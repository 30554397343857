import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Autocomplete,
  TableContainer,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Container,
  Divider,
  Radio,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { ClassicTable } from "../../../utils/components/Styles";
import IconButton from "@mui/material/IconButton";
import { object } from "prop-types";
import { editClientGuide } from "./guide";
import MultiAddressInput, { onSubmitAddress } from "./multiAddressInput";
const axios = require("axios");

const role2InitialData = [
  {
    label: "Quality Manager",
    keyName: "role2",
    type: 2,
    value: "Quality Manager",
    status: 1,
  },
  {
    label: "Check & Approved By",
    keyName: "role2",
    type: 2,
    value: "Check & Approved By",
    status: 1,
  },
  {
    label: "Verified By",
    keyName: "role2",
    type: 2,
    value: "Verified By",
    status: 1,
  },
];

const settingInitialData = [
  {
    label: "Calibration Engineer",
    keyName: "role1",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Reviewed By",
    keyName: "role3",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Signed By",
    keyName: "role4",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Witnessed By",
    keyName: "role5",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Amendment Number",
    keyName: "amendment",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Electronic Sign",
    keyName: "electronicSign",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Compliance Status",
    keyName: "complianceStatus",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Download Number",
    keyName: "downloadNumber",
    type: 2,
    value: "false",
    status: 1,
  },
  {
    label: "Last Modified",
    keyName: "lastModified",
    type: 2,
    value: "false",
    status: 1,
  },
];

export default function UpdatreClient(props) {
  const editClientGuides = editClientGuide();
  const [companyName, setCompanyNanme] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [gstNumber, setGstNumber] = React.useState("");
  const [addressIds, setAddressIds] = React.useState("");
  const [state, setState] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [shippingAddressIds, setShippingAddressIds] = React.useState("");
  const [contactDetailsRows, setContactDetailsRowsRows] = React.useState([
    ["", "", ""],
  ]);
  const [calibratedByChecked, setCalibratedByChecked] = React.useState("false");
  const [checkedByChecked, setCheckedByChecked] = React.useState("false");
  const [approvedByChecked, setApprovedByChecked] = React.useState("false");
  const [reviewedByChecked, setReviewedByChecked] = React.useState("false");
  const [signedByChecked, setSignedByChecked] = React.useState("false");
  const [branches, setBranches] = React.useState([]);
  const [selectedBranch, setSelectedBranch] = React.useState(null);
  const [addresses, setAddresses] = React.useState([]);
  const [checkboxData, setCheckboxData] = React.useState(settingInitialData);
  const [selectedRole2Value, setSelectedRole2Value] = React.useState({});
  const [allSettings, setAllSettings] = React.useState([]);
  const [currentClientID, setCurrentClientID] = React.useState(null);
  const [allAddresses, setAllAddresses] = React.useState([]);

  const params = useParams();
  const childRef = useRef(null);

  const readingColumns = [
    [
      {
        field: "id",
        headerName: "Sr. No.",
        editable: false,
      },
      {
        field: "address",
        headerName: "Address",
        editable: true,
      },
      {
        field: "contactPersonName",
        headerName: "Contact Person Name",
        editable: true,
      },

      {
        field: "contactNo",
        headerName: "Contact No",
        editable: true,
      },
      {
        field: "email",
        headerName: "email Id",
        editable: true,
      },
    ],
  ];

  const getBranches = (setBranch) => {
    axios.get(BASE_URL + "branch?_where=(status,eq,1)").then((res) => {
      setBranch(res.data);
      setBranches(
        res.data.map((item) => {
          return {
            id: item.id,
            branchName: item.branchName,
          };
        })
      );
    });
  };

  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...contactDetailsRows];
    newRows[rowIndex][colIndex] = value;
    setContactDetailsRowsRows([...newRows]);
  };

  const addRangeRow = () => {
    let newReadingRows = [...contactDetailsRows];
    newReadingRows.push(["", "", "", ""]);
    setContactDetailsRowsRows([...newReadingRows]);
  };

  const deleteReadingRow = (rowIndex) => {
    let newReadingRows = [...contactDetailsRows];
    if (newReadingRows[rowIndex] != undefined) {
      newReadingRows.splice(rowIndex, 1);
      setContactDetailsRowsRows([...newReadingRows]);
    }
  };

  const initiateChData = () => {
    try {
      let rows = [];
      var contactDetailsArray = [];
      if (contact != "") {
        contactDetailsArray = JSON.parse(contact);
      }
      for (let i = 0; i < contactDetailsArray.length; i++) {
        rows.push([
          contactDetailsArray[i].contact,
          contactDetailsArray[i].contactPersonName,
          contactDetailsArray[i].emailId,
        ]);
      }
      setContactDetailsRowsRows(rows);
    } catch (error) {
      console.log(error);
    }
  };

  const getClientsList = () => {
    let url = BASE_URL;
    axios
      .get(url + `clients/${params.id}`)
      .then((res) => {
        let newData = res.data[0];
        setCompanyNanme(newData?.companyName);
        setContact(newData?.contact);
        setAddressIds(newData?.address);
        let address = newData?.address;
        setAddresses(address?.split("||"));
        setShippingAddressIds(newData?.shippingAddress);
        setGstNumber(newData?.gstNumber);
        setState(newData?.state);
        setEmail(newData?.email);
        // setSignature(newData?.signature);
        getBranches((inpt) => {
          let tmp = {};
          inpt.map((e) => (tmp[e.id] = e));
          newData?.branch
            ? setSelectedBranch(tmp[newData?.branch] || null)
            : setSelectedBranch(null);
        });
        let rows = [];
        var contactDetailsArray = [];
        if (newData?.contact != "" && newData?.contact != null) {
          contactDetailsArray = JSON.parse(newData?.contact);
        }
        for (let i = 0; i < contactDetailsArray.length; i++) {
          rows.push([
            contactDetailsArray[i].address,
            contactDetailsArray[i].contactPersonName,
            contactDetailsArray[i].contact,
            contactDetailsArray[i].emailId,
          ]);
        }
        setContactDetailsRowsRows(rows);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    try {
      const AllIds = await onSubmitAddress(
        allAddresses,
        setShippingAddressIds,
        setAddressIds
      );
  
      const rows = contactDetailsRows.map((row) => ({
        address: row[0] || "",
        contactPersonName: row[1] || "",
        contact: row[2] || "",
        emailId: row[3] || "",
      }));
  
      const checkedObj = {
        forOrganization: {
          calibratedBy: calibratedByChecked.toString(),
          checkedBy: checkedByChecked.toString(),
          approvedBy: approvedByChecked.toString(),
        },
        forClient: {
          reviewedBy: reviewedByChecked.toString(),
          signedBy: signedByChecked.toString(),
        },
      };
  
      const jsonData = {
        companyName,
        contact: rows.length ? JSON.stringify(rows) : null,
        address: AllIds?.[0] ? `${addressIds},${AllIds?.[0]}` : addressIds,
        shippingAddress: AllIds?.[1]
          ? `${shippingAddressIds},${AllIds?.[1]}`
          : shippingAddressIds,
        gstNumber,
        state,
        email,
        signature: JSON.stringify(checkedObj),
        branch: selectedBranch ? `${selectedBranch?.id}` : null,
      };
  
      const url = BASE_URL;
  
      const axiosCall = params.id
        ? axios.patch(url + `clients/${params.id}`, jsonData)
        : axios.post(url + `clients`, jsonData);
  
      await axiosCall;
  
      if (params.id) {
        const sqlQuery = {
          query: `DELETE FROM clientSettings WHERE clientId=${params.id}`,
        };
        await axios.post(BASE_URL + `dynamic`, sqlQuery);
      }
  
      await axios.post(url + "clientSettings/bulk", allSettings);
  
      toast.success(params.id ? "Clients updated!" : "Client Created!");
    } catch (error) {
      toast.error(error.message || "Something Went Wrong!");
    } finally {
      props.setLoader(false);
    }
  };
  

  useEffect(() => {
    if (params.id) getClientsList();
    initiateChData();
    if (!params.id) getBranches(() => {});
  }, []);

  const CheckboxGroup = () => {
    const handleChange = (event, label) => {
      const updatedData = checkboxData.map((item) => {
        if (item.label === label) {
          return {
            ...item,
            value: event.target.checked ? "true" : "false",
          };
        }
        return item;
      });
      setCheckboxData(updatedData);
    };

    const handleChangeForRole2 = (event, item) => {
      if (event.target.checked) {
        setSelectedRole2Value(item);
      } else {
        setSelectedRole2Value({});
      }
    };

    const findCurrentClientID = () => {
      if (!params.id) {
        axios
          .get(BASE_URL + "clients?_fields=id&_sort=-id&_size=1")
          .then((res) => {
            let idIs = res?.data[0]?.id + 1;
            setCurrentClientID(idIs);
          });
      } else {
        setCurrentClientID(parseInt(params.id));
      }
    };

    useEffect(() => {
      const updatedData = checkboxData.map(({ label, ...item }) => ({
        ...item,
        clientId: currentClientID,
      }));

      const updatedSettings =
        Object.keys(selectedRole2Value).length > 0
          ? [
              ...updatedData,
              { ...selectedRole2Value, clientId: currentClientID },
            ]
          : updatedData;

      setAllSettings(updatedSettings);
    }, [checkboxData, selectedRole2Value, currentClientID]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const sqlQuery = {
            query: `SELECT keyName, value FROM clientSettings WHERE clientId = ${params.id}`,
          };

          const res = await axios.post(BASE_URL + `dynamic`, sqlQuery);
          const data = res.data;

          const role2Object = data.find((item) => item.keyName === "role2");

          if (role2Object) {
            setSelectedRole2Value({
              ...role2Object,
              label: role2Object.value,
            });
          }

          const filteredData = data.filter((item) => item.keyName !== "role2");

          const updatedData = checkboxData.map((ele) => {
            const found = filteredData.find((e) => ele.keyName === e.keyName);
            return found ? { ...ele, value: found.value } : ele;
          });

          setCheckboxData(updatedData);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }, [params.id]);

    useEffect(() => {
      findCurrentClientID();
    }, []);

    return (
      <Box id="client_edit_extrasetting">
        <Typography variant="h6" align="left" sx={{ margin: "1.5rem 0px" }}>
          Settings
        </Typography>
        <Typography
          variant="subtitle1"
          align="left"
          sx={{ margin: "1rem 0px 0px 0px" }}
        >
          For Signature
        </Typography>
        <Box style={{ marginLeft: "1.5rem" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Typography
              variant="paragraph"
              align="left"
              style={{ width: "13%" }}
            >
              Organization
            </Typography>
            {checkboxData?.slice(0, 1).map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={item.value === "true"}
                    onChange={(event) => handleChange(event, item.label)}
                    name={item.label}
                  />
                }
                label={<Typography variant="b">{item.label}</Typography>}
              />
            ))}
            {role2InitialData.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      selectedRole2Value &&
                      selectedRole2Value.label === item.label
                    }
                    onChange={(event) => handleChangeForRole2(event, item)}
                  />
                }
                label={<Typography variant="b">{item.label}</Typography>}
              />
            ))}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <Typography
              variant="paragraph"
              align="left"
              style={{ width: "13%" }}
            >
              Client
            </Typography>
            {checkboxData?.slice(1, 4).map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={item.value === "true"}
                    onChange={(event) => handleChange(event, item.label)}
                    name={item.label}
                  />
                }
                label={<Typography variant="b">{item.label}</Typography>}
              />
            ))}
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "0.7rem",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" align="left" style={{ width: "15%" }}>
            Miscellaneous
          </Typography>
          {checkboxData?.slice(4).map((item, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item.value === "true"}
                  onChange={(event) => handleChange(event, item.label)}
                  name={item.label}
                />
              }
              label={<Typography variant="b">{item.label}</Typography>}
            />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Container sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          Edit Client
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editClientGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="client_edit_compname"
            label="Company Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyNanme(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Billing Address"
            size="small"
            fullWidth
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>{" "} */}
        <Grid item xs={3}>
          <Autocomplete
            fullWidth
            id="client_edit_branch"
            size="small"
            options={branches || []}
            defaultValue={undefined}
            value={selectedBranch || null}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.branchName || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Branch"
                placeholder="Select Branch"
              />
            )}
            onChange={(event, value) => setSelectedBranch(value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="client_edit_GST"
            label="GST Number *"
            size="small"
            fullWidth
            variant="outlined"
            value={gstNumber}
            onChange={(e) => setGstNumber(e.target.value)}
          />
        </Grid>{" "}
        <Grid item xs={3}>
          <TextField
            id="client_edit_State"
            label="State *"
            size="small"
            fullWidth
            variant="outlined"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Email *"
            size="small"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MultiAddressInput
            addressIds={addressIds}
            shippingAddressIds={shippingAddressIds}
            setAddressIds={setAddressIds}
            setShippingAddressIds={setShippingAddressIds}
            allAddresses={allAddresses}
            setAllAddresses={setAllAddresses}
            ref={childRef}
          />
        </Grid>
      </Grid>
      <br />
      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
        }}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          style={{ float: "left", margin: "20px 0px" }}
        >
          Contact Details
        </Typography>
        <Button
          id="client_edit_addcontact"
          variant="contained"
          size="small"
          onClick={(e) => {
            addRangeRow(0);
          }}
        >
          Add Contact
        </Button>
      </Box>

      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0].map((column) => (
                  <TableCell key={column.field}>
                    {" "}
                    <Typography noWrap>{column.headerName}</Typography>
                  </TableCell>
                ))}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contactDetailsRows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>
                      {cellIndex === 0 ? (
                        <Autocomplete
                          id="client_edit_contvalue"
                          options={allAddresses}
                          size="small"
                          getOptionLabel={(option) => option?.address}
                          value={cell !== null ? { address: cell } : null}
                          onChange={(event, value) => {
                            let optionId = value?.address;
                            updateCellValue(index, cellIndex, optionId);
                          }}
                          sx={{ width: "320px" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Address *"
                              variant="outlined"
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          id="client_edit_contvalue"
                          size="small"
                          value={cell}
                          onChange={(e) => {
                            updateCellValue(index, cellIndex, e.target.value);
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <DeleteIcon
                      id="client_edit_contdelte"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(0, index);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <br />
      </div>

      <Divider
        sx={{
          backgroundColor: "black",
          height: "2px",
          margin: "5px 0px",
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>{CheckboxGroup()}</Box>
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          id="client_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          {params.id ? "Update" : "Save"}
        </Button>
      </Toolbar>
      <ToastContainer />
    </Container>
  );
}
