import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";

import { Grid, TextField, Autocomplete, ButtonGroup, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../global";
import ExcelDownload from "../../../utils/components/excelDownload";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");
let notNeededColumn=["id"]
const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "ulrNo", label: "ULR No.", minWidth: 40 },
  { id: "certificateNumber", label: "Certificate Number", minWidth: 40 },
  {
    id: "InstrumentId",
    label: "Instrument Name",
    minWidth: 40,
    align: "left",
  },
  { id: "range", label: "range", minWidth: 40 },
  { id: "lc", label: "lc", minWidth: 40 },
  { id: "DUCID", label: "DUC ID", minWidth: 40 },
  { id: "make", label: "Make", minWidth: 40 },
  { id: "model", label: "Model", minWidth: 40 },
  { id: "locationOfInstrument", label: "Location Of Instrument", minWidth: 40 },
  {
    id: "calibrationDate",
    label: "Calibration date",
    minWidth: 40,
    align: "left",
  },
  {
    id: "dueDate",
    label: "Due date",
    minWidth: 40,
    align: "left",
  },
];

function createData(
  id,
  ulrNo,
  certificateNumber,
  InstrumentId,
  range,
  lc,
  DUCID,
  make,
  model,
  locationOfInstrument,
  calibrationDate,
  dueDate
) {
  return {
    id,
    ulrNo,
    certificateNumber,
    InstrumentId,
    range,
    lc,
    DUCID,
    make,
    model,
    locationOfInstrument,
    calibrationDate,
    dueDate,
  };
}

export default function SummaryReport() {
  const [page, setPage] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [fromDate, setFromDate] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [toDate, setToDate] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [calData, setCalData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [certificatesData, setCertificatesData] = React.useState([]);
  const [finalArray, setFinalArray] = React.useState([]);
  const [instId, setInstId] = React.useState(0);
  const [ducId, setDucId] = React.useState("");
  const _clientId = localStorage.getItem("clientId");
  const _userType = localStorage.getItem("type");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    let endPoint=""
    endPoint= _userType == "3"?`clients/${_clientId}?_where=(status,eq,1)`:`clients?_where=(status,eq,1)`;
    axios
      .get(url + endPoint)
      .then((res) => {
        setCustomer(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getInstruments = (id) => {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cert.certificates,_j,inst.instruments&_on1=(cert.srfInstrumentId,eq,inst.id)&_fields=cert.DUCID,inst.instrumentName,cert.calibrationDate,cert.id,inst.id,cert.locationOfInstrument&_where=(cert.clientId,eq,${id})`
      )
      .then((res) => {
        setCertificatesData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  var pushArray = [];

  for (let i = 0; i < customer.length; i++) {
    pushArray.push({
      id: customer[i].id,
      label: customer[i].companyName,
    });
  }

  const initializeCertificateDataTable = (id) => {
    var pushArray = [];
    var uniqueLabels = new Set();

    for (let i = 0; i < certificatesData.length; i++) {
      const label = `${certificatesData[i].inst_instrumentName},${certificatesData[i].cert_DUCID}`;
      if (!uniqueLabels.has(label)) {
        pushArray.push({
          instrumentId: certificatesData[i].inst_id,
          ducId: certificatesData[i].cert_DUCID,
          label: label,
        });
        uniqueLabels.add(label);
      }
    }

    console.log("pushArray", pushArray);
    setFinalArray(pushArray);
  };

  const getData = (event) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (instId && ducId) {
      whereConditions = `AND inst.id = ${instId} AND cert.DUCID = '${ducId}'`;
    }

    if (toDate != null && fromDate != null) {
      if (whereConditions === "") {
        whereConditions = " and ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` cert.calibrationDate between '${moment(
        fromDate
      ).format("YYYY-MM-DD")}' and '${moment(toDate).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }
    let data = {
      query: `SELECT client.companyName, client.contact, client.address, inst.instrumentName, cert.calibrationDate, cert.nextDueDate, client.id AS client_id, cert.id AS certificate_id, cert.DUCID, cert.srfInstrumentId, cert.certificateNumber,replace(replace( cert.ranges , '#',''),'|',' to ') as ranges , replace(replace( cert.lc , '#',''),'|',' to ') as lc, cert.make, cert.model  FROM certificates AS cert LEFT JOIN instruments AS inst ON cert.instrumentId = inst.id LEFT JOIN clients AS client ON cert.clientId = client.id WHERE cert.clientId = ${company} ${whereConditions} `,
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setData(res.data);
        initializeDataTable();
        // FilterData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const initializeDataTable = () => {
    $("#datatable-keytable").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      bPaginate: false,
      bLengthChange: true,
      bFilter: true,
      bInfo: false,
      bAutoWidth: true,
      language: {
        zeroRecords: " ",
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  };
  console.log("data123",data)
  const initializeRowTable = () => {
    var rowData = [];
    var idAr = [];
    for (let i = 0; i < data.length; i++) {
      idAr.push(data[i].certificate_id);
      rowData.push(
        createData(
          data[i].certificate_id,
          "ULR12123",
          data[i].certificateNumber,
          data[i].instrumentName,
          data[i].ranges,
          data[i].lc,
          data[i].DUCID,
          data[i].make,
          data[i].model,
          data[i].address,
          data[i].calibrationDate
            ? moment(data[i].calibrationDate).format("DD-MM-YYYY")
            : "",
          data[i].nextDueDate
            ? moment(data[i].nextDueDate).format("DD-MM-YYYY")
            : ""
        )
      );
    }
    setCalData(rowData);
    setIds(idAr);
  };
  useEffect(() => {
    getCustomer();
    //  getData();
  }, []);
  useEffect(() => {
    initializeRowTable();
  }, [data]);
  useEffect(() => {
    initializeCertificateDataTable();
  }, [certificatesData]);

  return (
    <>
      <div style={{ marginTop: "2em" }}>
        <Grid container spacing={2} mb={6} justifyContent={"end"}>
          <Grid item xs={2}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={pushArray}
              renderInput={(params) => (
                <TextField {...params} label="Company Name" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  setCompany(value.id);
                  getInstruments(value.id);
                } else {
                  setCompany("");
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={finalArray}
              renderInput={(params) => (
                <TextField {...params} label="Instruments" />
              )}
              onChange={(event, value) => {
                if (value != null) {
                  setInstId(value.instrumentId);
                  setDucId(value.ducId);
                } else {
                  setCompany("");
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date From"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              id="Start Date"
              label="Date To"
              variant="outlined"
              type="date"
              className="mr-2"
              required={true}
              InputLabelProps={{ shrink: true }}
              size="small"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </Grid>
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              // FilterData();
              getData();
            }}
            style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
          >
            Search
          </Button>
          {ids.length ? (
            // <Button
            //   variant="contained"
            //   size="small"
            //   sx={{ m: 0 }}
            //   component={Link}
            //   to={`/summaryReport/viewSummary/${ids.toString()}`}
            //   style={{ marginLeft: "10px", height: "40px", marginTop: "1em" }}
            // >
            //   Print Summary
            // </Button>
            <ExcelDownload
            finalData={calData}
            notNeededColumn={notNeededColumn}
          />
          ) : (
            ""
          )}
        </Grid>

        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              <TableCell style={{ fontWeight: "bold" }}>
                View Certificate
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      let value = row[column.id];

                      if (column.id == "id") {
                        value = page * rowsPerPage + index + 1;
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                        
                      );
                    })}
                     <TableCell>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                        >
                          <Tooltip
                            title="View Certificate"
                            placement="top-start"
                          >
                            <Button
                              component={Link}
                              to={`/certificate/viewCertificate/${row.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={calData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
}
