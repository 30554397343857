import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Toolbar,
  Tooltip,
  Typography,
  Divider,
  Modal,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandCircleDownOutlined from "@mui/icons-material/ExpandCircleDownOutlined";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { BASE_URL, MAX_COLS, SRF_ID_BASE } from "../../../global";
import { ClassicTable } from "../../../utils/components/Styles";
import processDatasheetTemplate from "./processDatasheetTemplate";
import {
  prepareCertificateReadings,
  prepareDatasheetReadings,
  prepareTypeBValues,
} from "./utils";
import StandardMasterSelection from "../StandardMasterSelection";
import generateAudit from "../../audit/audit";
import { showComponent } from "../../helper/helpers";
import {
  UNCERTAINTY_LINK,
  EXTRA_COLUMN_LINK,
} from "../../master/staticTable/editTable";
import ViewDatasheet from "../viewDatasheet";
import { updateSRFAnalytics } from "../../../utils/analytics/srfAnalytics";
import {
  useStyles,
  TableCell,
  modalStyle,
  historyModalStyle,
} from "./constants";
import "./datasheet.css";
import { editdataSheetGuide } from "../guide";

const axios = require("axios");

export default function EditDatasheet(props) {
  const editdataSheetGuides = editdataSheetGuide();
  const classes = useStyles();
  const printComponentRef = React.useRef();
  const { datasheetId, isPrint } = useParams();
  const [units, setUnits] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [staticReadingRows, setStaticReadingRows] = useState([]);
  const [certificateStaticReadings, seCertificateStaticReadings] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [datasheetStaticReadings, setDatasheetStaticReadings] = useState([]);
  const [datasheet, setDatasheet] = useState(null);
  const [instrument, setInstrument] = useState(null);
  const [instrumentId, setInstrumentId] = useState(null);
  const [staticTables, setStaticTables] = useState(null);
  const [certificateStaticTables, setCertificateStaticTables] = useState(null);
  const [clientId, setClientId] = useState("");
  const [clientIdNumber, setClientIdNumber] = useState("");
  const [instrumentName, setInstrumentName] = useState("");
  const [make, setMake] = useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] = useState("");
  const [referenceStandards, setreferenceStandards] = useState("");
  const [standardMasterArray, setStandardMasterArray] = useState([]);
  const [allStandards, setAllStandards] = useState([]);
  const [supportiveStandards, setSupportiveStandrds] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [lc, setLC] = useState("");
  const [dcNo, setDCNo] = useState("");
  const [dcDate, setDCDate] = useState("");
  const [ranges, setRanges] = useState("");
  const [deletedTableIds, setDeletedTableIds] = useState(null);
  const [DUCID, setDUCID] = useState("");
  const [configuration, setConfiguration] = useState({});
  const [calibrationDate, setCalibrationDate] = useState("");
  const [nextDueDate, setNextDueDate] = useState("");
  const [receiptDate, setReceiptDate] = useState("");
  const [mfgNo, setMfgNo] = useState("");
  const [startTemp, setStartTemp] = useState("");
  const [endTemp, setEndTemp] = useState("");
  const [startHumidity, setStartHumidity] = useState("");
  const [endHumidity, setEndHumidity] = useState("");
  const [identificationNo, setIdentificationNo] = useState("");
  const [location, setLocation] = useState("");
  const [accuracy, setAccuracy] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [poDate, setPoDate] = useState(null);
  const [poNo, setPoNo] = useState(null);
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [models, setModel] = useState("");
  const [gaugeData, setGaugeData] = useState({});
  const [ConOfDuc, setConOfDuc] = useState("");
  const [calPoint, setCalPoint] = useState("");
  const [calMethod, setCalMethod] = useState("");
  const [locationOfInstrument, setLocationOfInstrument] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [atmosphericPressure, setAtmosphericPressure] = useState("");
  const [cmcReadings, setCmcReadings] = useState([]);
  const [cmcReadingRows, setCmcReadingRows] = useState([]);
  const [disciplineName, setDisciplineName] = useState("");
  const [defaultReamrk, setDefaultRemark] = useState(null);
  const [tableDisplayStatus, setTableDisplayStatus] = useState(true);
  const [activeRowIndex, setActiveRowIndex] = useState(-1);
  const [activeTable, setActiveTable] = useState(-1);
  const [calibratedby, setCalibratedby] = useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [extraColumns, setExtraColumns] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [revisionNumber, setRevisionNumber] = useState(-1);
  const [reasonForAmendment, setReasonForAmendment] = React.useState("");
  const [amendmnetHistory, setAmendmentHistory] = React.useState("");
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalHistoryOpen, setModalHistoryOpen] = React.useState(false);
  const [referenceData, setReferenceData] = useState({});
  const [calibrationReason, setCalibrationReason] = useState(1);
  const [complianceStatus, setComplianceStatus] = useState(1);
  const [selectedRangeIds, setSelectedRangeIds] = useState([]);
  const [shouldShowAmendment, setShouldShowAmendment] = useState(false);
  const [supportive, setSupportive] = useState([]);
  const [requestedname, setRequestedname] = useState("");
  const [srfId, setSrfId] = useState(-1);
  const [loader, setLoader] = useState(undefined);
  const [isPrinting, setisPrinting] = React.useState(false);
  const [unceraintyConfig, setUnceraintyConfig] = React.useState({});
  const [io, setIo] = React.useState([]);
  const [dCReadingIdMap, setDCReadingIdMap] = React.useState({});
  const [derviedColumns, setDerivedColumns] = React.useState([]);
  const [calibrationFrequency, setCalibrationFrequency] = React.useState("");
  const [certificateNumber, setCertifucateNumber] = React.useState("");
  const [ULRNumber, setULRNumber] = React.useState("");
  const [entryDate, setEntryDate] = React.useState("");
  const [SRFNumber, setSRFNumber] = React.useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [issueNo, setIssueNo] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [amendmentNo, setAmendmentNo] = useState("");
  const [amendmentDate, setAmendmentDate] = useState("");
  const [outOffRangeTracker, setOutOffRangeTracker] = useState({});
  const [precNotEqTracker, setPrecNotEqTracker] = useState({});
  const [calTypeSetting, setCalTypeSetting] = useState(false);
  const [compliance, setCompliance] = React.useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const handleHistoryOpen = () => setModalHistoryOpen(true);
  const handleHistoryClose = () => setModalHistoryOpen(false);

  const precisionCount = 4;
  const filterRangeData = [];
  const deletedColumns = {};
  const approvedby = "";
  const userType = localStorage.getItem("type");

  async function getDateTimeFromServer(time = false) {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT now() as date`;
      const response = await axios.post(url, { query });
      let dateTime = new Date(response.data[0]?.date || null);
      if (time) {
        dateTime = {
          hour: dateTime.getHours(),
          minute: dateTime.getMinutes(),
          second: dateTime.getSeconds(),
        };
      }
      return dateTime;
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  // utility functions
  const getCustomColumnCount = (
    tableId,
    isStatic = null,
    isCertificate = null,
    tables = null
  ) => {
    let tbl = tables;
    if (!tbl) {
      tbl =
        isStatic === "static"
          ? isCertificate === "certificate"
            ? certificateStaticTables
            : staticTables
          : null;
    }

    let count = 0;
    if (!tbl) return count;
    for (let i = 0; i < tbl.length; i++) {
      if (tbl[i].id == tableId) {
        for (let j = 0; j < MAX_COLS; j++) {
          if (tbl[i]["c" + (j + 1)] != null) {
            count++;
          } else {
            break;
          }
        }
      }
    }
    return count;
  };

  const clearPreviousReadings = async (table, ids = "") => {
    if (ids === "" || !ids) return true;
    ids = ids.map((id) => {
      if (id > 0) return id;
      else return 0;
    });
    try {
      let res = await axios.delete(
        BASE_URL + `${table}/bulk?_ids=${ids.toString()}`
      );
      return true;
    } catch (error) {
      console.error(`error - ${table} - clearPreviousReadings - `, error);
      setLoader(false);
    }
    return false;
  };

  // api calls
  function fetchSettings() {
    return axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        complance = false,
        amd = false,
        calTypeSetting = false,
        settings = res.data;
      settings.some((setting) => {
        if (setting.keyName === "remark") {
          remark = setting.value;
        } else if (
          setting.keyName === "amendment" &&
          setting.value.toLowerCase() === "true"
        ) {
          amd = true;
        } else if (
          setting.keyName === "Calibration Type" &&
          setting.value.toLowerCase() === "true"
        ) {
          calTypeSetting = true;
        } else if (
          setting.keyName === "Compliance Status" &&
          setting.value.toLowerCase() === "true"
        ) {
          complance = true;
        }
      });
      setShouldShowAmendment(amd);
      setCalTypeSetting(calTypeSetting);
      setCompliance(complance);
      return res;
    });
  }

  function prepareAndSetDatasheet(ds) {
    let range_low = [];
    let range_high = [];
    let ranges = ds?.ds_ranges.split("||");
    ranges.forEach((range) => {
      range_low.push((range.split("|")[0]?.match(/[+-\d\.]+/g) || [])[0]);
      range_high.push((range.split("|")[1]?.match(/[+-\d\.]+/g) || [])[0]);
    });

    let accuracy = [];
    ds?.ds_accuracy
      ?.split("||")
      ?.forEach((item) => accuracy.push((item?.match(/[\d\.]+/g) || [])[0]));

    let lc = [];
    ds?.ds_lc
      ?.split("||")
      ?.forEach((item) => lc.push((item?.match(/[\d\.]+/g) || [])[0]));

    setDatasheet({
      id: datasheetId,
      // extracting numbers
      lc: lc?.join("||"),
      range_low: range_low.join("||"),
      range_high: range_high.join("||"),
      accuracy: accuracy?.join("||"),
    });
  }

  function fetchDatasheet() {
    let url = BASE_URL;
    return axios
      .get(
        url +
          `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(cust.id,eq,ds.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=ds.lastModified,ds.amendment,ds.revisionNumber,ds.calibratedby,ds.clientId,cust.companyName,ins.instrumentName,ds.jobNumber,ds.requestedname,ds.id,ds.make,ds.dcNumber,ds.dcDate,ds.lc,ds.instrumentId,ds.deletedTableIds,ins.standardMasterIds,ds.ranges,ins.calibrationProcedureNo,ins.referenceStandards,cust.address,ds.dcNumber,ds.calibrationDate,ds.dcDate,ds.nextDueDate,ds.receiptDate,ds.calProcRefNo,ds.mfgNo,ds.identificationNo,ds.startTemp,ds.endTemp,ds.additionalStandardIds,ds.DUCID,ds.calibrationType,ds.specificValues,ds.location,ds.serialNumber,ds.accuracy,ds.poNumber,ds.poDate,ds.dateOfIssue,ds.model,ds.ConOfDuc,ds.calPoint,ds.calMethod,ds.locationOfInstrument,ds.srnNo,ds.configuration,ds.atmosphericPressure,ds.tableDisplayStatus,ds.startHumidity,ds.endHumidity,disc.id,disc.name,disc.remarks,ds.extraColumns,ds.calibrationReason,ds.complianceStatus,ds.calFrequency&_where=(ds.id,eq, ${datasheetId})`
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setSrfId(
            res.data[0].ds_jobNumber
              ? Number(res.data[0].ds_jobNumber.split("/")[0]) - SRF_ID_BASE
              : 0
          );
          fetchDisciplineDetails(res.data[0]?.disc_id);
          axios
            .get(
              BASE_URL +
                `srfs/${
                  res.data[0].ds_jobNumber
                    ? Number(res.data[0].ds_jobNumber.split("/")[0]) -
                      SRF_ID_BASE
                    : 0
                }?_fields=serviceReqNumber,entryDate`
            )
            .then((res) => {
              setSRFNumber(res.data[0]?.serviceReqNumber);
              setEntryDate(res.data[0]?.entryDate);
            });

          if (res.data[0].ds_calibratedby) {
            axios
              .get(BASE_URL + `users/${res.data[0].ds_calibratedby}`)
              .then((res) => {
                const userData = res.data[0];
                const calibratedByUsername = userData.userName;
                setCalibratedby(calibratedByUsername);
              })
              .catch((err) => console.error("Error fetching user data:", err));
          }
          setJobNumber(res.data[0].ds_jobNumber);
          prepareAndSetDatasheet(res.data[0]);
          setCalibrationFrequency(res.data[0].ds_calFrequency || "");
          setRevisionNumber(res.data[0].ds_revisionNumber);
          // setCalibratedby(res.data[0].ds_calibratedby);
          // setApprovedby(res.data[0].ds_calibratedby)
          setClientId(res.data[0].cust_companyName);
          setClientIdNumber(res.data[0].ds_clientId);
          setInstrumentName(res.data[0].ins_instrumentName);
          setRequestedname(
            res.data[0].ds_requestedname ? res.data[0].ds_requestedname : ""
          );
          setInstrumentId(res.data[0].ds_instrumentId);
          setMake(res.data[0].ds_make);
          setLC(res.data[0].ds_lc);
          setDefaultRemark(res.data[0].disc_remarks);
          res.data[0].ds_additionalStandardIds &&
            setSelectedStandards(
              res.data[0].ds_additionalStandardIds?.split(",")
            );
          setRanges(res.data[0].ds_ranges);
          setDCDate(res.data[0].ds_dcDate ? res.data[0].ds_dcDate : "");
          setDCNo(res.data[0].ds_dcNumber);
          setDeletedTableIds(res.data[0].ds_deletedTableIds);
          setreferenceStandards(res.data[0].ins_referenceStandards);
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo);
          setCalibrationDate(
            res.data[0].ds_calibrationDate ? res.data[0].ds_calibrationDate : ""
          );
          setNextDueDate(
            res.data[0].ds_nextDueDate ? res.data[0].ds_nextDueDate : ""
          );
          setReceiptDate(
            res.data[0].ds_receiptDate ? res.data[0].ds_receiptDate : ""
          );
          setMfgNo(res.data[0].ds_mfgNo);
          setStartTemp(res.data[0].ds_startTemp);
          setEndTemp(res.data[0].ds_endTemp);
          setStartHumidity(res.data[0].ds_startHumidity);
          setEndHumidity(res.data[0].ds_endHumidity);
          setIdentificationNo(res.data[0].ds_identificationNo);
          setDUCID(res.data[0].ds_DUCID);
          setLocation(res.data[0].ds_location);
          setAccuracy(res.data[0].ds_accuracy);
          setSerialNo(res.data[0].ds_serialNumber);
          setPoNo(res.data[0].ds_poNumber);
          setPoDate(res.data[0].ds_poDate);
          setDateOfIssue(res.data[0].ds_dateOfIssue);
          setModel(res.data[0].ds_model);
          setConOfDuc(res.data[0].ds_ConOfDuc);
          setCalPoint(res.data[0].ds_calPoint);
          setCalMethod(res.data[0].ds_calMethod);
          setLocationOfInstrument(res.data[0].ds_locationOfInstrument);
          setConfiguration(JSON.parse(res.data[0].ds_configuration) || {});
          setAtmosphericPressure(res.data[0].ds_atmosphericPressure);
          setDisciplineName(res.data[0].disc_name);
          setTableDisplayStatus(res.data[0].ds_tableDisplayStatus);
          setExtraColumns(
            Object.entries(JSON.parse(res.data[0].ds_extraColumns))
          );
          let extraEvvtries = res.data[0].ds_extraColumns
            ? JSON.parse(res.data[0].ds_extraColumns)
            : {};
          setAmendmentHistory(res.data[0].ds_amendment);
          setCalibrationReason(res.data[0].ds_calibrationReason);
          setComplianceStatus(
            res.data[0].ds_complienceStatus == null
              ? 1
              : res.data[0].ds_complienceStatus
          );
        }

        return Promise.all([
          fetchDatasheetStaticTables(
            res.data[0].ds_instrumentId,
            res.data[0].ds_deletedTableIds //TODO: pass static tables deleted ids only
          ),
          fetchCertificateStaticTables(
            res.data[0].ds_instrumentId,
            res.data[0].ds_deletedTableIds // considered as certificated table id as both have same table ids
          ),
          fetchInstrument(res.data[0].ds_instrumentId),
          fetchCmcReadings(res.data[0].ds_instrumentId),
          (async () => {
            if (res.data[0].ins_standardMasterIds) {
              let combineArray = await filterAllowedMasters(
                res.data[0].ins_standardMasterIds
              );
              return axios
                .get(
                  url + `standards?_where=(id,in,${combineArray.toString()})`
                )
                .then((res) => {
                  setStandardMasterArray(res.data);
                  return res;
                });
            }
          })(),
        ])
          .then((res) => {
            fetchStaticReadingRows();
          })
          .catch((err) => err);
      })
      .catch((err) => {
        console.error("datasheet data fetching error: ", err);
      });
  }

  function fetchDatasheetStaticTables(instrumentId, deletedTableIds, table_id) {
    let url = BASE_URL;
    deletedTableIds = deletedTableIds?.split(",")?.map((id) => Number(id));
    let data_url = table_id
      ? `datasheetStaticTables/${table_id}`
      : `datasheetStaticTables?_where=(instrumentId,eq,${instrumentId})`;
    return axios
      .get(url + data_url)
      .then((res) => {
        if (deletedTableIds) {
          res.data = res.data.filter(
            (row) => !deletedTableIds.includes(row.id)
          );
        }
        if (res.data.length > 0) {
          let tmp = {};
          let cMap = {};
          res.data.map((table) => {
            tmp[table.id] = {
              isUncertainty: table.isUncertainty,
              isVerticalUncertainty: table.isVerticalUncertainty,
              isAutomated: table.isAutomated,
              defaultValues: JSON.parse(table.defaultConfiguration)?.values,
            };
            cMap[table.id] = Object.keys(
              JSON.parse(table.defaultConfiguration).formulas
            );
          });
          setDerivedColumns({ ...cMap });
          setUnceraintyConfig(tmp);
          setStaticTables(res.data);
        }
        return res.data;
      })
      .catch((err) => {
        console.error("datasheet static tables data fetching error: ", err);
        return err;
      });
  }

  function fetchCertificateStaticTables(instrumentId, deletedTableIds = "") {
    let url = BASE_URL;
    return axios
      .get(
        url + `certificateStaticTables?_where=(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        setCertificateStaticTables(res.data);
        return res;
      })
      .catch((err) => {
        console.error("certificate static tables data fetching error: ", err);
        return err;
      });
  }

  const filterAllowedMasters = async (mst) => {
    if (userType != 2) {
      return mst;
    }
    if (!mst) return mst;
    mst = (mst || "").split(",").map((e) => e.split(":")[0]);
    if (mst === "") return mst;
    let res = null;
    let ioh = io;
    if (ioh === null || ioh === undefined || !ioh.length) {
      res = await axios.get(
        BASE_URL +
          `standardInOut?_where=(status,eq,1)~and(personName,eq,${
            user[1]
          })~and(expectedReturnDate,gt,${moment(new Date()).format(
            "YYYY-MM-DD"
          )})`
      );
      ioh = res.data
        .filter((d) => d.purpose.toLowerCase() != "self-calibration")
        .map((e) => e.standards)
        .join(",")
        .split(",");
    }

    if (res.data.length) {
      mst = mst.filter((c) => ioh.includes(c));
      setIo(ioh);
    }
    return mst.join(",");
  };

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    return axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then(async (res) => {
        if (res.data.length > 0) {
          setInstrument({ ...res.data[0] });
          // console.log("instrument:", res.data[0]);

          // extract standards
          let rawStandardIds = await filterAllowedMasters(
            res.data[0]?.standardMasterIds
          );

          let rawSupportiveInstrumentMasterData =
            res.data[0]?.supportiveInstrumentMasterData || "";
          let standardIds = rawStandardIds
            .split(",")
            .map((value) => value.split(":")[0]);
          let supportiveStandardIds = rawSupportiveInstrumentMasterData
            .split(",")
            .map((value) => value.split(":")[0]);
          if (standardIds.length > 0 || supportiveStandardIds.length > 0)
            fetchStandards([
              standardIds.join(","),
              supportiveStandardIds.join(","),
            ]);
        }
        return res;
      })
      .catch((err) => {
        console.error("Instrument data fetching error: ", err);
        return err;
      });
  }

  useEffect(() => {
    if (instrument !== null && unitArray && unitArray.length > 0) {
      const unitArr = [""];
      for (let i = 0; i < unitArray.length; i++) {
        if (instrument.disciplineId == unitArray[i].disciplineId) {
          unitArr.push(unitArray[i].symbol);
        }
      }
      setUnits(unitArr);
    }
  }, [instrument, unitArray]);

  function fetchStaticReadingRows(di) {
    let url = BASE_URL;

    return Promise.all([
      // for static datasheet
      axios
        .get(
          url +
            `datasheetStaticReadings?_where=(datasheetId,eq,${
              di || datasheetId
            })`
        )
        .then((res) => {
          if (di)
            res.data = res.data.map((e) => {
              delete e.id;
              e.datasheetId = datasheetId;
              return e;
            });
          setDatasheetStaticReadings(res.data);
          return res;
        })
        .catch((err) => {
          console.error(
            "static datasheet tables reading fetching error: ",
            err
          );
          return err;
        }),
      // for static certificate
      axios
        .get(
          url +
            `certificateStaticReadings?_where=(certificateId,eq,${
              di || datasheetId
            })` // daaseetId would be same as certificateId
        )
        .then((res) => {
          if (di)
            res.data = res.data.map((e) => {
              delete e.id;
              e.certificateId = datasheetId;
              return e;
            });
          seCertificateStaticReadings(res.data);
          return res;
        })
        .catch((err) => {
          console.error(
            "static certificate tables reading fetching error: ",
            err
          );
          return err;
        }),
    ]);
  }

  let calculateNextDueDate = () => {
    if (!calibrationDate || !calibrationFrequency) return "";
    let calculateDate = new Date(
      moment(calibrationDate)
        .add(calibrationFrequency?.split(" ")?.[0] || 0, "M")
        .format("YYYY-MM-DD")
    );
    calculateDate = calibrationDate ? calculateDate : "";
    setNextDueDate(calculateDate);
    return calculateDate;
  };

  // TODO: correct this unblocked scope
  let parseRows = (tableRows) => {
    let base = 2;
    return Object.values(tableRows).map((rows) =>
      rows.map((row, idx) =>
        row.map((col, col_id) =>
          col_id >= base && col_id < row.length - 2 && col
            ? col.split("#")[0]
            : col
        )
      )
    );
  };

  // TODOL simplify this function
  async function fetchOtherStaticReadingRows() {
    setLoader(true);
    let queries = [];
    if (DUCID)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${instrumentId})~and(DUCID,eq,${DUCID})`,
      ]);
    if (ranges && lc)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${instrumentId})~and((ranges,like,${ranges.replaceAll(
          "#",
          "_"
        )})~and(lc,like,${lc.replaceAll("#", "_")}))`,
      ]);
    if (models)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${instrumentId})~and(model,ne,${models})`,
      ]);
    if (serialNo)
      queries = queries.concat([
        `datasheets?_where=(totalReadings,gt,0)~and(id,ne,${datasheetId})~and(instrumentId,eq,${instrumentId})~and(serialNumber,ne,${serialNo})`,
      ]);

    let res = [];
    for (let i = 0; i < queries.length; i++) {
      res = await axios.get(BASE_URL + queries[i]);
      if (res.data.length > 0) break;
    }
    let datasheetIds = res.data?.map((row) => row.id);
    if (datasheetIds?.length > 0) {
      let _datasheetId =
        datasheetIds[Math.floor(Math.random() * datasheetIds.length)];
      let t = datasheetStaticReadings
        .filter((e) => e.datasheetId == datasheetId)
        .map((row) => row.id);
      if (t.length > 0)
        await clearPreviousReadings("datasheetStaticReadings", t);
      t = datasheetStaticReadings
        .filter((e) => e.datasheetId == datasheetId)
        .map((row) => row.id);
      if (t.length > 0)
        await clearPreviousReadings("certificateStaticReadings", t);
      fetchStaticReadingRows(_datasheetId);
      setLoader(false);
    } else {
      setLoader(false);
    }
  }

  function fetchStandards(ids = "") {
    const convertStandardData = {
      pl_id: "id",
      pl_stId: "stId",
      pl_standardName: "title",
      pr_id: "rangeId",
      pr_rangeName: "range",
      pr_accuracy: "accuracy",
      pr_gravity: "lc",
      pr_axialUniformity: "axialUniformity",
      pr_radialUniformity: "radialUniformity",
      pr_stability: "stability",
      pl_type: "type",
      pl_masterleastcount: "masterleastcount",
      pr_mode: "mode",
      pr_parameter: "parameter",
      pr_paratype: "paratype",
      ds_disciplineKey: "dsKey",
    };

    axios
      .get(
        BASE_URL +
          `xjoin?_join=pl.standards,_j,pr.standardRanges,_j,ds.discipline&_on1=(pl.id,eq,pr.standardId)&_on2=(pl.disciplineId,eq,ds.id)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.rangeName,pr.accuracy,pr.gravity,pr.axialUniformity,pr.radialUniformity,pr.mode,pr.parameter,pr.paratype,pr.stability,pl.type,pl.masterleastcount,pl.make,pl.certificateNumber,pl.traceability,pl.calDate,pl.validUpto,ds.disciplineKey&_where=(pl.status,eq,1)~and(pl.id,in,${ids[0]})`
      )
      .then((res) => {
        let dt = [];
        res.data.map((d) => {
          dt.push({});
          Object.entries(d).map(
            (e) => (dt[dt.length - 1][convertStandardData[e[0]]] = e[1])
          );
        });
        setAllStandards(dt.filter((st) => st.type == 1));
      })
      .catch((err) => {
        console.error("standards data fetching error: ", err);
      });
    axios
      .get(
        BASE_URL +
          `xjoin?_join=pl.standards,_j,pr.standardRanges&_on1=(pl.id,eq,pr.standardId)&_fields=pl.standardName,pr.rangeName,pl.id,pr.id,pl.stId,pr.rangeName,pr.accuracy,pr.gravity,pr.axialUniformity,pr.radialUniformity,pr.mode,pr.parameter,pr.paratype,pr.stability,pl.type,pl.masterleastcount,pl.make,pl.certificateNumber,pl.traceability,pl.calDate,pl.validUpto&_where=(pl.status,eq,1)~and(pl.id,in,${ids[1]})`
      )
      .then((res) => {
        let dt = [];
        res.data.map((d) => {
          dt.push({});
          Object.entries(d).map(
            (e) => (dt[dt.length - 1][convertStandardData[e[0]]] = e[1])
          );
        });
        setSupportiveStandrds(dt.filter((st) => st.type == 2));
      })
      .catch((err) => {
        console.error("standards data fetching error: ", err);
      });
  }

  function fetchUnits() {
    return axios
      .get(BASE_URL + "unit?_where=(status,eq,1)")
      .then((res) => {
        let units = res.data.reduce((prev, current) => {
          return [...prev, current.symbol];
        }, []);
        setUnits(units);
        setUnitArray(res.data);
        return res;
      })
      .catch((err) => {
        console.error("unit fetching error: ", err);
        return err;
      });
  }

  function fetchCmcReadings(instrumentId) {
    return axios
      .get(BASE_URL + `cmc?_where=(instrumentId,eq,${instrumentId})`)
      .then((res) => {
        setCmcReadings(res.data);
        return res;
      })
      .catch((err) => {
        console.error("Something Went Wrong while fetching standardRanges!");
        return err;
      });
  }

  function getReferenceData() {
    let tables = ["srfInstruments"];
    axios.get(`${BASE_URL}${tables[0]}/${datasheetId}`).then((res) => {
      setReferenceData({ ...referenceData, [`${tables[0]}`]: res.data[0] });
    });
  }

  async function processDatasheet() {
    console.log("processing...");
    setLoader(true);
    // tmp table id mappings
    let tableIdMappings = {};
    let _staticTables = [...staticTables];

    for (let i = 0; i < _staticTables.length; i++) {
      let table = _staticTables[i];
      if (String(table.id).includes("tmp")) {
        let tmpTableId = table.id;
        const [dsTableId, certTableId] = await Promise.all([
          createDatasheetTable(table),
          createCertificateTable(table),
        ]);
        if (dsTableId === certTableId) {
          tableIdMappings[tmpTableId] = dsTableId;
          _staticTables[i].id = dsTableId;
        }
      }
    }

    // replace all tmp table ids with actual ids
    let updatedReadingRows = staticReadingRows.map((row) => {
      if (String(row[0])?.includes("tmp")) row[0] = tableIdMappings[row[0]];
      return row;
    });

    // prepare readings
    let config = staticTables.map((table, tableIndex) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration);
      return {
        tableId: table.id,
        index: tableIndex,
        formulas: defaultConfiguration.formulas,
        customPrecision: JSON.parse(table.customPrecision),
      };
    });

    updatedReadingRows = prepareDatasheetReadings({
      readings: updatedReadingRows,
      uncertaintyIndex: -3, // 3rd from last
      config: config,
      precisionCount: precisionCount,
      datasheet: datasheet,
      allStandards: allStandards,
      referenceData: referenceData,
      // TODO: add operations
      operations: [""],
    });
    updatedReadingRows = await processDatasheetTemplate(
      updatedReadingRows,
      instrumentId
    );

    // TODO: this process is getting called twice, which shouldn't, really need to optimize
    updatedReadingRows = prepareDatasheetReadings({
      readings: updatedReadingRows,
      uncertaintyIndex: -3, // 3rd from last
      config: config,
      precisionCount: precisionCount,
      datasheet: datasheet,
      allStandards: allStandards,
      referenceData: { ...referenceData },
      // TODO: add operations
      operations: ["setPrecision"],
    });

    // let ret = ViewAll.getUncertainties(
    //   staticTables,
    //   staticReadingRows,
    //   unceraintyMap,
    //   cmcReadings
    // );

    setStaticReadingRows([...updatedReadingRows]);
    setLoader(false);
  }

  function submitDatasheet() {
    setLoader(true);
    // prepare data
    let data = {
      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD HH:mm:ss")
        : null,
      nextDueDate: nextDueDate
        ? moment(nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: receiptDate
        ? moment(receiptDate).format("YYYY-MM-DD")
        : null,
      DCDate: dcDate ? moment(dcDate).format("YYYY-MM-DD") : null,
      identificationNo: identificationNo,
      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : null,
      make: make,
      ranges: ranges,
      lc: lc,
      DUCID: DUCID,
      mfgNo: mfgNo,
      startTemp: startTemp,
      endTemp: endTemp,
      startHumidity: startHumidity,
      endHumidity: endHumidity,
      configuration: JSON.stringify(configuration),
      totalReadings: staticReadingRows?.length || 0,
      tableDisplayStatus: tableDisplayStatus,
      calibratedby: user[0],
      revisionNumber: Number(revisionNumber) + 1,
      requestedname: requestedname,
      amendment:
        shouldShowAmendment && revisionNumber > -1
          ? amendmnetHistory.length
            ? amendmnetHistory +
              "\n" +
              reasonForAmendment +
              "," +
              moment().format("DD/MM/YYYY")
            : reasonForAmendment + "," + moment().format("DD/MM/YYYY")
          : "",
      additionalStandardIds: selectedStandards?.join(","),
      calibrationReason: calibrationReason,
      complianceStatus: complianceStatus,
      extraColumns: (() => {
        let tmp = {};
        extraColumns.map((e) => (tmp[e[0]] = e[1]));
        return JSON.stringify(tmp);
      })(),
    };
    if (filterRangeData.length) {
      delete filterRangeData.splice(filterRangeData.length - 2, 1);
      data.specificValues = filterRangeData.toString();
    }
    // patch req
    axios
      .patch(BASE_URL + `datasheets/${datasheetId}`, data)
      .then((res) => {
        console.log(moment().format("yyyy-MM-DD HH:mm:ss"));
        submitCertificate();
      })
      .catch((err) => {
        console.error(err);
        toast.error("error : ", err);
        setLoader(false);
      });
  }

  function submitCertificate() {
    let data = {
      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD")
        : null,
      nextDueDate: nextDueDate
        ? moment(nextDueDate).format("YYYY-MM-DD")
        : null,
      receiptDate: moment(receiptDate).format("YYYY-MM-DD"),
      DCDate: moment(dcDate).format("YYYY-MM-DD"),
      additionalStandardIds: selectedStandards?.join(","),
      identificationNo: identificationNo,
      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : null,
      make: make,
      ranges: ranges,
      lc: lc,
      DUCID: DUCID,
      mfgNo: mfgNo,
      startTemp: startTemp,
      endTemp: endTemp,
      tempDiff: String(
        Math.abs(Number(startTemp) - Number(endTemp)).toFixed(2)
      ),
      startHumidity: startHumidity,
      endHumidity: endHumidity,
      humDiff: String(
        Math.abs(Number(startHumidity) - Number(endHumidity)).toFixed(2)
      ),
      configuration: JSON.stringify(configuration),
      tableDisplayStatus: tableDisplayStatus,
      calibrationReason: calibrationReason,
      complianceStatus: complianceStatus,
    };

    if (filterRangeData.length) {
      // delete filterRangeData.splice(filterRangeData.length - 2, 1)
      data.specificValues = filterRangeData.toString();
    }

    // patch req
    axios
      .patch(BASE_URL + `certificates/${datasheetId}`, data)
      .then((res) => {
        submitSrfIntrument();
      })
      .catch((err) => {
        console.error(err);
        toast.error("error : ", err);
        setLoader(false);
      });
  }

  function submitSrfIntrument() {
    let data = {
      make: make,
      ranges: ranges,
      lc: lc,
      DUCID: DUCID,
      serialNumber: serialNo,
      model: models,
    };

    // patch req
    axios
      .patch(BASE_URL + `srfInstruments/${datasheetId}`, data)
      .then((res) => {
        submitSRF();
      })
      .catch((err) => {
        console.error(err);
        toast.error("error : ", err);
        setLoader(false);
      });
  }

  function submitSRF() {
    let row = {
      receiptDate: moment(receiptDate).format("YYYY-MM-DD"),
      dcNumber: dcNo,
      dcDate: moment(dcDate).format("YYYY-MM-DD"),
      // address: address,
      poNumber: poNo,
      poDate: moment(poDate).format("YYYY-MM-DD"),
    };
    axios
      .patch(BASE_URL + `srfs/${srfId}`, row)
      .then((res) => {
        createAutomatedTemplates();
      })
      .catch((err) => {
        console.log("error : ", err);
        setLoader(true);
      });
  }

  const createAutomatedTemplates = async () => {
    // tmp table id mappings
    let tableIdMappings = {};
    let _staticTables = [...staticTables];

    for (let i = 0; i < _staticTables.length; i++) {
      let table = _staticTables[i];
      if (String(table.id).includes("tmp")) {
        let tmpTableId = table.id;
        const [dsTableId, certTableId] = await Promise.all([
          createDatasheetTable(table),
          createCertificateTable(table),
        ]);
        if (dsTableId === certTableId) {
          tableIdMappings[tmpTableId] = dsTableId;
          _staticTables[i].id = dsTableId;
        }
      }
    }

    submitStaticDatasheetReadings(_staticTables);
  };
  const createDatasheetTable = async (table) => {
    // remove unwanted
    let data = { ...table };
    delete data.id;
    delete data?.lastModified;
    table.isUncertainty = false;
    table.isAutomated = 1;
    let res = await axios
      .post(BASE_URL + `datasheetStaticTables`, data)
      .then((res) => {});
    return res.data.insertId;
  };

  const createCertificateTable = async (table) => {
    // add necessary

    // remove unwanted
    let data = { ...table };
    delete data.id;
    delete data?.lastModified;

    // hide uncertainty by default
    table.isUncertainty = false;
    table.isAutomated = 1;
    let res = await axios
      .post(BASE_URL + `certificateStaticTables`, data)
      .then((res) => {});
    return res.data.insertId;
  };

  let withUnit = (val, unit) => {
    if (String(val).includes("_unit_") || String(val).includes("_unit_"))
      return val;
    return `${String(val)}#${unit ? unit.split("_unit_")[1] : unit}`;
  };

  const submitStaticDatasheetReadings = async (staticTables) => {
    let updatedReadingRows = staticReadingRows;

    // create JSON array of readings
    let readings = [];
    let datasheetReadingIds = [];
    let indx = 0;
    let currentUnit = -1;
    // for (const updatedReadingRow of updatedReadingRows) {
    updatedReadingRows.map((updatedReadingRow, idx) => {
      let row = {};
      row["tableId"] = updatedReadingRow[0];
      row["datasheetId"] = updatedReadingRow[1];
      let base = 2;

      let customColumnCount = getCustomColumnCount(
        updatedReadingRow[0],
        "static"
      );
      if (String(updatedReadingRow[base + 1]).includes("_unit_"))
        currentUnit = idx;
      for (let j = 0; j < customColumnCount; j++) {
        row[`c${j + 1}`] = withUnit(
          updatedReadingRow[base + j] !== undefined ||
            updatedReadingRow[base + j] !== null
            ? updatedReadingRow[base + j]
            : null,
          currentUnit > -1 ? updatedReadingRows[currentUnit][base + j] : ""
        );
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null;
      }

      // set uncertainty unit only
      if (
        String(updatedReadingRow[base + customColumnCount]).includes("_unit_")
      ) {
        row["uncertainty"] = updatedReadingRow[
          base + customColumnCount
        ]?.replaceAll("$", "");
      } else {
        row["uncertainty"] =
          updatedReadingRow[base + customColumnCount] !== undefined ||
          updatedReadingRow[base + customColumnCount] !== null
            ? updatedReadingRow[base + customColumnCount]
            : null;
        row["uncertainty"] = String(row["uncertainty"])?.replaceAll("$", "");
        row["uncertainty"] = withUnit(
          row["uncertainty"],
          currentUnit > -1
            ? updatedReadingRows[currentUnit][base + customColumnCount]
            : ""
        );
      }

      // set standard range ids
      row["standardRanges"] = updatedReadingRow[base + customColumnCount + 1]
        ? updatedReadingRow[base + customColumnCount + 1]
            ?.split(",")
            ?.filter((e) => e)
            ?.join(",")
        : updatedReadingRow[base + customColumnCount + 1];

      // resolve typeB values
      let table = staticTables.find((table) => table.id == row["tableId"]);
      let defaultConfiguration = JSON.parse(table.defaultConfiguration);

      if (defaultConfiguration?.typeb?.relations) {
        row["typeBValues"] = prepareTypeBValues(
          defaultConfiguration.typeb?.relations,
          row
        );
      } else {
        row["typeBValues"] = null;
      }

      updatedReadingRow[updatedReadingRow.length - 1] != 0 &&
        datasheetReadingIds.push(
          updatedReadingRow[updatedReadingRow.length - 1]
        );

      row["supportiveRanges"] =
        supportive[indx] !== undefined && supportive[indx] !== null
          ? supportive[indx]
          : null;
      indx += 1;
      readings.push(row);
    });

    let st = {};
    staticTables.map((e) => (st[e.id] = e));
    let changeLog = [
      ...readings
        .map((e, i) => [
          i,
          [
            ...Object.entries(e)
              .map((r, j) =>
                datasheetStaticReadings[i] &&
                r[1] == datasheetStaticReadings[i][r[0]]
                  ? true
                  : `${r[0].startsWith("c") ? st[e.tableId][r[0]] : r[0]} : ${
                      datasheetStaticReadings[i]
                        ? datasheetStaticReadings[i][r[0]]
                        : ""
                    } >> ${r[1]}`
              )
              .filter((e2) => e2 !== true),
          ],
        ])
        .filter((e) => e[1].length)
        .map((e) => `${e[0]} : ${e[1].join(" , ")}`),
    ]
      .join(" | ")
      .replaceAll("#", " ")
      .replaceAll("_rh_", "")
      .replaceAll("_unit_", "");
    // clear previous readings
    let status = true;
    if (datasheetReadingIds?.length > 0) {
      status = await clearPreviousReadings(
        "datasheetStaticReadings",
        datasheetReadingIds
      );
    }
    if (status === true) {
      // update datasheet readings
      if (readings.length > 0) {
        axios
          .post(BASE_URL + "datasheetStaticReadings/bulk", readings)
          .then(async (res) => {
            if (changeLog.length)
              await generateAudit(
                "datasheet",
                jobNumber,
                user[0],
                moment().format("yyyy-MM-DD HH:mm:ss"),
                "Reading Updated",
                changeLog,
                clientIdNumber
              );

            updateSRFAnalytics([datasheetId], 60);

            submitStaticCertificateReadings(
              updatedReadingRows,
              readings,
              staticTables
            );
          })
          .catch((err) => {
            console.error(err);
            toast.error("error : ", err);
            setLoader(false);
          });
      } else {
        setLoader(false);
        updateSRFAnalytics([datasheetId], 25);
      }
    } else {
      toast.error("Failed to save datasheet records, please try again");
    }
  };

  const submitStaticCertificateReadings = async (
    updatedReadingRows,
    dsReadings,
    staticTables
  ) => {
    // sync automated tables with datasheet
    let _certificateStaticTables = [...certificateStaticTables];
    staticTables.forEach((table) => {
      if (
        !_certificateStaticTables.some((certTable) => certTable.id == table.id)
      ) {
        _certificateStaticTables.push(table);
      }
    });

    // prepare readings
    let config = _certificateStaticTables.map((table) => {
      let defaultConfiguration = JSON.parse(table.defaultConfiguration);
      return {
        tableId: table.id,
        formulas: defaultConfiguration.formulas,
        relations: defaultConfiguration.relations,
        totalColumns: defaultConfiguration.totalColumns,
      };
    });
    updatedReadingRows = prepareCertificateReadings({
      datasheetReadings: updatedReadingRows,
      config: config,
      precisionCount: precisionCount,
    });

    // create json array of readings
    let readings = [];
    let certificateReadingIds = [];
    for (let i = 0; i < updatedReadingRows?.length; i++) {
      let row = {};
      row["tableId"] = updatedReadingRows[i][0];
      row["certificateId"] = Number(updatedReadingRows[i][1]);
      let base = 2;

      let customColumnCount = getCustomColumnCount(
        updatedReadingRows[i][0],
        "static",
        "certificate",
        _certificateStaticTables
      );

      for (let j = 0; j < customColumnCount; j++) {
        row[`c${j + 1}`] =
          updatedReadingRows[i][base + j] !== undefined ||
          updatedReadingRows[i][base + j] !== null
            ? updatedReadingRows[i][base + j]
            : null;
      }

      for (let j = customColumnCount; j < MAX_COLS; j++) {
        row[`c${j + 1}`] = null;
      }

      updatedReadingRows[i][updatedReadingRows[i].length - 1] != 0 &&
        certificateReadingIds.push(
          updatedReadingRows[i][updatedReadingRows[i].length - 1]
        );

      row["uncertainty"] = (dsReadings[i]["uncertainty"] || "").split("#")[0];
      // set standard range ids
      row["standardRanges"] = dsReadings[i]["standardRanges"]
        ? dsReadings[i]["standardRanges"]
            ?.split(",")
            ?.filter((e) => e)
            ?.join(",")
        : null;
      readings.push(row);
    }

    // clear previous readings
    certificateReadingIds = certificateStaticReadings.map((row) => row.id);
    // clear previous readings
    let status = true;
    if (certificateReadingIds?.length > 0)
      status = await clearPreviousReadings(
        "certificateStaticReadings",
        certificateReadingIds
      );
    let st = {};
    staticTables.map((e) => (st[e.id] = e));
    let changeLog = [
      ...readings
        .map((e, i) => [
          i,
          [
            ...Object.entries(e)
              .map((r, j) =>
                certificateStaticReadings[i] &&
                r[1] == certificateStaticReadings[i][r[0]]
                  ? true
                  : `${r[0].startsWith("c") ? st[e.tableId][r[0]] : r[0]} : ${
                      certificateStaticReadings[i]
                        ? certificateStaticReadings[i][r[0]]
                        : ""
                    } >> ${r[1]}`
              )
              .filter((e2) => e2 !== true),
          ],
        ])
        .filter((e) => e[1].length)
        .map((e) => `${e[0]} : ${e[1].join(" , ")}`),
    ]
      .join(" | ")
      .replaceAll("#", " ")
      .replaceAll("_rh_", "")
      .replaceAll("_unit_", "");
    if (status === true) {
      axios
        .post(BASE_URL + "certificateStaticReadings/bulk", readings)
        .then(async (res) => {
          toast.success("Successfully Updated!");
          if (changeLog.length)
            await generateAudit(
              "certificate",
              certificateNumber,
              user[0],
              moment().format("yyyy-MM-DD HH:mm:ss"),
              "Reading Updated",
              changeLog,
              clientIdNumber
            );
          setLoader(false);
          setTimeout(() => window.location.reload(false), 500);
        })
        .catch((err) => {
          console.error(err);
          toast.error("error : ", err);
          setLoader(false);
        });
    } else {
      toast.error("Failed to save datasheet, please try again");
    }
  };

  const initiateCmcReadingRows = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < cmcReadings.length; i++) {
      rowsSpace.push([null, null, null, null]);
      rows.push([
        cmcReadings[i].id,
        cmcReadings[i].fromRange,
        cmcReadings[i].toRange,
        cmcReadings[i].lowerCmc,
        cmcReadings[i].higherCmc,
      ]);

      setCmcReadingRows(rows);
      // setCmcReadingRangeValue(rowsSpace);
    }
  };

  const initiateStaticReadingRows = () => {
    if (!(datasheetStaticReadings && certificateStaticReadings)) return null;
    let rows = {};
    let _selectedRangeIds = selectedRangeIds;
    let tS = [];
    let rowUnitMap = {};
    let CurrentUnitRow = -1;
    let dcMap = {
      ...dCReadingIdMap,
    };

    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));

    datasheetStaticReadings.forEach((reading, index) => {
      let row = [];
      row.push(reading.tableId);
      row.push(reading.datasheetId);
      if (!rowUnitMap[reading.tableId]) {
        rowUnitMap[reading.tableId] = {};
      }
      if (reading["c1"].includes("_unit_")) {
        CurrentUnitRow = [reading.id, reading.uncertainty];
      } else {
        rowUnitMap[reading.tableId][reading.id] = CurrentUnitRow;
      }

      for (let i = 0; i < MAX_COLS; i++) {
        // check for default value
        if (reading["c" + (i + 1)] != null) {
          row.push(
            reading["c" + (i + 1)] === "NA"
              ? ""
              : `${
                  derviedColumns[reading.tableId]?.includes("c" + (i + 1)) ||
                  tableMap[reading.tableId]?.[`c${i + 1}`]?.includes(
                    UNCERTAINTY_LINK
                  )
                    ? "$"
                    : ""
                }${String(reading["c" + (i + 1)]).replace(/[$]+/, "$")}`
          );
        } else {
          break;
        }
      }
      row.push("$" + reading?.uncertainty);
      row.push(reading.standardRanges);
      row.push(reading.id);
      dcMap[reading.id] = certificateStaticReadings[index]?.id;
      // IMP NOTE:
      // this is highly sensitive array, before adding any new fields to row, please check the consequences and impacting area specially static indexing
      if (rows[reading.tableId]?.length > 0) {
        rows[reading.tableId] = [...rows[reading.tableId], [...row]];
      } else {
        rows[reading.tableId] = [[...row]];
      }
      tS.push(reading.supportiveRanges);

      // set current ranges to selectedRangeIds
      _selectedRangeIds[index] = reading.standardRanges;
    });

    rows = parseRows(rows);

    setSupportive(tS);
    setSelectedRangeIds(_selectedRangeIds);

    let allRows = [];
    for (const tableId in rows) {
      if (rows[tableId]) allRows.push(...rows[tableId]);
    }
    setStaticReadingRows([...allRows]);
    setDCReadingIdMap({ ...dcMap });
  };

  const addStaticReadingRow = (
    tableIndex,
    tableId,
    rowIndex = null,
    type = null
  ) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static");

    let row = [tableId, Number(datasheetId)].concat(
      Array(totalColumns).fill("")
    );
    let tableMap = {};
    staticTables?.map((s) => (tableMap[s.id] = s));
    row = row.map((e, i) =>
      i !== 0 && i !== 1
        ? e === "NA"
          ? ""
          : `${
              derviedColumns[tableId].includes("c" + (i - 1)) ||
              tableMap[tableId][`c${i - 1}`]?.includes(UNCERTAINTY_LINK)
                ? "$"
                : ""
            }${e}`
        : e
    );
    // if (unceraintyConfig[tableId].isUncertainty)
    row.push("$"); // uncertainty
    row.push(null); // standard range
    row.push(0); // database row id

    if (
      unceraintyConfig[tableId].defaultValues &&
      Object.keys(unceraintyConfig[tableId].defaultValues).length
    ) {
      let tmp = [...row];
      let updatedRows = [];
      let col = ["", ""];
      Object.keys(unceraintyConfig[tableId].defaultValues).map((val) => {
        col[0] = val;
      });
      col[1] = (unceraintyConfig[tableId].defaultValues[col[0]] || "")
        .split(",")
        .map((c) => c.trim());
      for (let i = 0; i < col[1].length; i++) {
        tmp[1 + Number(col[0].split("c")[1])] = `$${col[1][i]}`;
        updatedRows.push(tmp);
        tmp = [...row];
      }
      row = [...updatedRows];
    } else row = [row];

    if (type === "copy") {
      // row = [...staticReadingRows[rowIndex]];
      row[0][totalColumns + 3] = staticReadingRows[rowIndex][totalColumns + 3];
      let tS = [...supportive];
      tS.push(tS[rowIndex]);
      setSupportive([...tS]);
    }

    // newReadingRows.push(row);
    newReadingRows = newReadingRows.concat([...row]);
    setStaticReadingRows([...newReadingRows]);
  };

  const addStaticUnitRow = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(
      Array(totalColumns).fill(units ? "_unit_" + units[0] : "")
    );
    if (unceraintyConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setStaticReadingRows([...newReadingRows]);
  };

  const addStaticRowHeader = (tableIndex, tableId) => {
    let newReadingRows = [...staticReadingRows];
    let totalColumns = getCustomColumnCount(tableId, "static") + 1; // for custom + uncertainty

    let row = [tableId, datasheetId].concat(Array(totalColumns).fill("_rh_")); //_rh_ : row header
    if (unceraintyConfig[tableId].isUncertainty) row.push(null); // standard range
    row.push(0);
    newReadingRows.push(row);
    setStaticReadingRows([...newReadingRows]);
  };

  const deleteStaticReadingRow = (rowIndex) => {
    let newReadingRows = [...staticReadingRows];
    if (newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1])
      Promise.all([
        axios.delete(
          BASE_URL +
            `datasheetStaticReadings/${
              newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
            }`,
          { status: -1 }
        ),
        axios.delete(
          BASE_URL +
            `certificateStaticReadings/${
              dCReadingIdMap[
                newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1]
              ]
            }`,
          { status: -1 }
        ),
      ])
        .then((res) => {
          toast.success("deleted successfully!");
        })
        .catch((err) => {
          console.log("delete error : ", err);
          toast.error("delete error : " + err);
        });

    setDeletedIds([
      ...deletedIds,
      newReadingRows[rowIndex][newReadingRows[rowIndex]?.length - 1],
    ]);

    newReadingRows.splice(rowIndex, 1);
    setStaticReadingRows([...newReadingRows]);
  };

  const updateStaticCellValue = (rowIndex, cellIndex, value) => {
    let newReadingRows = [...staticReadingRows];
    if (cellIndex === 0 && value.includes("_unit_")) {
      newReadingRows[rowIndex][cellIndex + 2] = value;
      let tmp = newReadingRows[rowIndex].map((e, i) =>
        i > 1 &&
        i < newReadingRows[rowIndex].length - 2 &&
        e.replaceAll("_unit_", "").length === 0
          ? value
          : e
      );
      newReadingRows[rowIndex] = tmp;
    } else newReadingRows[rowIndex][cellIndex + 2] = value;
    setStaticReadingRows([...newReadingRows]);
    // setActiveCell(value)
    // setActiveRow(rowIndex)
    // setActiveCellIndex(cellIndex)
    setActiveRowIndex(-1);
    setActiveTable(-1);
  };

  const updateStandardRangesOfRow = (value, index) => {
    if (index === -1) {
      let tS = [...supportive];
      tS[activeRowIndex] = value;
      setSupportive([...tS]);
      return;
    }
    let newReadingRows = [...staticReadingRows];
    // TODO: correct below logic, as it is highly coupled to index which it should not at all !!!
    let rangeIds =
      newReadingRows[activeRowIndex][newReadingRows[activeRowIndex].length - 2];
    rangeIds = String(rangeIds || "").split(",");

    rangeIds[index - 1] = value;

    newReadingRows[activeRowIndex][newReadingRows[activeRowIndex].length - 2] =
      rangeIds.join(",");

    setStaticReadingRows([...newReadingRows]);

    let _selectedRangeIds = selectedRangeIds;
    _selectedRangeIds[activeRowIndex] = rangeIds.join(",");
    setSelectedRangeIds(_selectedRangeIds);
    setSelectedStandardMasters(_selectedRangeIds);
  };

  const deleteTable = (tableId) => {
    // append into previoud ids
    let data = {
      deletedTableIds: (deletedTableIds ? deletedTableIds + "," : "") + tableId,
    };
    axios
      .patch(BASE_URL + `datasheets/${datasheetId}`, data)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
    axios
      .patch(BASE_URL + `certificates/${datasheetId}`, data)
      .then((res) => {
        window.location.reload(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const prepareGaugeTable = () => {
    // calc. T
    let a = gaugeData?.size + gaugeData?.ht;
    let b = gaugeData?.size + gaugeData?.lt;
    let [g, k] = a > b ? [a, b] : [b, a];
    g = Number(g.toFixed(4));
    k = Number(k.toFixed(4));

    let t = Number((g - k).toFixed(3));

    let instrumentId = instrument?.isGauge == 5 ? 2 : 1;

    axios
      .get(
        BASE_URL +
          `gauges?_where=(nominalSizeUpto,gte,${gaugeData?.size})~and(t,gte,${
            t * 1000
          })~and(instrumentId,eq,${instrumentId})`
      )
      .then((res) => {
        if (res.data?.length <= 0) return;
        // calculate values for go, no go, wear limit
        let data = res.data[0]; // for largest T
        data["Z"] = data.Z / 1000;
        data["H2"] = data.H2 / 1000;
        data["Y"] = data.Y / 1000;
        data["alpha"] = data.alpha / 1000;

        let specifiedSize = { go: "", nogo: "", wl: null };
        let go1, go2, nogo1, nogo2;

        // for plain gauge
        if (instrument?.isGauge != 5) {
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (k + data.Z + data.H2).toFixed(4);
            go2 = (k + data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (g + data.H2).toFixed(4);
            nogo2 = (g - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (k - data.Y).toFixed(4);
          } else {
            // more than 180
            go1 = (k + data.Z + data.H2).toFixed(4);
            go2 = (k + data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (g - data.alpha + data.H2).toFixed(4);
            nogo2 = (g - data.alpha - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (k - data.Y + data.alpha).toFixed(4);
          }
        } else {
          // for snap gauge
          if (gaugeData.size <= 180) {
            // less than 180
            go1 = (g - data.Z + data.H2).toFixed(4);
            go2 = (g - data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (k + data.H2).toFixed(4);
            nogo2 = (k - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (g + data.Y).toFixed(4);
          } else {
            // more  than 180
            go1 = (g - data.Z + data.H2).toFixed(4);
            go2 = (g - data.Z - data.H2).toFixed(4);
            [go1, go2] = go1 < go2 ? [go1, go2] : [go2, go1];
            specifiedSize.go = go1 + " / " + go2;

            nogo1 = (k + data.alpha + data.H2).toFixed(4);
            nogo2 = (k + data.alpha - data.H2).toFixed(4);
            [nogo1, nogo2] = nogo1 < nogo2 ? [nogo1, nogo2] : [nogo2, nogo1];
            specifiedSize.nogo = nogo1 + " / " + nogo2;

            specifiedSize.wl = (g + data.Y - data.alpha).toFixed(4);
          }
        }

        specifiedSize = [
          specifiedSize.go,
          specifiedSize.nogo,
          specifiedSize.wl,
        ];

        let _staticReadingRows = staticReadingRows;
        specifiedSize.forEach((val, index) => {
          _staticReadingRows[index][3] = val;
        });
        setStaticReadingRows([..._staticReadingRows]);

        setGaugeData({
          ...gaugeData,
          isPrepared: true,
          g: g,
          k: k,
          t: t,
          h2: data.H2,
          alpha: data.alpha,
          z: data.Z,
          y: data.Y,
        });
      });
  };

  const fetchCertificateDetails = () => {
    axios
      .get(
        BASE_URL + `certificates/${datasheetId}?_fields=certificateNumber,ULRNo`
      )
      .then((res) => {
        setCertifucateNumber(res.data[0]?.certificateNumber);
        setULRNumber(res.data[0]?.ULRNo);
      });
  };

  const fetchDisciplineDetails = (id) => {
    axios
      .get(
        BASE_URL +
          `xjoin?_join=ds.datasheets,_j,ins.instruments,_j,disc.discipline&_on1=(ds.instrumentId,eq,ins.id)&_on2=(ins.disciplineId,eq,disc.id)&_fields=disc.documentNumber,disc.issueNo,disc.issueDate,disc.amendmentNo,disc.amendmentDate&_where=(disc.id,eq,${id})`
      )
      .then((res) => {
        setDocumentNumber(res.data[0]?.disc_documentNumber);
        setIssueNo(res.data[0]?.disc_issueNo);
        setIssueDate(res.data[0]?.disc_issueDate);
        setAmendmentNo(res.data[0]?.disc_amendmentNo);
        setAmendmentDate(res.data[0]?.disc_amendmentDate);
      });
  };

  useEffect(() => {
    if (nextDueDate) calculateNextDueDate();
  }, [calibrationDate]);

  useEffect(() => {
    initiateCmcReadingRows();
  }, [cmcReadings]);

  useEffect(() => {
    if (datasheetStaticReadings && certificateStaticReadings)
      initiateStaticReadingRows();
  }, [datasheetStaticReadings, certificateStaticReadings]);

  useEffect(() => {
    setLoader(true);
    Promise.all([
      fetchDatasheet(),
      fetchUnits(),
      fetchSettings(),
      getReferenceData(),
      fetchCertificateDetails(),
    ])
      .then((res) => {
        setLoader(false);
        setDataLoaded(true);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  //validation Function
  const validateStandards = (type) => {
    if (type === 1) {
      let expiredStandards = [];
      standardMasterArray.map((standard) => {
        if (moment().isAfter(standard.validUpto)) {
          expiredStandards.push(standard.standardName);
        }
      });
      return expiredStandards;
    }
  };

  useEffect(() => {
    if (standardMasterArray.length > 0) {
      let expiredStandards = validateStandards(1);
      if (expiredStandards.length > 0) {
        window.alert(
          `below standards are expired\n${expiredStandards.join(
            "\n"
          )} \nPlease recalibrate these standards and edit datasheet later!`
        );
      }
    }
  }, [standardMasterArray]);

  const renderCommonHeader = (defaultConfiguration) => {
    if (!defaultConfiguration) return null;
    defaultConfiguration = JSON.parse(defaultConfiguration);
    let hasCommonHeader = defaultConfiguration.hasCommonHeader;
    if (hasCommonHeader && defaultConfiguration.commonHeaderConfig) {
      let commonHeaderConfig =
        defaultConfiguration.commonHeaderConfig?.split(",");
      let cols = [];
      commonHeaderConfig.forEach((col) => {
        let [name, size] = col?.split(":");
        let classNames =
          "header-border " + (name != "$" ? "header-border-bottom" : "");
        cols.push(
          <TableCell
            className={classNames}
            align="center"
            style={{ textAlign: "center" }}
            colSpan={Number(size)}
          >
            {name != "$" ? name : ""}
          </TableCell>
        );
      });

      return (
        <TableRow>
          {/* for SN */}
          {!isPrinting && (
            <>
              <TableCell className="header-border" align="center"></TableCell>
              <TableCell className="header-border" align="center"></TableCell>
            </>
          )}
          {cols}
        </TableRow>
      );
    }
    return null;
  };

  const renderUnit = (value, onChange) => {
    return (
      <div id="datasheet-editdatasheet_readingrow_selectunit">
        {isPrinting ? (
          <Typography variant="p">{value}</Typography>
        ) : (
          <select name="units" id="units" onChange={onChange}>
            <option value={""} key={""} selected></option>
            {units?.map((unit) => {
              if (unit == value) {
                return (
                  <option value={unit} key={unit} selected>
                    {unit}
                  </option>
                );
              } else {
                return (
                  <option value={unit} key={unit}>
                    {unit}
                  </option>
                );
              }
            })}
          </select>
        )}
      </div>
    );
  };

  const getStandardRangeLC = (
    standardRangeId,
    stdRangePref,
    isRange,
    tableIndex
  ) => {
    standardRangeId = standardRangeId?.split(",").map((e) => e.split(":")[1])[
      stdRangePref > 0 ? stdRangePref - 1 : 0
    ];
    let range = allStandards?.filter(
      (range) => range.rangeId === Number(standardRangeId)
    );
    let duc_ranges;
    if (isRange) {
      duc_ranges = ranges?.split("||");
      if (duc_ranges?.length > 1) {
        duc_ranges = duc_ranges[tableIndex || 0];
      } else duc_ranges = duc_ranges[0];
      range = range?.[0]?.range
        ? Math.max(
            ...(range[0]?.range
              .split("|")
              .map((e) => Number(e.split("#")[0]))
              .filter((e) => !isNaN(e)) || [])
          )
        : null;
      duc_ranges =
        duc_ranges !== undefined || duc_ranges !== null
          ? Math.max(
              ...(duc_ranges
                .split("|")
                .map((e) => Number(e.split("#")[0]))
                .filter((e) => !isNaN(e)) || [])
            )
          : null;

      if (range && range !== null && duc_ranges && duc_ranges !== null)
        range = Math.min(range, duc_ranges);
      else if (!range && duc_ranges) range = duc_ranges;
    }

    return isRange ? range : range[0]?.lc.split("#")[0];
  };

  const getConditionalFormattedColor = (
    value,
    cellIndex,
    table,
    tableIndex,
    rowIndex
  ) => {
    value = `${value}`.replaceAll("$", "");
    let { relations } = JSON.parse(table?.conditionFormatting || "{}");
    let dsCol;
    let config = relations["c" + cellIndex];
    if (relations) {
      if (config) {
        let _dsCol = config.dsCol.split(".");
        dsCol = !String(_dsCol[0])?.includes("ds")
          ? Number(_dsCol[0])
          : _dsCol[0];
        let stdRangePref = Number(_dsCol[1] || "0");

        let valToCompare = 0;
        if (String(dsCol)?.includes("ds")) {
          _dsCol = dsCol.split("ds")[1];
          valToCompare = staticReadingRows[rowIndex][Number(_dsCol) + 2 - 1];
        } else {
          valToCompare = [
            0,
            getStandardRangeLC(
              staticReadingRows[rowIndex][
                staticReadingRows[rowIndex].length - 2
              ],
              stdRangePref,
              true,
              tableIndex
            ) || 0,
            datasheet.lc,
            getStandardRangeLC(
              staticReadingRows[rowIndex][
                staticReadingRows[rowIndex].length - 2
              ],
              stdRangePref
            ) || 0,
            datasheet.accuracy,
          ][dsCol - 1];
        }

        // reformat the data
        valToCompare = String(valToCompare)
          ?.replace("\n", "")
          .replaceAll("$", "")
          .split("||");

        valToCompare =
          valToCompare?.length > tableIndex
            ? valToCompare[tableIndex]
            : valToCompare[0];
        if (
          config.condition?.toLowerCase() === "lt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) <
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          complianceStatus != 0 && setComplianceStatus(0);
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "gt" &&
          Number(dsCol == 2 ? value : Math.abs(value)) >
            Number(dsCol == 2 ? valToCompare : Math.abs(valToCompare))
        ) {
          complianceStatus != 0 && setComplianceStatus(0);
          // alert("Calibration Point Can't be greater than Master Range!");
          if (
            dsCol === 2 &&
            !outOffRangeTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
          ) {
            setOutOffRangeTracker({
              ...outOffRangeTracker,
              [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
            });
          }
          return config.color;
        } else if (
          config.condition?.toLowerCase() === "precisioncountnotequal"
        ) {
          value = value.toString();
          let valueDecimalCount = value.includes(".")
            ? value.split(".").pop().length
            : 0;
          valToCompare = valToCompare.toString();
          let valToCompareDecimalCount = 0;
          if (String(valToCompare).includes(".")) {
            valToCompareDecimalCount = String(valToCompare).split(".")[1];
            valToCompareDecimalCount =
              valToCompareDecimalCount.split("#")[0].length;
          }

          if (valueDecimalCount !== valToCompareDecimalCount) {
            complianceStatus != 0 && setComplianceStatus(0);
            if (!precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]) {
              setPrecNotEqTracker({
                ...precNotEqTracker,
                [`${tableIndex}_${rowIndex}_${cellIndex}`]: true,
              });
            }
            return config.color;
          }
        }
      }
    }
    if (
      dsCol == 2 &&
      outOffRangeTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...outOffRangeTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      setOutOffRangeTracker(tmp);
    }
    if (
      config?.condition?.toLowerCase() === "precisioncountnotequal" &&
      precNotEqTracker[`${tableIndex}_${rowIndex}_${cellIndex}`]
    ) {
      let tmp = { ...precNotEqTracker };
      delete tmp[`${tableIndex}_${rowIndex}_${cellIndex}`];
      setPrecNotEqTracker(tmp);
    }
    return "";
  };

  const renderTableCell = (
    table,
    tableIndex,
    row,
    rowIndex,
    cell,
    cellIndex,
    onChange = {}
  ) => {
    let field = null;

    if (deletedColumns[table.id]?.includes("c" + (cellIndex + 2))) return null;

    if (String(cell)?.includes("_unit_")) {
      //removed check to check to show text field instead of unit for m-x
      let value = cell.split("_unit_")[1];
      field = renderUnit(value, (e) => {
        updateStaticCellValue(rowIndex, cellIndex, "_unit_" + e.target.value);
      });
    } else if (cell && cell[0] == "$") {
      field = (
        <span
          style={{
            color: getConditionalFormattedColor(
              row[cellIndex + 2],
              cellIndex + 1,
              table,
              tableIndex,
              rowIndex
            ),
          }}
        >
          {/* {!isNaN(cell.slice(1))
            ? Number(cell.slice(1)).toFixed(4)
            : cell.slice(1)} */}
          {cell.slice(1)}
        </span>
      );
    } else if (cell == "null" && cell == null) {
      field = (
        <TextField
          size="small"
          value={""}
          InputProps={{
            style: {
              fontSize: "12px",
              margin: "0px",
              padding: "0px",
            },
          }}
          onChange={(e) => {
            updateStaticCellValue(rowIndex, cellIndex, e.target.value);
          }}
          variant="standard"
        ></TextField>
      );
    } else {
      field = (
        <>
          {isPrinting ? (
            <Typography variant="p">{row[cellIndex + 2]}</Typography>
          ) : (
            <TextField
            id="datasheet-editdatasheet_readingrow_enterReading"
              size="small"
              sx={{
                input: {
                  color: getConditionalFormattedColor(
                    row[cellIndex + 2],
                    cellIndex + 1,
                    table,
                    tableIndex,
                    rowIndex
                  ),
                },
              }}
              value={row[cellIndex + 2]}
              InputProps={{
                style: {
                  fontSize: "12px",
                  margin: "0px",
                  padding: "0px",
                },
              }}
              onChange={(e) => {
                updateStaticCellValue(rowIndex, cellIndex, e.target.value);
              }}
              variant="standard"
            ></TextField>
          )}
        </>
      );
    }
    return (
      <TableCell
        key={cellIndex}
        style={{
          padding: "4px",
        }}
        variant="standard"
      >
        {field}
      </TableCell>
    );
  };

  const renderActions = (
    row,
    rowIndex,
    range,
    tableId,
    tableIndex,
    hasActions = false
  ) => {
    return (
      <ButtonGroup size="small" aria-label="small button group">
        {hasActions && (
          <>
            <Button
             id="datasheet-editdatasheet_readingrow_copy"
              onClick={(e) => {
                addStaticReadingRow(tableIndex, tableId, rowIndex, "copy");
              }}
            >
              <ContentCopyIcon />
            </Button>
          </>
        )}
      </ButtonGroup>
    );
  };

  const checkMaster = (index) => {
    let rangeId = staticReadingRows[index][staticReadingRows[index].length - 2];
    rangeId =
      rangeId && rangeId !== "" && rangeId?.length > 0
        ? rangeId?.split(",")
        : [];
    rangeId = rangeId.filter((e) => e !== "");

    if (rangeId.length) return true;
    return false;
  };

  const renderRows = (table, tableIndex, staticReadingRows, range) => {
    let rows = [];
    let rowType = "";
    staticReadingRows?.forEach((row, rowIndex) => {
      let _row = [];
      if (row[0] == table.id) {
        if (String(row[2])?.includes("_rh_")) {
          rowType = "header";
          _row = (
            <TableRow key={rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(row, rowIndex, range, table.id, tableIndex)}
                </TableCell>
              ) : (
                ""
              )}
              <TableCell colSpan={row.length - 3 - (isPrinting ? 2 : 0)}>
                <TextField
                  id="datasheet-editdatasheet_readingrow_enterrrowheading"
                  size="small"
                  fullWidth
                  value={row[0 + 2].split("_rh_")[1]} // 2: row_base
                  InputProps={{
                    style: {
                      fontSize: "14px",
                      margin: "auto",
                      padding: "0px",
                    },
                  }}
                  onChange={(e) => {
                    updateStaticCellValue(rowIndex, 0, "_rh_" + e.target.value);
                  }}
                ></TextField>
              </TableCell>
              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                    id="datasheet-editdatasheet_readingrowheader_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        deleteStaticReadingRow(rowIndex);
                        let tmp = { ...outOffRangeTracker };
                        let tmp2 = { ...outOffRangeTracker };
                        let tmp3 = { ...precNotEqTracker };
                        let tmp4 = { ...precNotEqTracker };
                        Object.keys(tmp).map((key) => {
                          if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                            delete tmp2[key];
                          }
                        });
                        Object.keys(tmp3).map((key) => {
                          if (key.includes(`${tableIndex}_${rowIndex}_`)) {
                            delete tmp4[key];
                          }
                        });
                        setOutOffRangeTracker(tmp2);
                        setPrecNotEqTracker(tmp4);
                      }}
                    />
                  )}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          );
        } else if (String(row[2])?.includes("_unit_")) {
          rowType = "unit";
          _row = (
            <TableRow key={rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(row, rowIndex, range, table.id, tableIndex)}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting && <TableCell></TableCell>}
              {row
                .slice(
                  2,
                  row.length -
                    (!isPrinting && unceraintyConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                ) // 2: tableId + datasheetId, 1: uncertainty, stdRanges
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}
              {!isPrinting && (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                    id="datasheet-editdatasheet_readingrowunit_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        deleteStaticReadingRow(rowIndex);
                      }}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        } else {
          rowType = "data";
          _row = (
            <TableRow key={rowIndex}>
              {!isPrinting ? (
                <TableCell>
                  {renderActions(
                    row,
                    rowIndex,
                    range,
                    table.id,
                    tableIndex,
                    true
                  )}
                </TableCell>
              ) : (
                ""
              )}
              {!isPrinting ? (
                <TableCell>
                  <button
                  id="datasheet-editdatasheet_readingrow_selectmaster"
                    style={{
                      color: checkMaster(rowIndex) ? "green" : "red",
                      rotate:
                        table.id === activeTable && rowIndex === activeRowIndex
                          ? "180deg"
                          : "0deg",
                    }}
                    onClick={() => {
                      if (activeTable === -1 || activeRowIndex === -1) {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      } else if (
                        activeTable === table.id &&
                        activeRowIndex === rowIndex
                      ) {
                        setActiveRowIndex(-1);
                        setActiveTable(-1);
                      } else {
                        setActiveRowIndex(rowIndex);
                        setActiveTable(table.id);
                      }
                    }}
                  >
                    <ExpandCircleDownOutlined />
                  </button>
                </TableCell>
              ) : (
                ""
              )}
              {row
                .slice(
                  2,
                  row.length -
                    (!isPrinting && unceraintyConfig[table.id]?.isUncertainty
                      ? 2
                      : 3)
                ) // 2: tableId + datasheetId, 2: standardRanges, rowId
                .map((cell, cellIndex) =>
                  renderTableCell(
                    table,
                    tableIndex,
                    row,
                    rowIndex,
                    cell,
                    cellIndex
                  )
                )}

              {!isPrinting ? (
                <TableCell>
                  {showComponent("delete") && (
                    <DeleteIcon
                      id="datasheet-editdatasheet_readingrow_delete"
                      style={{
                        color: "#dc3545",
                      }}
                      onClick={(e) => {
                        deleteStaticReadingRow(rowIndex);
                      }}
                    />
                  )}
                </TableCell>
              ) : (
                ""
              )}
            </TableRow>
          );
        }
      }
      if (
        activeTable === table.id &&
        rowIndex == activeRowIndex &&
        rowType == "data"
      ) {
        let totalStandards = standardMasterArray?.length || 0;
        let standardRow = (
          <tr id="datasheet-editdatasheet_readingrow_selectmasterrow">
            <td></td>
            <td></td>
            <TableCell colSpan={row.length - 2}>
              {" "}
              <StandardMasterSelection
                index={0}
                label="Standard master 1"
                allStandards={allStandards}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[0] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={1}
                label="Standard master 2"
                allStandards={allStandards}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[1] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={2}
                label="Standard master 3"
                allStandards={allStandards}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[2] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={3}
                label="Standard master 4"
                allStandards={allStandards}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                valId={
                  (
                    (
                      staticReadingRows[activeRowIndex][
                        staticReadingRows[activeRowIndex].length - 2
                      ] || ""
                    ).split(",")[3] || ""
                  ).split(":")[1] || ""
                }
              />
              <br />
              <StandardMasterSelection
                index={0}
                label="Supportive master"
                allStandards={supportiveStandards}
                updateStandardRangesOfRow={updateStandardRangesOfRow}
                isSupportive={true}
                valId={supportive[activeRowIndex] || ""}
              />
            </TableCell>
          </tr>
        );
        rows.push(_row, standardRow);
      } else {
        rows.push(_row);
      }
    });

    return rows;
  };

  const renderStaticTables = () => {
    if (!staticTables) return null;
    let range = 0;
    return staticTables?.map((table, tableIndex) => {
      if (tableDisplayStatus === 1 && table.isAutomated !== 0) return null;
      else if (tableDisplayStatus === 2 && table.isAutomated !== 1) return null;

      range = 0;
      return (
        <div key={tableIndex} sx={{ mb: 5 }} style={{ marginBottom: "60px" }}>
          {!isPrinting ? (
            <>
              <Typography
                variant="h6"
                component="div"
                style={{ float: "left" }}
              >
                {tableIndex + 1 + ". "}
                <TextField
                  id="outlined-basic"
                  size="small"
                  variant="standard"
                  type="text"
                  value={
                    configuration?.customTableNames?.[table.id] ||
                    table.tableName
                  }
                  onChange={(e) => {
                    setConfiguration({
                      ...configuration,
                      customTableNames: {
                        ...configuration.customTableNames,
                        [table.id]: e.target.value,
                      },
                    });
                  }}
                />
                {showComponent("delete") ? "|" : ""}
                {showComponent("delete") && (
                  <Button size="sm">
                    <DeleteIcon
                    id="datasheet-editdatasheet_readingtablehead_delete"
                      style={{
                        color: "#8d7a7c",
                      }}
                      onClick={(e) => {
                        deleteTable(table.id);
                      }}
                    />
                  </Button>
                )}
              </Typography>
            </>
          ) : (
            ""
          )}
          <div style={{ width: "100%", overflow: "auto" }}>
            <ClassicTable>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  {renderCommonHeader(table?.defaultConfiguration)}
                  <TableRow>
                    {!isPrinting ? (
                      <>
                        <TableCell>
                          <Button
                            id="datasheet-editdatasheet_readingtablehead_expanded_uncertainty"
                            variant="outlined"
                            size="small"
                            style={{
                              fontSize: "12px",
                              width: "100px",
                            }}
                            component={Link}
                            to={`/viewAll2/${datasheetId}/${instrumentId}/1/${table.id}`}
                          >
                            Expanded Uncertainty
                          </Button>
                        </TableCell>
                        <TableCell
                          className="header-border"
                          align="center"
                          style={{ textAlign: "center" }}
                        >
                          <Typography noWrap style={{ fontSize: "12px" }}>
                            Select Master
                          </Typography>
                        </TableCell>
                      </>
                    ) : (
                      ""
                    )}
                    {/* render columns*/}
                    {Array.from({ length: MAX_COLS + 1 }, (_, i) => {
                      if (!deletedColumns[table.id]?.includes("c" + i)) {
                        if (table["c" + i]?.toLowerCase()?.match(/m+\d+/g)) {
                          range++;
                        }
                        if (table["c" + i] != null) {
                          return (
                            <TableCell
                              className="header-border"
                              align="center"
                              style={{ textAlign: "center" }}
                            >
                              <div>
                                <Typography
                                  noWrap
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {table["c" + i]?.includes(UNCERTAINTY_LINK)
                                    ? table["c" + i]?.split(
                                        UNCERTAINTY_LINK
                                      )?.[0]
                                    : table["c" + i]?.includes(
                                        EXTRA_COLUMN_LINK
                                      )
                                    ? table["c" + i]?.split(
                                        EXTRA_COLUMN_LINK
                                      )?.[0]
                                    : table["c" + i]}
                                </Typography>
                              </div>
                            </TableCell>
                          );
                        }
                      }
                    })}
                    {!isPrinting &&
                    unceraintyConfig[table.id]?.isUncertainty ? (
                      <TableCell>
                        <Typography
                          noWrap
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Uncertainty
                        </Typography>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    {!isPrinting ? (
                      <TableCell
                        className="header-border"
                        align="center"
                        style={{ textAlign: "center" }}
                      ></TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows(table, tableIndex, staticReadingRows, range)}
                </TableBody>
              </Table>
            </ClassicTable>
          </div>
          {!isPrinting ? (
            <>
              <div style={{ display: "flex", gap: "5px", margin: "10px 0px" }}>
                <Button
                  id="datasheet-editdatasheet_readingadd"
                  variant="outlined"
                  size="small"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    addStaticReadingRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Reading(s)
                </Button>
                <Button
                  id="datasheet-editdatasheet_readingunit"
                  variant="outlined"
                  size="small"
                  style={{ float: "right", marginRight: "7px" }}
                  onClick={(e) => {
                    addStaticUnitRow(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Unit
                </Button>
                <Button
                  id="datasheet-editdatasheet_readingheader"
                  variant="outlined"
                  size="small"
                  style={{ float: "right", marginRight: "7px" }}
                  onClick={(e) => {
                    addStaticRowHeader(tableIndex, table.id);
                  }}
                >
                  <AddIcon /> Row header
                </Button>
              </div>
              <Divider
                sx={{
                  backgroundColor: "black",
                  height: "2px",
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const handleAmendment = () => {
    handleClose();
    submitDatasheet();
  };

  const renderModal = () => {
    return (
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
          if (r !== "backdropClick") handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Amendment"
            value={reasonForAmendment}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setReasonForAmendment(e.target.value)}
          />
          <Tooltip title="Print Datasheet" placement="top-start">
            <Button
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              onClick={handleAmendment}
              disabled={reasonForAmendment === ""}
            >
              Next
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  const renderAmendmentHistoryModal = () => {
    return (
      <Modal
        open={modalHistoryOpen}
        onClose={(e, r) => {
          // console.log("onClose : ", e, r)
          // if (r !== "backdropClick")
          handleHistoryClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={historyModalStyle}>
          <div style={{ maxHeight: "100%", height: "300px" }}>
            <h4 style={{ marginBottom: "10px", height: "30px" }}>
              Amendment History
            </h4>
            <div style={{ overflow: "auto", height: "235px" }}>
              {amendmnetHistory ? (
                <table style={{ margin: "auto" }}>
                  <tr>
                    <th style={{ padding: "5px" }}>Sr. No.</th>
                    <th style={{ padding: "5px" }}>Amendment Remark</th>
                    <th style={{ padding: "5px" }}>Date</th>
                  </tr>
                  {amendmnetHistory.split("\n").map((e, i) => (
                    <tr>
                      <td style={{ padding: "5px" }}>{i + 1}</td>
                      <td style={{ padding: "5px" }}>{e.split(",")[0]}</td>
                      <td style={{ padding: "5px" }}>{e.split(",")[1]}</td>
                    </tr>
                  ))}
                </table>
              ) : (
                "No Amendment History Found!"
              )}
            </div>
          </div>
        </Box>
      </Modal>
    );
  };

  const renderGaugeForm = () => {
    if (instrument?.isGauge) {
      return (
        <>
          <div>
            <Typography variant="h6" component="div">
              Prepare gauge data
            </Typography>
            <div>
              <TextField
                id="outlined-basic"
                label="Size"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, size: Number(e.target.value) });
                }}
              />
              <TextField
                id="outlined-basic"
                label="Higher Tolerance"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, ht: Number(e.target.value) });
                }}
              />
              <TextField
                id="outlined-basic"
                label="Lower Tolerance"
                size="small"
                variant="outlined"
                type="number"
                onChange={(e) => {
                  setGaugeData({ ...gaugeData, lt: Number(e.target.value) });
                }}
              />
              <Button
                variant="contained"
                size="small"
                className="m-1"
                sx={{ m: 0 }}
                onClick={() => {
                  prepareGaugeTable();
                }}
              >
                Prepare
              </Button>
            </div>
            <div>
              {gaugeData.isPrepared != 0 && (
                <div className="m-2">
                  <Button className="m-1 p-1" variant="outlined">
                    G : {gaugeData.g ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    K: {gaugeData.k ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    T: {gaugeData.t?.toFixed(4) ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Y: {gaugeData.y ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Z: {gaugeData.z ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    H/2: {gaugeData.h2 ?? "-"}
                  </Button>
                  <Button className="m-1 p-1" variant="outlined">
                    Aplha: {gaugeData.alpha ?? "-"}
                  </Button>
                </div>
              )}
            </div>
          </div>
          <hr />
        </>
      );
    }
  };

  const renderCmcTable = () => {
    if (cmcReadingRows.length < 1) return;

    let readingColumns = [
      {
        field: "srNo",
        headerName: "Sr. No.",
      },
      {
        field: "fromRange",
        headerName: "From range",
        editable: true,
      },
      {
        field: "toRange",
        headerName: "To range",
        editable: true,
      },
      {
        field: "lowerCMC",
        headerName: "Lower CMC",
      },
      {
        field: "higherCMC",
        headerName: "Higher CMC",
      },
    ];
    return (
      <div id="datasheet-editdatasheet_cms">
        <h4 style={{ "margin-bottom": "0px" }}>CMC</h4>
        <div style={{ width: "100%", overflow: "auto" }}>
          <ClassicTable>
            <Table sx={{ minWidth: 660 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {readingColumns.map((column) => (
                    <TableCell key={column.field}>
                      {" "}
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cmcReadingRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {row.map((cell, cellIndex) => {
                      if (cellIndex > 0) {
                        return (
                          <TableCell>{cell?.replaceAll("#", " ")}</TableCell>
                        );
                      }
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>

          <br />
        </div>
      </div>
    );
  };

  const setSelectedStandardMasters = (selectedRangeIds) => {
    let selectedStandardIds = allStandards.map((range) => {
      let status = false;
      selectedRangeIds
        ?.filter((item) => item)
        ?.forEach((rangeIds) => {
          rangeIds = rangeIds?.split(",").map((e) => e.split(":")[1]);
          if (rangeIds?.includes(String(range.rangeId)) === true) status = true;
        });
      if (status === true) return String(range.id);
      else return null;
    });
    if (selectedStandardIds.length > 0) {
      setSelectedStandards(selectedStandardIds);
    } else setSelectedStandards([]);
  };

  const renderForm = () => {
    return (
      <div>
        <Grid container spacing={2} sx={{ my: 2 }} id="datasheet-editdatasheet_firstdisabled">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Document Number"
              size="small"
              value={documentNumber}
              fullWidth
              variant="outlined"
              onChange={(e) => setDocumentNumber(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Issue Number"
              size="small"
              value={issueNo}
              fullWidth
              variant="outlined"
              onChange={(e) => setIssueNo(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Issue Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={issueDate ? new Date(issueDate) : ""}
                onChange={(e) => setIssueDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                slotProps={{ textField: { size: "small" } }}
                disabled
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Amendment Number"
              size="small"
              value={amendmentNo}
              fullWidth
              variant="outlined"
              onChange={(e) => setAmendmentNo(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Amendment Date"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={amendmentDate ? new Date(amendmentDate) : ""}
                onChange={(e) => setEntryDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                disabled
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Customer Name"
              size="small"
              value={clientId}
              fullWidth
              variant="outlined"
              onChange={(e) => setClientId(e.target.value)}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Service Request Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={SRFNumber}
              onChange={(e) => setSRFNumber(e.target.value)}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Job Number"
              className="textfield"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={jobNumber}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="DC Number"
              InputLabelProps={{ shrink: true }}
              value={dcNo}
              onChange={(e) => setDCNo(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Certificate Number"
              InputLabelProps={{ shrink: true }}
              value={certificateNumber}
              onChange={(e) => setCertifucateNumber(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="ULR Number"
              InputLabelProps={{ shrink: true }}
              value={ULRNumber}
              onChange={(e) => setULRNumber(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Entry Data"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={entryDate ? new Date(entryDate) : ""}
                onChange={(e) => setEntryDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                disabled
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={3} id="datasheet-editdatasheet_dateofissue">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker                 
                label="Date Of Issue"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={dateOfIssue ? new Date(dateOfIssue) : ""}
                onChange={(e) => setDateOfIssue(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
                // disabled={!(userType == 1 || userType == 4)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3} id="datasheet-editdatasheet_datepo">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="PO Date"
                inputFormat="MM/dd/yyyy"
                value={poDate ? new Date(poDate) : ""}
                onChange={(e) => setPoDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3}  id="datasheet-editdatasheet_dateofcallibration">
            {userType == 1 ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker                 
                  label="Date of calibration"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={calibrationDate ? new Date(calibrationDate) : ""}
                  onChange={async (e) => {
                    let dateTime = await getDateTimeFromServer();
                    dateTime.setUTCFullYear(e.getUTCFullYear());
                    dateTime.setUTCMonth(e.getUTCMonth());
                    dateTime.setUTCDate(e.getUTCDate());
                    setCalibrationDate(dateTime);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date of calibration"
                  inputFormat="dd/MM/yyyy"
                  format="dd/MM/yyyy"
                  value={calibrationDate ? new Date(calibrationDate) : ""}
                  onChange={async (e) => {
                    let dateTime = await getDateTimeFromServer();
                    dateTime.setUTCFullYear(e.getUTCFullYear());
                    dateTime.setUTCMonth(e.getUTCMonth());
                    dateTime.setUTCDate(e.getUTCDate());
                    setCalibrationDate(dateTime);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}
                />
              </LocalizationProvider>
            )}
          </Grid>
          <Grid item xs={3}  id="datasheet-editdatasheet_dateofnextduedate">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker               
                label="Next due date"
                value={
                  nextDueDate ? new Date(nextDueDate) : calculateNextDueDate()
                }
                onChange={(e) => setNextDueDate(e)}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3} id="datasheet-editdatasheet_dateofreceipt">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date Of Receipt"
                value={receiptDate ? new Date(receiptDate) : ""}
                onChange={(e) => setReceiptDate(e)}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3} id="datasheet-editdatasheet_dateofdc">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date of DC"
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                value={dcDate ? new Date(dcDate) : ""}
                onChange={(e) => setDCDate(e)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2} sx={{ my: 2 }} id="datasheet-editdatasheet_seconddisabled">
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Instrument Name"
              size="small"
              value={instrumentName}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Requested Name"
              size="small"
              value={requestedname}
              fullWidth
              variant="outlined"
              onChange={(e) => setRequestedname(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Calibration Procedure No."
              size="small"
              value={calibrationProcedureNo}
              onChange={(e) => setCalibrationProcedureNo(e.target.value)}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Referenecer Standards"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={referenceStandards}
              onChange={(e) => setreferenceStandards(e.target.value)}
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Make"
              value={make}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setMake(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Model"
              value={models}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setModel(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Con Of Duc"
              value={ConOfDuc}
              className="textfield"
              onChange={(e) => setConOfDuc(e.target.value)}
              size="small"
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Range"
              value={ranges
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => setRanges(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="LC"
              value={lc
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              onChange={(e) => setLC(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Serial Number"
              value={serialNo}
              onChange={(e) => setSerialNo(e.target.value)}
              className="textfield"
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="DUC ID"
              value={DUCID}
              onChange={(e) => setDUCID(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Location"
              value={location}
              className="textfield"
              onChange={(e) => setLocation(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Accuracy"
              value={accuracy
                ?.replaceAll("||", ", ")
                ?.replaceAll("|", " to ")
                ?.replaceAll("#", "")}
              className="textfield"
              onChange={(e) => setAccuracy(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Cal Point"
              value={calPoint}
              className="textfield"
              onChange={(e) => setCalPoint(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Cal Method"
              value={calMethod}
              className="textfield"
              onChange={(e) => setCalMethod(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Location Of Instrument"
              value={locationOfInstrument}
              className="textfield"
              onChange={(e) => setLocationOfInstrument(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          {extraColumns.map((col, id) => (
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label={col[0]}
                value={col[1]}
                onChange={(e) => {
                  let tmp = [...extraColumns];
                  tmp[id][1] = e.target.value;
                  setExtraColumns([...tmp]);
                }}
                size="small"
                fullWidth
                variant="outlined"
                disabled
              />
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={2}>
            <TextField
              id="datasheet-editdatasheet_starttemprature"
              label="Start temprature"
              value={startTemp}
              onChange={(e) => setStartTemp(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="datasheet-editdatasheet_endtemprature"
              label="End temprature"
              value={endTemp}
              onChange={(e) => setEndTemp(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="datasheet-editdatasheet_starthHumidity"
              label="Start Humidity"
              value={startHumidity}
              onChange={(e) => setStartHumidity(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              id="datasheet-editdatasheet_endhHumidity"
              label="End Humidity"
              value={endHumidity}
              onChange={(e) => setEndHumidity(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="datasheet-editdatasheet_atmospheric"
              label="Atmospheric Pressure"
              value={atmosphericPressure}
              onChange={(e) => setAtmosphericPressure(e.target.value)}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="datasheet-editdatasheet_discipline"
              label="Discipline"
              disabled
              value={disciplineName}
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div>
          <Typography variant="h6" component="div" sx={{ mb: 2 }}>
            Details of Calibration Standard Used
          </Typography>
          <ClassicTable id="datasheet-editdatasheet_details_of_calibration">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Name of Standard</TableCell>
                  <TableCell>Range</TableCell>
                  <TableCell>Accuracy</TableCell>
                  <TableCell>Least Count</TableCell>
                  <TableCell>Make/Model</TableCell>
                  <TableCell>Certificate No.</TableCell>
                  <TableCell>Tracability</TableCell>
                  <TableCell>Cal.dt</TableCell>
                  <TableCell>Due Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSelectedMasters()?.map((row, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>

                    <TableCell
                      style={{
                        color: `${
                          moment().isAfter(row.validUpto) ? "red" : ""
                        }`,
                      }}
                    >
                      {row.standardName}
                    </TableCell>
                    <TableCell>{row.masterrange}</TableCell>
                    <TableCell>{row.masteraccuracy}</TableCell>
                    <TableCell>{row.masterleastcount}</TableCell>
                    <TableCell>{row.make}</TableCell>
                    <TableCell>{row.certificateNumber}</TableCell>
                    <TableCell>{row.traceability}</TableCell>
                    <TableCell>
                      {moment(row.calDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(row.validUpto).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ClassicTable>
        </div>
        <br />
        <hr />
        {renderGaugeForm()}
        <div>
          <Typography variant="h6" component="div">
            Observed Readings
          </Typography>
        </div>
        <hr style={{ border: "1px solid black" }} />
        <Button
          size="sm"
          onClick={(e) => {
            fetchOtherStaticReadingRows();
          }}
        >
          Autoload
        </Button>
        <div className="ds-tables">{renderStaticTables()}</div>
        <hr style={{ border: "1px solid black" }} />
        {staticReadingRows.length > 0 && complianceStatus === 0 && (
          <h5 style={{ color: "red" }}>Compliance status: failed</h5>
        )}
        {staticReadingRows.length > 0 && complianceStatus === 1 && (
          <h5 style={{ color: "green" }}>Compliance status: pass</h5>
        )}
        <hr />
        {renderCmcTable()}
        <div style={{ marginBottom: "50px" }} id="datasheet-editdatasheet_remarks">
          <div style={{ float: "left" }}>
            <h5>Remarks :</h5>
          </div>
          <br />
          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}
            placeholder="Enter remark here"
            style={{ width: 900, float: "left" }}
            value={configuration?.remark || defaultReamrk}
            onChange={(e) => {
              setConfiguration({
                ...configuration,
                remark: e.target.value,
              });
            }}
          />
        </div>
        {/* <div>
          <Grid container spacing={2}>
            <Grid item>
              <b>Last Modified : </b>
            </Grid>
            <Grid item>
              {showLastModified ? moment(lastModified).format("mm/DD/yyyy") : ""}
            </Grid>
          </Grid>
        </div> */}
        {calTypeSetting && (
          <div>
            <Grid container spacing={2}>
              <Grid item style={{ marginTop: "5px" }}>
                <h5>Calibration Type:</h5>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  classes={{
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                  control={
                    <Checkbox
                      checked={calibrationReason == 1}
                      onChange={(e) => {
                        setCalibrationReason(1);
                      }}
                    />
                  }
                  label="New Installation"
                />
              </Grid>

              <Grid item xs={2}>
                <FormControlLabel
                  classes={{
                    justifyContent: "space-between",
                    alignItems: "left",
                  }}
                  control={
                    <Checkbox
                      checked={calibrationReason == 2}
                      onChange={(e) => {
                        setCalibrationReason(2);
                      }}
                    />
                  }
                  label="Periodic"
                />
              </Grid>
            </Grid>
          </div>
        )}

        <hr
          style={{ float: "left", border: "1px solid black", width: "100%" }}
        />

        <Grid container spacing={2} sx={{ my: 3 }}>
          <Grid item xs={4}>
            <TextField
              id="datasheet-editdatasheet_calibratedby"
              label="Calibrated by"
              size="small"
              fullWidth
              variant="outlined"
              value={calibratedby}
              disabled
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label="Checked / Approved by"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={4}>
            <TextField
              id="datasheet-editdatasheet_issueno"
              label="Issue Number"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="datasheet-editdatasheet_revno"
              label="Rev. Number"
              size="small"
              fullWidth
              variant="outlined"
              disabled
              value={revisionNumber == -1 ? 0 : revisionNumber}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="datasheet-editdatasheet_formno"
              label="Form Number"
              size="small"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={3} id="datasheet-editdatasheet_reviosiondate">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Revision Date"
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </div>
    );
  };

  const checkMasters = () => {
    if (
      staticReadingRows
        .map((row) => {
          if (
            !String(row[2])?.includes("_unit_") &&
            !String(row[2])?.includes("_rh_")
          ) {
            let masters = row[2 + getCustomColumnCount(row[0], "static") + 1];
            masters =
              masters !== undefined && masters !== null && masters !== ""
                ? `${masters}`
                    .split(",")
                    .map((e) => e.trim())
                    .filter((e) => e.length > 0)
                : [];
            if (masters.length === 0) return true;
          }
          return false;
        })
        .filter((e) => e).length
    ) {
      if (
        window.confirm(
          "Masters is/are not added for rows? Do you want to save?"
        )
      )
        return true;
      return false;
    }
    return true;
  };

  const getSelectedMasters = () => {
    let base = 2;
    let selected_masters = {};
    staticReadingRows.map((r) => {
      let customColumnCount = getCustomColumnCount(r[0], "static");
      (r[base + customColumnCount + 1]
        ? r[base + customColumnCount + 1]
            ?.split(",")
            ?.filter((e) => e)
            .map((sm) => sm.split(":")[0])
        : []
      ).map((m) => (selected_masters[m] = true));
    });
    selected_masters = Object.keys(selected_masters).map((e) => Number(e));
    selected_masters = standardMasterArray.filter((e) =>
      selected_masters.includes(e.id)
    );
    return selected_masters;
  };

  useEffect(() => {
    if (isPrint === "print") {
      setisPrinting(true);
    }
  }, [isPrint]);

  if (!dataLoaded)
    return <div style={{ textAlign: "center" }}>...Loading data</div>;

  return (
    <>
     <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          editdataSheetGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      {!isPrinting && (
        <Paper sx={{ mx: 5, mt: 2, p: 2 }} ref={printComponentRef}>
          <Typography variant="h5" component="div" sx={{ mb: 2 }}>
            Datasheet of {instrumentName}
          </Typography>
          <hr />
          {renderForm()}
          <Toolbar
            style={{
              padding: "0px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {(`${userType}` === "1" ||
              `${userType}` === "4" ||
              props.rolesInfo?.currentRole?.id == 1) && (
              <Button
              id="datasheet-editdatasheet_savedatasheet"
                variant="contained"
                size="small"
                sx={{ ml: 3, p: "5px", minWidth: "130px", py: 1 }}
                onClick={async () => {
                  if (
                    !calibrationDate ||
                    !startHumidity ||
                    !startTemp ||
                    Object.keys(outOffRangeTracker).length
                    // TODO: uncomment this when precision validator when issue is fixed
                    // || Object.keys(precNotEqTracker).length
                  ) {
                    let alerts = [];
                    if (!calibrationDate)
                      alerts.push("Please add the calibration date!");
                    if (!startHumidity)
                      alerts.push("Please add the startHumidity!");
                    if (!startTemp) alerts.push("Please add the startTemp!");
                    if (Object.keys(outOffRangeTracker).length)
                      alerts.push(
                        "Readings can't be greater than 'DUC Higher Range' and 'Master To Range' !"
                      );
                    // TODO: uncomment this when precision validator issue is fixed
                    // if (Object.keys(precNotEqTracker).length)
                    //   alerts.push(
                    //     "Please make sure all reading should have correct precisions!"
                    //   );
                    alert(alerts.join("\n"));
                  } else {
                    if (checkMasters()) {
                      // complianceStatus !== 1 && setComplianceStatus(1);
                      await processDatasheet();
                      if (shouldShowAmendment && revisionNumber > -1)
                        handleOpen();
                      else submitDatasheet();
                    }
                  }
                }}
                // disabled={expiredStandardsList.length > 0}
              >
                Save
              </Button>
            )}

            {shouldShowAmendment && (
              <Button
                id="datasheet-editdatasheet_amendment"
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  handleHistoryOpen();
                }}
              >
                Amendment History
              </Button>
            )}
          </Toolbar>
          {renderModal()}
          {renderAmendmentHistoryModal()}
        </Paper>
      )}
      {isPrinting && (
        <ViewDatasheet
          instrumentName={instrumentName}
          printComponentRef={printComponentRef}
          documentNumber={documentNumber}
          issueNo={issueNo}
          issueDate={issueDate}
          amendmentNo={amendmentNo}
          amendmentDate={amendmentDate}
          SRFNumber={SRFNumber}
          entryDate={entryDate}
          certificateNumber={certificateNumber}
          calibrationDate={calibrationDate}
          nextDueDate={nextDueDate}
          classes={classes}
          requestedname={requestedname}
          make={make}
          DUCID={DUCID}
          models={models}
          serialNo={serialNo}
          ranges={ranges}
          accuracy={accuracy}
          lc={lc}
          locationOfInstrument={locationOfInstrument}
          location={location}
          ConOfDuc={ConOfDuc}
          calibrationProcedureNo={calibrationProcedureNo}
          referenceStandards={referenceStandards}
          extraColumns={extraColumns}
          startTemp={startTemp}
          startHumidity={startHumidity}
          getSelectedMasters={getSelectedMasters}
          disciplineName={disciplineName}
          renderStaticTables={renderStaticTables}
          configuration={configuration}
          defaultReamrk={defaultReamrk}
          datasheetId={datasheetId}
          calibratedby={calibratedby}
          approvedby={approvedby}
          complianceStatus={complianceStatus}
          compliance={compliance}
        />
      )}
    </>
  );
}
