import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
  Toolbar,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { editProductGuide } from "./guide";

const axios = require("axios");
export default function EditProduct(props) {
  const editproductGuides = editProductGuide();
  const [rate, setRate] = React.useState("");
  const [instrumentId, setInstrumentId] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [clientList, setClientList] = React.useState([]);
  const [clientId, setClientId] = React.useState(0);

  const params = useParams();
  const getProductIdList = () => {
    let url = BASE_URL;
    axios
      .get(url + `customProducts/${params.id}`)
      .then((res) => {
        setInstrumentId(res.data[0].instrumentId);
        setRate(res.data[0].rate);
        setClientId(res.data[0]?.clientId);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments?_where=(status,eq,1)")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            // contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductIdList();
    getInstrumentsList();
    getCustomer();
  }, []);

  const onSubmit = (id) => {
    var jsonData = {
      // productName: finalProductName,
      rate: rate,
    };
    let url = BASE_URL;

    var refresh = () => {
      window.location.reload(false);
    };
    axios
      .patch(url + `customProducts/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("updated Products!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Product
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          editproductGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 
      <Grid container spacing={2}>
        {clientId !== 0 && (
          <Grid item xs={3}>
            <TextField
              id="product_edit_compnay"
              label="Company Name"
              size="small"
              disabled
              value={
                (clientList.find((e) => e.id === clientId) || {}).label || ""
              }
              fullWidth
              variant="outlined"
              onChange={(event, value) => {
                if (value === null) {
                  setClientId(0);
                } else {
                  setClientId(value.id);
                }
              }}
            />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField
            id="product_edit_instruname"
            label="Product Name *"
            size="small"
            disabled
            value={
              instrumentArray.find((lab) => lab.id === instrumentId)
                ?.instrumentName || ""
            }
            fullWidth
            variant="outlined"
            onChange={(e) => setInstrumentId(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={4}>
            <TextField
              id="product_edit_rate"
              label="Rate *"
              size="small"
              value={rate}
              fullWidth
              variant="outlined"
              onChange={(e) => setRate(e.target.value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Toolbar style={{ padding: "0px" }}>
        <Button
        id="product_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          update
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
