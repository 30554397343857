import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import { TextField, Autocomplete, Modal, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { showComponent } from "../helper/helpers";
import TextEditor from "./textEditor";
import ExcelDownload from "../../utils/components/excelDownload";
import { quotationListGuide } from "./guide";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 40 },
  { id: "companyName", label: "Company Name", minWidth: 40 },
  { id: "clientId", label: "Contact Details", minWidth: 60 },
  { id: "quotationNumber", label: "Quotation Number", minWidth: 50 },
  {
    id: "date",
    label: "Quotation Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "totalAmount",
    label: "Total Amount",
    align: "left",
    minWidth: 40,
  },
  {
    id: "createdBy",
    label: "Created By",
    align: "left",
    minWidth: 40,
  },
];

function createData(
  id,
  companyName,
  referenceNumber,
  date,
  totalAmount,
  quotationNumber,
  clientId,
  subject,
  equipmentCollection,
  equipmentDelivery,
  conveyanceCharges,
  courierCharges,
  enquiryDate,
  enquiryId,
  createdBy
) {
  return {
    id,
    companyName,
    referenceNumber,
    date,
    totalAmount,
    quotationNumber,
    clientId,
    subject,
    equipmentCollection,
    equipmentDelivery,
    conveyanceCharges,
    courierCharges,
    enquiryDate,
    enquiryId,
    createdBy,
  };
}
const  notNeededColumn = ["id", "status","referenceNumber","clientId","subject","equipmentCollection","conveyanceCharges","enquiryDate","enquiryId","createdBy","courierCharges","equipmentDelivery"]

export default function QuotationList() {
  const quotationListGuides = quotationListGuide();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quotationList, setQuotationList] = React.useState([]);
  const [quotationData, setQuatationData] = React.useState();
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const userName = localStorage.getItem("userName");
  const [selectedColumn, SetSelectedColumn] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [clients, setClients] = React.useState([]);
  const [formData, setFormData] = React.useState({
    userEmail: "",
    clientName: userName ? userName.toUpperCase() : "",
    subject: "",
  });
  const [editorValue, setEditorValue] = React.useState("");

  const handleEditorChange = (value) => {
    setEditorValue(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    var jsonData = {
      status: -1,
    };
    let url = BASE_URL;
    axios
      .patch(url + `quotation/${id}`, jsonData)
      .then((res) => {
        toast("quotation was deleted!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  var refresh = () => {
    window.location.reload(false);
    // <Link to="/master/master/standard">Home</Link>
  };

  const getData = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Company Name": "companyName",
        "Reference Number": "referenceNumber",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` qt.date between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    if (whereConditions === "") {
      whereConditions = " where ";
    } else {
      whereConditions += " and ";
    }

    whereConditions += " qt.status = 1 ";

    let data = {
      query: `select qt.createdBy,qt.quotationNumber,qt.date,qt.totalAmount, qt.referenceNumber,qt.enquiryId,qt.id,qt.enquiryDate,qt.conveyanceCharges,qt.courierCharges,qt.equipmentCollection,qt.equipmentDelivery,qt.subject,client.companyName,qt.clientId from quotation as qt left join clients as client on qt.clientId = client.id ${whereConditions} order by qt.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(qt.id) as no_of_rows from quotation as qt left join clients as client on qt.clientId = client.id ${whereConditions} order by qt.id DESC ${pagination_settings}`;
    return data;
  };

  function getClientList() {
    const data = getData();

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        const uniqueIds = [];
        const unique = res.data.filter((element) => {
          const isDuplicate = uniqueIds.includes(element.quotationNumber);
          if (!isDuplicate) {
            uniqueIds.push(element.quotationNumber);
            return true;
          }
          return false;
        });
        // setQuotationList(unique);
        setQuotationList([...unique]);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  // api calls
  function getTotalRows() {
    const data = getData("count");
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

    // api calls
  function getUsers() {
    const data = {
    "query":"SELECT * FROM users"
    
    };
    axios
      .post(BASE_URL + `dynamic`, data)
      .then(async (res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }


    // api calls
    function getClients() {
      const data = {
      "query":"SELECT * FROM clients"
      
      };
      axios
        .post(BASE_URL + `dynamic`, data)
        .then(async (res) => {
          setClients(res.data);
        })
        .catch((err) => {
          console.log("clients data fetching error: ", err);
        });
    }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };
  const search = () => {
    getClientList();
  };

  useEffect(() => {
    getTotalRows();
    getUsers();
    getClients();
  }, []);

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  const initiateQuotationList = () => {
    var rowDaata = [];
    for (let i = 0; i < quotationList.length; i++) {
      rowDaata.push(
        createData(
          quotationList[i].id,
          quotationList[i].companyName,
          quotationList[i].referenceNumber,
          quotationList[i].date
            ? moment(new Date(quotationList[i].date)).format("DD-MM-YYYY")
            : "",
          quotationList[i].totalAmount,
          quotationList[i].quotationNumber,
          quotationList[i].clientId,
          quotationList[i].subject,
          quotationList[i].equipmentCollection,
          quotationList[i].equipmentDelivery,
          quotationList[i].conveyanceCharges,
          quotationList[i].courierCharges,
          quotationList[i].enquiryDate,
          quotationList[i].enquiryId,
          quotationList[i].createdBy
        )
      );
    }
    setQuatationData(rowDaata);
  };
  useEffect(() => {
    initiateQuotationList();
  }, [quotationList]);
  /////////////////////////////////// Mail ///////////////////////////////////////

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let payload = {
        userEmail: formData.userEmail,
        clientName: formData.clientName,
        subject: formData.subject,
        description: editorValue,
      };

      axios
        .post("http://localhost:5000/api/email", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("Email sent successfully !");
        })
        .catch((error) => {
          toast.error("Something Went Wrong Failed to send email !");
        });

      handleClose();
    } catch (error) {
      console.error("Error sending email", error);
    }
  };

  const renderEmailModal = () => {
    let DescriptionValue = "";
    if (selectedColumn.enquiryId && selectedColumn.enquiryDate) {
      DescriptionValue = `<p><br></p><p><strong>Greetings from BFI Private Limited</strong></p><p><br></p><p>Regarding your inquiry <strong>(No-${
        selectedColumn.enquiryId
      }  dated ${moment(selectedColumn.enquiryDate).format(
        "YYYY-MM-DD"
      )} )</strong>, please find the enclosed quotation <strong>(Quotation No: ${
        selectedColumn.quotationNumber
      } Dated ${
        selectedColumn.date
      })</strong> for Calibration Charges.</p><p><br></p><p>Subject&nbsp;:- ${
        selectedColumn.subject
      },&nbsp;</p><p>Equipment Collection:- ${
        selectedColumn.equipmentCollection
      },</p><p>Equipment Delivery :- ${
        selectedColumn.equipmentDelivery
      },</p><p>Total Quantity :- 12,</p><p>Grand Total :- ${
        selectedColumn.totalAmount
      },</p><p>Onsite Charges :- ${
        selectedColumn.conveyanceCharges
      },</p><p>Courier Charges:- ${
        selectedColumn.courierCharges
      },</p><p><br></p><p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p><p><br></p><p>Kindly confirm the receipt of the mail While sending the Instrument send all accessories(cables, power chord etc) along with the instruments for calibration</p><p><br></p><p>Thanks &amp; Regards,</p><p><br></p><p>Er. Pravin Kashid</p><p>(Founder)</p><p>[LAB City]</p><p><br></p><p>&nbsp;Regd. Office: 129, Vindya Complex, Sector-11,</p><p>CBD Belapur Navi Mumbai, Maharashtra.</p><p>Contact: 9769847865</p><p>Website: <a href=\"https://bfiinfotech.co.in/\" rel=\"noopener noreferrer\" target=\"_blank\">bfiinfotech.co.in</a></p><p>&nbsp;[LAB City Is The Product Of BFI]</p>`;
    } else {
      DescriptionValue = `<p><br></p><p><strong>Greetings from BFI Private Limited</strong></p><p><br></p> please find the enclosed quotation <strong>(Quotation No: ${selectedColumn.quotationNumber} Dated ${selectedColumn.date})</strong> for Calibration Charges.</p><p><br></p><p>Subject&nbsp;:- ${selectedColumn.subject},&nbsp;</p><p>Equipment Collection:- ${selectedColumn.equipmentCollection},</p><p>Equipment Delivery :- ${selectedColumn.equipmentDelivery},</p><p>Total Quantity :- 12,</p><p>Grand Total :- ${selectedColumn.totalAmount},</p><p>Onsite Charges :- ${selectedColumn.conveyanceCharges},</p><p>Courier Charges:- ${selectedColumn.courierCharges},</p><p><br></p><p>Please feel free to contact us in case of any queries, Expecting your valuable order.</p><p><br></p><p>Kindly confirm the receipt of the mail While sending the Instrument send all accessories(cables, power chord etc) along with the instruments for calibration</p><p><br></p><p>Thanks &amp; Regards,</p><p><br></p><p>Er. Pravin Kashid</p><p>(Founder)</p><p>[LAB City]</p><p><br></p><p>&nbsp;Regd. Office: 129, Vindya Complex, Sector-11,</p><p>CBD Belapur Navi Mumbai, Maharashtra.</p><p>Contact: 9769847865</p><p>Website: <a href=\"https://bfiinfotech.co.in/\" rel=\"noopener noreferrer\" target=\"_blank\">bfiinfotech.co.in</a></p><p>&nbsp;[LAB City Is The Product Of BFI]</p>`;
    }
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 800,
            height: "100vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" mb={2}>
            Email Form
          </Typography>
          <TextField
            label="User Email"
            fullWidth
            name="userEmail"
            value={formData.userEmail}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Client Name"
            fullWidth
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            label="Subject"
            fullWidth
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            mb={6}
            sx={{ marginBottom: "20px" }}
          />

          <TextEditor
            value={editorValue || DescriptionValue}
            onChange={handleEditorChange}
          />
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Box>
      </Modal>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      {renderEmailModal()}
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Toolbar>
        <Button
        id="quotationlist_createbtn"
          variant="contained"
          size="small"
          component={Link}
          to="/quotation/createQuotation"
        >
          <b>CREATE quotation</b>
        </Button>
      </Toolbar>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          quotationListGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  
      <br />
      <div
        className="mb-2"
        style={{ display: "flex", float: "left", justifyContent: "flex-end" }}
      >
        <div style={{ marginLeft: "10px", marginRight: "10px" }} id="quotationlist_datefrom">
          <LocalizationProvider dateAdapter={AdapterDateFns} classNames="mr-2">
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="From"
              value={from ? new Date(from) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setFrom(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>

        <div style={{ marginRight: "10px" }} id="quotationlist_dateto">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="To"
              value={to ? new Date(to) : ""}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => setTo(newValue)}
              renderInput={(params) => <TextField {...params} size="small" />}
              style={{ marginRight: "20px" }}
            />
          </LocalizationProvider>
        </div>

        <Autocomplete
          sx={{
            m: 0,
            minWidth: 180,
            marginLeft: "10px",
          }}
          size="small"
          id="quotationlist_searchby"
          options={[
            { key: "companyName", label: "Company Name" },
            { key: "referenceNumber", label: "Reference Number" },
          ]}
          renderInput={(params) => <TextField {...params} label="Search By" />}
          onInputChange={(event, newInputValue) => {
            setSearchBy(newInputValue);
          }}
        />
        <TextField
          id="quotationlist_searchbytext"
          label={"enter " + searchBy}
          size="small"
          variant="outlined"
          onChange={(e) => setSearchKey(e.target.value)}
          sx={{marginLeft:"10px"}}
        />
        <Button
        id="quotationlist_searchbtn"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          style={{ marginLeft: "10px" }}
          onClick={() => {
            search();
          }}
        >
          <SearchIcon />
        </Button>
        <ExcelDownload finalData={quotationData}  notNeededColumn={notNeededColumn}/>
      </div>
      <br />

      <table
        stickyHeader
        aria-label="sticky table"
        size="small"
        id="quotationlist_table"
        width="100%"
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <b>{column.label}</b>
              </TableCell>
            ))}
            <TableCell>
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {quotationData &&
            quotationData.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={index} key={row.code}>
                  {columns.map((column) => {

                    let value;
                    if (column.id === "createdBy") {
                      let userObject=users.filter((e)=>e.id ===  row[column.id] )
                      value = row[column.id] ? userObject[0]?.userName : null;
                    }else if(column.id === "clientId"){
                      value = (() => {
                        if (row[column.id]) {
                          const filterClient= clients.filter((e)=>e.id==row[column.id])[0]?.contact
                          const parsedData = filterClient?JSON.parse(filterClient)?.[0]:{};
                          return (
                            <>
                             <h6 style={{ fontSize: "1em" }}><span style={{fontWeight:"bold"}}>Name:</span> &nbsp;{parsedData?.contactPersonName}</h6>
                              <h6 style={{ fontSize: "1em" }}><span style={{fontWeight:"bold"}}>Email:</span> &nbsp;{parsedData?.emailId}</h6>
                              <h5 style={{ fontSize: "1em" }}><span style={{fontWeight:"bold"}}>Contact:</span> &nbsp;{parsedData?.contact}</h5>
                           
                            </>
                          );
                        }
                        return null;
                      })();
                    }else {
                      value = row[column.id];
                    }

                    if (column.id == "id") {
                      value = page * rowsPerPage + (index + 1);
                    }
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number"
                          ? column.format(value)
                          : value}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <ButtonGroup size="small" aria-label="small button group">
                      <Tooltip title="Edit Quotation" placement="top-start">
                        <Button
                        id="quotationlist_edit"
                          component={Link}
                          to={`/quotation/editQuotation/${row.clientId}/${(
                            row.quotationNumber || ""
                          ).replaceAll("/", "_")}`}
                        >
                          <PreviewIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Create FollowUp" placement="top-start">
                        <Button
                        id="quotationlist_followup"
                          component={Link}
                          to={`/quotation/quotationFollowUp/${row.id}`}
                        >
                          <BookmarkIcon />
                        </Button>
                      </Tooltip>
                      {showComponent("You really want to create invoice") && (
                        <Tooltip title="Create Invoice" placement="top-start">
                          <Button
                          id="quotationlist_createInvoice"
                            component={Link}
                            to={`/Invoice/createInvoice/${row.clientId}/${(
                              row.quotationNumber || ""
                            ).replaceAll("/", "_")}`}
                            onClick={(e) => {
                              if (
                                !window.confirm(
                                  "You really want to create invoice?"
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                          >
                            <ReceiptLongIcon />
                          </Button>
                        </Tooltip>
                      )}

                      <Tooltip title="Send Email" placement="top-start">
                        <Button
                        id="quotationlist_mail"
                          onClick={() => {
                            SetSelectedColumn(row);
                            handleOpen();
                          }}
                        >
                          <EmailIcon />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Print Quotation" placement="top-start">
                      <Button
                      id="quotationlist_download"
                        component={Link}
                        to={`/quotation/viewQuotation/${row.clientId}/${(
                          row.quotationNumber || ""
                        ).replaceAll("/", "_")}`}
                              >
                        <PrintIcon />
                      </Button>
                      </Tooltip>

                      {showComponent("delete") && (
                        <Tooltip title="Delete Quotation" placement="top-start">
                          <Button
                          id="quotationlist_delete"
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Really want to delete quotation?"
                                )
                              ) {
                                submitSiteDelete(row.id);
                              }
                            }}
                          >
                            <DeleteIcon style={{ color: "#dc3545" }} />
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        component="div"
        colSpan={3}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        ActionsComponent={TablePaginationActions}
      />
      <ToastContainer />
    </TableContainer>
  );
}
