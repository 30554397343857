// functionalities Check

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Grid,
  Modal,
  Table,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import QRCode from "react-qr-code";
import { BASE_URL, DOMAIN } from "../../global";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiTableCell from "@material-ui/core/TableCell";
import { Autocomplete } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HelpIcon from "@mui/icons-material/Help";
import UnitechHeader from "../image/certificate_header.png";
import UnitechFooter from "../image/certificate_footer.png";

import "./certificate.css";
import "./certificateList.css";
import RenderStaticTables from "./renderTables";
import { useGetRoleInfo } from "../login/Login";
import { makeChunks } from "../helper/helpers";
import { notifiy } from "../notification/Notification";
import { async } from "q";
import { countOfCertificatesAnalytics } from "../../utils/analytics/certificateAnalytics";
import { updateSRFAnalytics } from "../../utils/analytics/srfAnalytics";
import { viewCertificateGuide } from "./guide";
import { fetchAddresses } from "../invoice/productDetails";

const TableCell = withStyles({
  root: {
    border: "none",
    padding: "0px",
    margin: "0px",
    lineHeight: "1.32",
    fontSize: "12px",
    paddingLeft: "5px",
  },
})(MuiTableCell);

const axios = require("axios");
const useStyles = makeStyles({
  table: {
    border: "1px solid black",
    padding: "1px",
  },
  infoCell: {
    maxHeight: "5px",
  },
  innerCell: {
    padding: "0px",
    maxWidth: "40%",
  },
  formatedCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
  },
  certificateCell: {
    fontSize: "0.82em",
    padding: "0px",
    margin: "0px",
    width: "20%",
  },
  sxPad: {
    padding: "5px",
  },
  addressCell: {
    width: "200px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};

export default function ViewCertificate(props) {
  const viewCertificateGuides = viewCertificateGuide()
  const printComponentRef = React.useRef();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { certificateId: paramCertificateId } = useParams();
  const certificateId = props.certificateIds || paramCertificateId;

  const [instrument, setInstrument] = React.useState(null);
  const [configuration, setConfiguration] = React.useState({});
  const [clientId, setClientId] = React.useState("");
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [standardMasterArray, setStandardMasterArray] = React.useState([]);
  const [instrumentName, setInstrumentName] = React.useState("");
  const [requestedName, setRequestedName] = React.useState("");
  const [make, setMake] = React.useState("");
  const [calibrationProcedureNo, setCalibrationProcedureNo] =
    React.useState("");
  const [referenceStandards, setReferenceStandards] = React.useState("");
  const [lc, setLC] = React.useState("");
  const [dcNumber, setDcNumber] = React.useState("");
  const [ranges, setRanges] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [newAddressArray, setNewAddressArray]=React.useState([])
  const [calibrationDate, setCalibrationDate] = React.useState(null);
  const [approvedDate, setApprovedDate] = React.useState(null);
  const [reviewedDate, setReviewedDate] = React.useState(null);
  const [signedDate, setSignedDate] = React.useState(null);
  const [witnessDate, setWitnessDate] = React.useState(null);
  const [dcDate, setDcDate] = React.useState(null);
  const [nextDueDate, setNextDueDate] = React.useState(null);
  const [receiptDate, setReceiptDate] = React.useState(null);
  const [poDate, setPoDate] = React.useState(null);
  const [dateOfIssue, setDateOfIssue] = React.useState("");
  const [calibrationFrequency, setCalibrationFrequency] = React.useState(null);

  const [calProcRefNo, setCalProcRefNo] = React.useState("");
  const [mfgNo, setMfgNo] = React.useState("");
  const [rangeDbData, setRangeData] = React.useState([]);
  // const [allRangeData, setRangeDataArray] = React.useState([]);
  const [specificValuesFromTable, setSpecificValuesFromTable] = React.useState(
    []
  );
  const [status, setStatus] = React.useState(-1);
  const [identificationNo, setIdentificationNo] = React.useState("");
  const [DUCID, setDUCID] = React.useState([]);
  const [calType, setCalType] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [accuracy, setAccuracy] = React.useState("");
  const [filterRangeData, setFilterRangeData] = React.useState([]);
  const [serialNo, setSerialNo] = React.useState("");
  const [selectedStandards, setSelectedStandards] = React.useState("");
  const [ulrNumber, setUlrNumber] = React.useState(null);
  const [certificateNumber, setCertificateNumber] = React.useState("");
  const [serviceReqNumber, setServiceReqNumber] = React.useState("");
  const [jobNumber, setJobNumber] = React.useState("");
  const [workOrderNumber, setWorkOrderNumber] = React.useState("");
  const [poNo, setPoNo] = React.useState(null);
  const [startTemp, setStartTemp] = React.useState("");
  const [endTemp, setEndTemp] = React.useState("");
  const [tempDiff, setTempDiff] = React.useState("");
  const [startHumidity, setStartHumidity] = React.useState("");
  const [endHumidity, setEndHumidity] = React.useState("");
  const [humDiff, setHumDiff] = React.useState("");
  const [models, setModel] = React.useState("");
  const [ConOfDuc, setConOfDuc] = React.useState("");
  const [calPoint, setCalPoint] = React.useState("");
  const [calMethod, setCalMethod] = React.useState("");
  const [locationOfInstrument, setLocationOfInstrument] = React.useState("");
  const [srnNo, setSrnNo] = React.useState("");
  const [atmosphericPressure, setAtmosphericPressure] = React.useState("");
  const [disciplineName, setDisciplineName] = React.useState("");
  const [defaultReamrk, setDefaultRemark] = React.useState(null);
  const userType = localStorage.getItem("type");
  const [tableDisplayStatus, setTableDisplayStatus] = React.useState(1);
  const [settingList, setSettingList] = React.useState({});
  const [isPrinting, setisPrinting] = React.useState(!!props.certificateIds);
  const [id, setId] = React.useState(-1);
  const [calibratedby, setCalibratedby] = React.useState("");
  const [approvedby, setApprovedby] = React.useState("");
  const [rejectedBy, setRejectedBy] = React.useState("");
  const [reviewedBy, setReviewedBy] = React.useState("");
  const [signedBy, setSignedBy] = React.useState("");
  const [witnessedBy, setWitnessedBy] = React.useState("");
  const user = [localStorage.getItem("id"), localStorage.getItem("userName")];
  const [letterHead, setLetterHead] = React.useState(true);
  const [electronicSign, setElectronicSign] = React.useState(true);
  const [extraColumns, setExtraColumns] = React.useState([]);
  const [departmet, setDepartment] = React.useState("");
  const [input, setInput] = React.useState("");
  const [operatingrange, setOperatingrand] = React.useState("");
  const [revisionNumber, setRevisionNumber] = React.useState("");
  const [amendmnetHistory, setAmendmentHistory] = React.useState("");
  const [calibrationReason, setCalibrationReason] = React.useState(1);
  const [certificateDownloadCount, setCertificateDownloadCount] =
    React.useState({});

  const [complianceStatus, setComplianceStatus] = React.useState(null);
  const [compliance, setCompliance] = React.useState(false);
  const [lastModified, setLastModified] = React.useState("");
  const [showAmendment, setShowAmendment] = React.useState(false);
  const [instrumentId, setInstrumentId] = React.useState("");
  const [deletedTableIds, setDeletedTableIds] = React.useState("");
  const [rolesSettins, setRolesSettings] = React.useState("");
  const [calTypeSetting, setCalTypeSetting] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalOpen2, setModalOpen2] = React.useState(false);
  const { roles } = useGetRoleInfo(true);
  const roles_ids = roles.map((r) => Number(r.id));

  async function ServeDates() {
    try {
      const url = `${BASE_URL}dynamic`;
      const query = `SELECT now() as date`;
      const response = await axios.post(url, { query });
      // console.log('response',response.data[0]?.date);
      // setServerDate(response.data[0]?.date);
      return new Date(response.data[0]?.date || null);
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  const copyToClipboard = () => {
    // copy current url to click on copy url button
    const qrCodeURL = `${DOMAIN}/#/certificate/print/${certificateId}`;
    const input = document.createElement('input');
    input.value = qrCodeURL;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    // show message to copy url
    const tooltip = document.createElement('div');
    tooltip.textContent = 'URL copied to clipboard!';
    tooltip.style.position = 'fixed';
    tooltip.style.backgroundColor = '#333';
    tooltip.style.color = '#fff';
    tooltip.style.padding = '5px';
    tooltip.style.borderRadius = '5px';
    tooltip.style.zIndex = '9999';
    tooltip.style.top = '20%';
    tooltip.style.left = '50%';
    tooltip.style.transform = 'translate(-50%, -50%)';
    document.body.appendChild(tooltip);
    setTimeout(() => {
        document.body.removeChild(tooltip);
    }, 2000);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleClose2 = () => {
    setModalOpen2(false);
  };

  // api calls
  function fetchSettings() {
    axios.get(BASE_URL + `settings`).then((res) => {
      let remark = null,
        complance = false,
        calTypeSetting = false,
        settings = res.data,
        _rolesSettings = {};
      let _settingList = {};
      settings.forEach((setting) => {
        if (setting?.keyName?.toLowerCase() === "remarks") {
          remark = setting.value;
        } else if (
          setting.keyName === "Compliance Status" &&
          setting.value.toLowerCase() === "true"
        ) {
          complance = true;
        } else if (
          setting.keyName === "Calibration Type" &&
          setting.value.toLowerCase() === "true"
        ) {
          calTypeSetting = true;
        }
        if (setting.keyName?.startsWith("role")) {
          _rolesSettings[setting.keyName?.split("role")[1]] = {
            name: setting.value,
            id: setting.keyName?.split("role")[1],
          };
        }

        _settingList[setting.keyName] = setting.value;
      });
      _settingList["amendment"] = _settingList["amendment"].toLowerCase();
      setSettingList(_settingList);
      // setDefaultRemark(remark);
      setCompliance(complance);
      setCalTypeSetting(calTypeSetting);
      setRolesSettings(_rolesSettings);
    });
  }
  // todo : move to renderrangedata file
  const parseRange = (rangeVal) => {
    if (!rangeVal) {
      return "";
    }

    const tmp = rangeVal.split("||").map((range) => {
      const values = range.split("|").map((value) => value.trim());
      const firstValueParts = values[0].split("#");
      const firstValue = firstValueParts[0].trim();
      const firstUnit = firstValueParts[1] ? firstValueParts[1].trim() : "";

      const secondValueParts = values[1]
        ? values[1].split("#")
        : [undefined, undefined];
      const secondValue = secondValueParts[0] ? secondValueParts[0].trim() : "";
      const secondUnit = secondValueParts[1] ? secondValueParts[1].trim() : "";

      return `${firstValue}${firstValue ? " to " : ""}${secondValue}${
        secondValue ? ` ${secondUnit}` : ""
      }`;
    });

    return tmp.join(" , ");
  };

  function fetchCertificate() {
    let url = BASE_URL;
    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,ins.instruments,_j,cust.clients,_j,disc.discipline&_on1=(cer.instrumentId,eq,ins.id)&_on2=(cust.id,eq,cer.clientId)&_on3=(ins.disciplineId,eq,disc.id)&_fields=cer.lastModified,cer.approvedby,cer.status,cer.clientId,cer.location,cer.id,cer.instrumentId,cer.lc,cer.make,cer.model,cer.ConOfDuc,cer.ranges,cer.mfgNo,cer.deletedTableIds,cust.companyName,ins.instrumentName,ins.standardMasterIds,ins.calibrationProcedureNo,ins.referenceStandards,cer.address,cer.dcNumber,cer.calibrationDate,cer.dcDate,cer.nextDueDate,cer.receiptDate,cer.calProcRefNo,cer.mfgNo,cer.identificationNo,cer.DUCID,cer.DUCID,cer.calibrationType,cer.specificValues,cer.locationOfInstrument,cer.serialNumber,cer.additionalStandardIds,cer.serviceReqNumber,cer.jobNumber,cer.accuracy,cer.poNumber,cer.poDate,cer.startTemp,cer.endTemp,cer.tempDiff,cer.startHumidity,cer.endHumidity,cer.humDiff,cer.dateOfIssue,cer.model,cer.calPoint,cer.calMethod,cer.locationOfInstrument,cer.srnNo,cer.atmosphericPressure,cer.configuration,cer.workOrderNumber,cer.certificateNumber,cer.ULRNo,cer.tableDisplayStatus,disc.name,disc.remarks,cer.extraColumns,cer.calibrationReason,cer.complianceStatus&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        if (res.data.length && res.data[0] != null) {
          setLastModified(res.data[0].cer_lastModified);
          setStatus(res.data[0].cer_status);
          setId(res.data[0].cer_id);
          setClientId(res.data[0].cust_companyName);
          setInstrumentName(res.data[0].ins_instrumentName);
          setMake(res.data[0].cer_make);
          setModel(res.data[0].cer_model);
          setLC(res.data[0].cer_lc);
          setDefaultRemark(res.data[0].disc_remarks);
          setRanges(parseRange(res.data[0].cer_ranges));
          setReferenceStandards(res.data[0].ins_referenceStandards);
          setSelectedStandards(
            res.data[0].cer_additionalStandardIds?.split(",")
          );
          setCalibrationProcedureNo(res.data[0].ins_calibrationProcedureNo);
          setAddress(res.data[0].cer_address);
          setDcNumber(res.data[0].cer_dcNumber);
          setUlrNumber(res.data[0].cer_ULRNo);
          setCertificateNumber(res.data[0].cer_certificateNumber);
          // setCalibrationDate(res.data[0].cer_calibrationDate)
          setDcDate(res.data[0].cer_dcDate);
          setNextDueDate(res.data[0].cer_nextDueDate);
          setReceiptDate(res.data[0].cer_receiptDate);
          setCalProcRefNo(res.data[0].cer_calProcRefNo);
          setMfgNo(res.data[0].cer_mfgNo);
          setIdentificationNo(res.data[0].cer_identificationNo);
          setDUCID(res.data[0].cer_DUCID);
          setCalType(res.data[0].cer_calibrationType);
          res.data[0].cer_specificValues
            ? setSpecificValuesFromTable(
                res.data[0].cer_specificValues.split(",")
              )
            : setSpecificValuesFromTable([]);
          setLocation(res.data[0].cer_location);
          setAccuracy(res.data[0].cer_accuracy);
          setSerialNo(res.data[0].cer_serialNumber);
          setServiceReqNumber(res.data[0].cer_serviceReqNumber);
          setJobNumber(res.data[0].cer_jobNumber);
          setWorkOrderNumber(res.data[0].cer_workOrderNumber);
          setPoNo(res.data[0].cer_poNumber);
          setPoDate(res.data[0].cer_poDate);
          setStartTemp(res.data[0].cer_startTemp);
          setEndTemp(res.data[0].cer_endTemp);
          setTempDiff(res.data[0].cer_tempDiff);
          setStartHumidity(res.data[0].cer_startHumidity);
          setEndHumidity(res.data[0].cer_endHumidity);
          setHumDiff(res.data[0].cer_humDiff);
          setDateOfIssue(res.data[0].cer_dateOfIssue);
          setModel(res.data[0].cer_model);
          setConOfDuc(res.data[0].cer_ConOfDuc);
          setCalPoint(res.data[0].cer_calPoint);
          setCalMethod(res.data[0].cer_calMethod);
          setLocationOfInstrument(res.data[0].cer_locationOfInstrument);
          setSrnNo(res.data[0].cer_srnNo);
          setAtmosphericPressure(res.data[0].cer_atmosphericPressure);
          setConfiguration(JSON.parse(res.data[0].cer_configuration));
          setDisciplineName(res.data[0].disc_name);
          setTableDisplayStatus(res.data[0].cer_tableDisplayStatus);
          let extraEvvtries = res.data[0].cer_extraColumns
            ? JSON.parse(res.data[0].cer_extraColumns)
            : {};
          setInput(extraEvvtries["input"]);
          extraEvvtries["operating range"] = parseRange(
            extraEvvtries["operating range"]
          );
          setOperatingrand(extraEvvtries["operating range"]);
          setDepartment(extraEvvtries["department"]);
          setExtraColumns(
            Object.keys(extraEvvtries).length
              ? Object.entries(extraEvvtries)
              : []
          );
          setCalibrationReason(res.data[0].cer_calibrationReason);
          setComplianceStatus(res.data[0].cer_complianceStatus);
        }

        setInstrumentId(res.data[0].cer_instrumentId);
        setDeletedTableIds(res.data[0].cer_deletedTableIds);

        // fetchRangesData(res.data[0].cer_instrumentId);

        fetchInstrument(res.data[0].cer_instrumentId);
        if (res.data[0].cer_additionalStandardIds) {
          let standardMasterIds = res.data[0].cer_additionalStandardIds;

          var splitStData = standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        } else if (res.data[0].ins_standardMasterIds) {
          var splitStData = res.data[0].ins_standardMasterIds.split(",");
          var combineArray = [];
          splitStData.forEach(function (element) {
            var splitByCol = element.split(":")[0];
            combineArray.push(splitByCol);
          });
          axios
            .get(url + `standards?_where=(id,in,${combineArray.toString()})`)
            .then((res) => {
              setStandardMasterArray(res.data);
            });
        }
      })
      .catch((err) => {
        console.log("certificate data fetching error: ", err);
      });

    axios
      .get(
        url +
          `xjoin?_join=cer.certificates,_j,d.datasheets&_on1=(cer.id,eq,d.id)&_fields=d.calibratedby,d.calibrationDate,cer.approvedby,cer.rejectedBy,cer.approvedDate,cer.reviewedBy,cer.reviewedDate,cer.signedBy,cer.signedDate,cer.witnessBy,cer.witnessDate,d.requestedname,d.revisionNumber,d.amendment,d.calFrequency&_where=(cer.id,eq, ${certificateId})`
      )
      .then((res) => {
        // Need to fetch company name for each user exists using xjoin
        // for clients use c and for users use u
        axios
          .get(
            BASE_URL +
              `users?_where=(id,in${
                res.data[0].d_calibratedby
                  ? `,${res.data[0].d_calibratedby}`
                  : "" || ""
              }${
                res.data[0].cer_approvedby
                  ? `,${res.data[0].cer_approvedby}`
                  : "" || ""
              }${
                res.data[0].cer_rejectedBy
                  ? `,${res.data[0].cer_rejectedBy}`
                  : "" || ""
              }${
                res.data[0].cer_reviewedBy
                  ? `,${res.data[0].cer_reviewedBy}`
                  : "" || ""
              }${
                res.data[0].cer_signedBy
                  ? `,${res.data[0].cer_signedBy}`
                  : "" || ""
              }${
                res.data[0].cer_witnessBy
                  ? `,${res.data[0].cer_witnessBy}`
                  : "" || ""
              })`
          )
          .then((res2) => {
            // Logic to remove _c and _u if xjoin if used
            // Update replace all if xjoin members are increased in future
            let tmp = [...res.data];
            tmp.map((e, i) =>
              Object.keys(e).map(
                (k) =>
                  (res.data[i][k.replaceAll("_c", "").replaceAll("_u", "")] =
                    e[k])
              )
            );
            res2.data.map((e) => {
              if (e.id == res.data[0].d_calibratedby) {
                setCalibratedby([
                  res.data[0].d_calibratedby,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
              }
              if (e.id == res.data[0].cer_approvedby)
                setApprovedby([
                  res.data[0].cer_approvedby,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
              if (e.id == res.data[0].cer_rejectedBy)
                setRejectedBy([
                  res.data[0].cer_rejectedBy,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
              if (e.id == res.data[0].cer_reviewedBy)
                setReviewedBy([
                  res.data[0].cer_reviewedBy,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
              if (e.id == res.data[0].cer_signedBy)
                setSignedBy([
                  res.data[0].cer_signedBy,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
              if (e.id == res.data[0].cer_witnessBy)
                setWitnessedBy([
                  res.data[0].cer_witnessBy,
                  e.userName,
                  e.id,
                  e.companyName,
                ]);
            });
          });

        setCalibrationDate(res.data[0].d_calibrationDate);
        setApprovedDate(res.data[0].cer_approvedDate);
        setReviewedDate(res.data[0].cer_reviewedDate);
        setSignedDate(res.data[0].cer_signedDate);
        setWitnessDate(res.data[0].cert_witnessDate);

        setCalibrationFrequency(res.data[0].d_calFrequency);
        setRevisionNumber(res.data[0].d_revisionNumber);
        let tmpSplt = res.data[0].d_amendment
          ? res.data[0].d_amendment.split("\n")
          : [];
        setAmendmentHistory(tmpSplt.filter((_, i) => i === tmpSplt.length - 1));
        setRequestedName(
          res.data[0].d_requestedname ? res.data[0].d_requestedname : ""
        );
      });
  }

  function fetchInstrument(instrumentId) {
    let url = BASE_URL;
    axios
      .get(url + `instruments?_where=(id,eq,${instrumentId})`)
      .then((res) => {
        setInstrument(res.data);
      })
      .catch((err) => {
        console.log("certificate tables data fetching error: ", err);
      });
  }

  function certificateCount() {
    let data = {
      query: `SELECT * FROM certificateAnalytics WHERE certificateId = ${certificateId} `,
    };

    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificateDownloadCount(res.data[0]);
      })
      .catch((err) => {
        console.log("certificateAnalytics tables data fetching error: ", err);
      });
  }

  const getInstrumentsList = () => {
    let url = BASE_URL;
    axios
      .get(url + "instruments")
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        console.log("Something Went Wrong!");
      });
  };

  const submitCertificate = () => {
    // props.setLoader(true);

    let data = {
      certificateNumber: certificateNumber,
      dcNumber: dcNumber,
      workOrderNumber: workOrderNumber,
      serviceReqNumber: serviceReqNumber,
      ULRNo: ulrNumber,
      poNumber: poNo,
      poDate: poDate
        ? moment(poDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dateOfIssue: dateOfIssue
        ? moment(dateOfIssue).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      calibrationDate: calibrationDate
        ? moment(calibrationDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      nextDueDate: nextDueDate ? moment(nextDueDate).format("YYYY-MM-DD") : "",

      receiptDate: receiptDate
        ? moment(receiptDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),

      dcDate: dcDate
        ? moment(dcDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
    };

    axios
      .patch(BASE_URL + "certificates/" + certificateId, data)
      .then((res) => {
        toast.success("Datasheet updated successfully!");
        // props.setLoader(false);
      })
      .catch((err) => {
        toast.error("error : " + err);
        // props.setLoader(false);
      });
  };

  useEffect(() => {
    getInstrumentsList();
    fetchCertificate();
    fetchSettings();
    certificateCount();
    // ServeDates();
  }, []);

  useEffect(() => {
    if (props.isPrint === true) {
      setisPrinting(true);
      setTimeout(() => {
        generatePDF();
      }, 2000);
    }
  }, [props.isPrint]);

  var refresh = () => {
    window.location.reload(false);
  };

  const changeCertificateStatus = async (ids, rejectionMessage) => {
    let url = BASE_URL;
    let data = {
      status: ids,
    };
    let dateTime = await ServeDates();

    switch (ids) {
      case 3:
        data.approvedby = Number(user[0]);
        data.approvedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        break;
      case 4:
        data.rejectedBy = Number(user[0]);
        data.approvedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        break;
      case 5:
        data.reviewedBy = user[0];
        data.reviewedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        break;
      case 6:
        data.signedBy = Number(user[0]);
        data.signedDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        break;
      case 7:
        data.witnessBy = Number(user[0]);
        data.witnessDate = moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
        break;
      default:
        break;
    }

    axios
      .patch(url + `certificates/${id}`, data)
      .then((res) => {
        toast.success(
          `Certificate is ${
            ["", "", "", "Approved", "Rejected", "Review", "Signed", "Witness"][
              ids
            ]
          } successfully!`
        );

        if (ids === 3) {
          updateSRFAnalytics([id], 80);
        }
        //TODO: Need to update _to once notification module is perfected.
        if (ids == 4) {
          const notification = {
            _from: user[0],
            _to: calibratedby[0],
            body: `${instrumentName}(${DUCID}) certificate rejected :\n ${rejectionMessage}`,
            type: 1,
          };
          notifiy(notification, () => {
            toast.success(`Notified of rejection to ${notification._to}`);
            setTimeout(refresh, 500);
          });
        } else {
          setTimeout(refresh, 500);
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const generateDynamicHeader = async (
    ulrNumber,
    certificateNumber,
    pageNo,
    totalPages
  ) => {
    const formattedCurrentPage = pageNo.toString().padStart(2, "0");
    const formattedTotalPages = totalPages.toString().padStart(2, "0");
    const pageRange = `${formattedCurrentPage}/${formattedTotalPages}`;
    let htmlString = `    
    <div id="responsivepdfheader">
      <table  style="width:100%;">
        <tr>
          ${
            ulrNumber
              ? `<td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">ULR No : </b> 
            <b style="font-size:19px;">${ulrNumber}</b>
          </td>`
              : ""
          }
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">Certificate No : </b>
            <b style="font-size:19px;">${certificateNumber}</b>
          </td>
          <td style="padding:5px;font-family: Calibri, sans-serif;height:60px;">
            <b style="font-size:20px;">Page Number : </b>
            <b style="font-size:19px;">${pageRange}</b>
          </td>
        </tr>
      </table>
    </div>
    `;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  const generateLetterHeadHeader = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechHeader} style="width:100%; height:170px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };

  const generateDynamicFooter = async (
    calibratedby,
    approvedby,
    calibrationDate
  ) => {
    let footers = [...roles];
    footers.map((e, i) => {
      if (e.id == 1) {
        footers[i].by = calibratedby[1];
        footers[i].date = calibrationDate;
        footers[i].companyName = calibratedby[3];
        footers[i].label = "Calibrated By";
      } else if (e.id == 2) {
        footers[i].by = approvedby[1];
        footers[i].date = approvedDate;
        footers[i].companyName = approvedby[3];
        footers[i].label = "Checked And Approved By";
      } else if (e.id == 3) {
        footers[i].by = reviewedBy[1];
        footers[i].date = reviewedDate;
        footers[i].companyName = reviewedBy[3];
        footers[i].label = "Reviewed By";
        footers[i].name = clientId;
      } else if (e.id == 4) {
        footers[i].by = signedBy[1];
        footers[i].date = signedDate;
        footers[i].companyName = signedBy[3];
      } else if (e.id == 5) {
        footers[i].by = witnessedBy[1];
        footers[i].date = witnessDate;
        footers[i].companyName = witnessedBy[3];
      }
    });
    footers = makeChunks(footers, footers.length == 4 ? 2 : 3);
    footers.map((row, i) => {
      footers[i][0].align = "left";
      if (row.length === 3) {
        footers[i][1].align = "center";
      }
      footers[i][row.length - 1].align = "right";
    });
    let footerString = `
    <div id="responsivepdfheader">
      ${footers
        .map(
          (row) => `
          <table style="width:100%;margin-bottom:5px">
          <tr>
          <td colspan="2">
          <b style="font-size:23px;">
          <b style="text-transform: 'uppercase'">For BFI, Mumbai</b> 
          </b>
          </td>
          <td>
          <b style="font-size:23px;">
          <b style="text-transform: 'uppercase'">For Customer</b> 
          </b>
          </td>
          </tr>
          <tr>
            ${row
              .map((column) =>
                electronicSign
                  ? `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    ${column.by ? "Electronically signed by" : "\u00A0"}
                        </b>
                        <br>
                          <b style="font-size:19px;">${
                            column.by ? column.by : "\u00A0"
                          }
                            <br>
                            ${
                              column.date
                                ? moment(column.date).format(
                                    "DD/MM/yyyy hh:mm A"
                                  )
                                : "\u00A0"
                            }
                        </b>
                    </div>
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
                  : `
                  <td style="vertical-align:top;text-align: ${
                    column.align
                  };font-family: Calibri, sans-serif;line-height:30px;width:${
                      100 / row.length
                    }%">
                    <div style="width: 100%;disply:flex;flex-direction:column;">
                    <div style="border-bottom:1px solid black;padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.label}</b> 
                    </b>
                    </div>
                    <div style="border-bottom:1px solid black;padding:5px 15px;height:70px;">
                   <b style="font-size:19px;">${
                     column.by ? column.by : "\u00A0"
                   }
                   </b>
                    </div>
                    <div style="padding:5px 15px;">
                    <b style="font-size:20px;">
                    <b style="text-transform: 'uppercase'">${column.name}</b> 
                    </b>
                    </div>
                    </div>    
                  </td>`
              )
              .join("")}
          </tr></table>`
        )
        .join("")}
    </div>`;

    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = footerString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return [img, footers.length];
  };
  const generateLetterHeadFooter = async () => {
    let htmlString = `
    <div>
      <img src=${UnitechFooter} style="width:100%; height:150px;"/>
    </div>`;
    let htmlNode = document.getElementById("header-section");
    htmlNode.innerHTML = htmlString;
    let image = await toPng(htmlNode, { quality: 2, scale: 3 });
    const img = new Image();
    img.src = image;
    htmlNode.innerHTML = "";
    return img;
  };
  var refresh = () => {
    window.location.reload(false);
  };
  const generatePDF = async () => {
    const element = printComponentRef.current;
    const [_, marginNumber] = await generateDynamicFooter(
      calibratedby,
      approvedby,
      calibrationDate
    );
    let footerHeight = 90;
    let footerPos = [0, 675];
    let pdf = await html2pdf()
      .from(element)
      .set({
        margin: [125, 1, 75 + marginNumber * footerHeight, 1],
        filename: `certificate_${certificateId}.pdf`,
        pagebreak: { after: "section", mode: ["css", "legacy"] },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          orientation: "portrait",
          unit: "pt",
          format: "a4",
        },
      })
      .toPdf()
      .get("pdf");

    const totalPages = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(9);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("helvetica", "bold");

      let image = await generateDynamicHeader(
        ulrNumber,
        `${certificateNumber}${
          settingList["amendment"] === "true" && revisionNumber > 0
            ? `/AMD-${revisionNumber}`
            : ""
        }`,
        i,
        totalPages
      );
      pdf.addImage(image, 0, 78, 595, 55);
      let height;
      [image, height] = await generateDynamicFooter(
        calibratedby,
        approvedby,
        calibrationDate
      );

      pdf.addImage(
        image,
        footerPos[0],
        height > 1 ? footerPos[1] - (height - 1) * footerHeight : footerPos[1],
        595,
        height * footerHeight
      );

      const text = "CALIBRATION CERTIFICATE";
      const pageWidth = pdf.internal.pageSize.getWidth();
      const textWidth =
        (pdf.getStringUnitWidth(text) * 12) / pdf.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;
      pdf.setFontSize(12);
      pdf.text(text, x, 85);
      pdf.setFont("helvetica", "normal");

      if (letterHead) {
        let image = await generateLetterHeadHeader();

        pdf.addImage(image, 0, 0, 595, 70);

        image = await generateLetterHeadFooter();
        pdf.addImage(image, 0, 790, 595, 50);
      }
      if (electronicSign) {
        pdf.setFontSize(9);
        const text =
          "This certificate is electronically signed and does not required physical signature";
        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 765);
      }
      if (i === totalPages) {
        pdf.setFontSize(9);
        const text = "--------- END OF CALIBRATION CERTIFICATE ---------";
        const text1 =
          certificateDownloadCount?.certificateDownloadCounter > 0
            ? `Download No.${certificateDownloadCount.certificateDownloadCounter}`
            : ".";

        const textWidth =
          (pdf.getStringUnitWidth(text) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const pageWidth = pdf.internal.pageSize.getWidth();
        const x = (pageWidth - textWidth) / 2;
        pdf.text(text, x, 778);
        pdf.text(text1, pageWidth - 83, 778);
      }
    }
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `certificate_${certificateNumber}.pdf`;
    a.click();
    // pdf.save();
    countOfCertificatesAnalytics([certificateId]);
    if (props.isPrint != true){
      setTimeout(() => {
        refresh();
      }, 500);
    }
  };

  // print view
  const dataArray = [ulrNumber,certificateNumber,calibrationDate,JSON.stringify(settingList),revisionNumber,
    Array.isArray(calibratedby) ? calibratedby.join(',') : calibratedby,
    Array.isArray(approvedby) ? approvedby.join(',') : approvedby,
    approvedDate,
    Array.isArray(reviewedBy) ? reviewedBy.join(',') : reviewedBy,
    reviewedDate,
    clientId,
    Array.isArray(signedBy) ? signedBy.join(',') : signedBy,
    signedDate,
    Array.isArray(witnessedBy) ? witnessedBy.join(',') : witnessedBy,
    witnessDate,
  ];

  useEffect(async()=>{
    let splitAddress=address?.split(",")
    let newAddressArray = await fetchAddresses(splitAddress?.[0],"0")
    setNewAddressArray(newAddressArray)
  },[address])
    // console.log("dataArray",dataArray)
  const concatenatedData = dataArray.join('&&');
  const printView = () => {
    return (
      // <>
      <div
        id="printOnly2"
        // style={{ pageBreakAfter: "always" }}
        ref={printComponentRef}
      >
        <div style={{ width: "100%" }} id="header-section"></div>
        <table style={{ width: "100%" }}>
          <tbody className="page" data-id={concatenatedData}>
            <tr>
              <td style={{ border: "none" }}>
                <div
                  class="content"
                  style={{
                    fontSize: "11px",
                    margin: "20px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    padding: "10px",
                    border: "1px solid black",
                  }}
                >
                  <Table>
                    {customerDetailsTable()}
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        <b>DETAILS OF UNIT UNDER CALIBRATION (UUC) </b>
                      </TableCell>
                    </TableRow>
                    {unitCalibrationDetails()}
                    <Table
                      className={classes.table}
                      style={{ marginBottom: "3px" }}
                    >
                      <TableRow>
                        <TableCell>
                          <b>Environmental Conditions: </b>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>
                              Temperature :
                            </b>
                            {startTemp} C°
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <b style={{ paddingRight: "10px" }}>Humidity:</b>
                            {startHumidity} %RH
                          </span>
                        </TableCell>
                      </TableRow>
                    </Table>
                    {masterDataTable()}
                  </Table>
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <div style={{ marginBottom: "5px", marginTop: "10px" }}>
                        <b>DISCIPLINE : </b>
                        {disciplineName}
                      </div>
                      <div className="row">
                        <RenderStaticTables
                          tableDisplayStatus={tableDisplayStatus}
                          configuration={configuration}
                          certificateId={certificateId}
                          instrumentId={instrumentId}
                          deletedTableIds={deletedTableIds}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            flex: "1",
                          }}
                        >
                          <div className="text-start" style={{}}>
                            <pre
                              style={{
                                fontFamily: " Calibri, sans-serif",
                                fontSize: "10px",
                                marginBottom: "0px",
                                padding: "0",
                                lineHeight: 1,
                                whiteSpace: "pre-wrap",
                                pageBreakInside: "avoid",
                              }}
                            >
                              <div
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                <b
                                  style={{ float: "left", fontSize: "11px" }}
                                  className="standardUnitTable"
                                >
                                  Remarks :
                                </b>
                                {compliance
                                  ? `The results ${
                                      complianceStatus == 1 ? "" : "doesn't"
                                    } comply with acceptance criteria\n`
                                  : ""}
                                {calTypeSetting
                                  ? `Reason For Calibration : ${
                                      calibrationReason == 1
                                        ? "New"
                                        : "Periodic"
                                    }\n`
                                  : ""}
                                {`${
                                  configuration?.remark || defaultReamrk || ""
                                }\n${amendmnetHistory || ""}`}
                              </div>
                            </pre>
                          </div>
                        </div>
                        <div
                          style={{
                            textAlignLast: "center",
                            padding: "0px 10px",
                            marginTop: "5px",
                          }}
                        >
                          <QRCode
                            value={`${DOMAIN}/#/certificate/print/${certificateId}`}
                            align="center"
                            size={60}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {
                          settingList["lastModified"] &&
                          settingList["lastModified"].toLowerCase() === "true"
                            ? `\nPrint At : ${moment().format(
                                "MM/DD/yyyy HH:mm A"
                              )}`
                            : ""
                          // `\nLast Modified : ${moment(lastModified).format("mm/DD/yyyy")}` : ""
                        }
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ textAlign: "center" }}>
                    --------- END OF CALIBRATION CERTIFICATE ---------
                  </div>*/}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      // </>
    );
    function customerDetailsTable() {

      return (
        <Table className={classes.table} style={{ marginBottom: "3px" }}>
          <TableRow className={classes.row}>
            <TableCell className={classes.addressCell} style={{ width: "25%" }}>
              <div>
                <b>Customer Name & Address</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: "3px",
              }}
            >
              <div>
                <b>{clientId}</b>
              </div>
              <div>{newAddressArray?.[0]?.address}</div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Date Recipet</b>
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Date Calibration</b>
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                <b>Due Date</b>
              </div>
              <div style={{ padding: "3px" }}>
                <b>Date Issue</b>
              </div>
            </TableCell>
            <TableCell
              style={{
                width: "25%",
                borderLeft: "1px solid black",
                padding: 0,
              }}
            >
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(receiptDate).format("DD/MM/YYYY")}
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(calibrationDate).format("DD/MM/YYYY")}
              </div>
              <div style={{ borderBottom: "1px solid black", padding: "3px" }}>
                {moment(nextDueDate).format("DD/MM/YYYY")}
              </div>
              <div style={{ padding: "3px" }}>
                {dateOfIssue ? moment(dateOfIssue).format("DD/MM/YYYY") : moment(approvedDate).format("DD/MM/YYYY")}
              </div>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function unitCalibrationDetails() {
      return (
        <Table
          className={classes.table}
          style={{ fontSize: "11px", marginBottom: "3px" }}
        >
          <TableRow className={classes.row}>
            <TableCell className={classes.infoCell} align="center">
              <TableCell
                style={{
                  border: "none",
                  width: "150px",
                  padding: "0px",
                }}
              >
                <b>Name of Instrument </b>
              </TableCell>
              <TableCell style={{ border: "none" }}>:</TableCell>
              <TableCell style={{ border: "none" }}>
                {requestedName.length > 0 ? requestedName : instrumentName}
              </TableCell>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>SRF No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {serviceReqNumber}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Make</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{make}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Identification No.</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{DUCID}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Model</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{models}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Serial no. </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{serialNo}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Range</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ranges}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Accuracy</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {accuracy
                    ? `± ${accuracy
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", " ")}`
                    : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Resolution/Least Count</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {lc
                    ?.replaceAll("||", ", ")
                    ?.replaceAll("|", " to ")
                    ?.replaceAll("#", " ")}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Location</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {locationOfInstrument ? locationOfInstrument : ""}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibration Location </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{location}</TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Condition of Instrument </b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>{ConOfDuc}</TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Calibration Procedure</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {calibrationProcedureNo}
                </TableCell>
              </TableRow>
            </TableCell>
            <TableCell className={classes.infoCell}>
              <TableRow style={{ border: "none", padding: "0px" }}>
                <TableCell
                  style={{
                    border: "none",
                    width: "150px",
                    padding: "0px",
                  }}
                >
                  <b>Standard Reference</b>
                </TableCell>
                <TableCell style={{ border: "none" }}>:</TableCell>
                <TableCell style={{ border: "none" }}>
                  {referenceStandards}
                </TableCell>
              </TableRow>
            </TableCell>
          </TableRow>
        </Table>
      );
    }
    function masterDataTable() {
      //Please don't put thead or tbody
      return (
        <div align="center">
          <b>DETAILS OF STANDARD USED FOR CALIBRATION</b>
          <br></br>
          <table
            className="standardUnitTable single-border-table"
            align="center"
          >
            <tr>
              <td>
                <b>Instrument Name</b>
              </td>
              <td>
                <b>Id/Sr. No. </b>
              </td>
              <td>
                <b>Certificate No.</b>
              </td>
              <td>
                <b>Calibrated By</b>
              </td>
              <td>
                <b>Calibration Due Date</b>
              </td>
            </tr>
            {standardMasterArray.map((row, index) => (
              <tr key={row.name}>
                <td>{row.standardName}</td>
                <td>{row.stId}</td>
                <td>{row.certificateNumber}</td>
                <td>{row.traceability}</td>
                <td>{moment(row.validUpto).format("DD/MM/YYYY")}</td>
              </tr>
            ))}
          </table>
        </div>
      );
    }
  };

  const showButton = (button) => {
    if (approvedby?.[0]) return false;
    const userTypeBollean = userType == 1 || userType == 4;
    if (!userTypeBollean && !roles_ids.includes(button)) return false;
    let ret =
      calibratedby &&
      calibratedby.length > 0 &&
      calibratedby[0] !== user[1] &&
      !props.isPrint &&
      status !== -1;

    switch (button) {
      case 3:
        ret =
          ret &&
          !reviewedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[3]?.name
              ? `${rolesSettins[3]?.name}`.toLocaleLowerCase() !== "false"
              : true)) ||
            props.rolesInfo?.currentRole?.id == 3);
        break;
      case 4:
        ret =
          ret &&
          !signedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[4]?.name
              ? `${rolesSettins[3]?.name}`.toLocaleLowerCase() !== "false"
              : true)) ||
            props.rolesInfo?.currentRole?.id == 4);
        break;
      case 5:
        ret =
          ret &&
          !witnessedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[5]?.name
              ? `${rolesSettins[5]?.name}`.toLocaleLowerCase() !== "false"
              : true)) ||
            props.rolesInfo?.currentRole?.id == 5);
        break;
      case 1:
        ret =
          ret &&
          !rejectedBy[0] &&
          ((userTypeBollean &&
            (rolesSettins[1]?.name
              ? `${rolesSettins[1]?.name}`.toLocaleLowerCase() !== "false"
              : true)) ||
            props.rolesInfo?.currentRole?.id == 2);
        break;
      case 2:
        ret =
          ret &&
          ((userTypeBollean &&
            (rolesSettins[2]?.name
              ? `${rolesSettins[2]?.name}`.toLocaleLowerCase() !== "false"
              : true)) ||
            props.rolesInfo?.currentRole?.id == 2);
        break;
      default:
        ret = false;
        break;
    }

    return ret;
  };

  const ShowRejectModal = () => {
    const [rejectReason, setRejectReason] = useState("");
    return (
      <Modal
        open={modalOpen}
        onClose={(e, r) => {
          if (r !== "backdropClick") handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Rejection"
            value={rejectReason}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <Tooltip title="Send Rejection Reason" placement="top-start">
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                changeCertificateStatus(4, rejectReason);
              }}
              disabled={rejectReason === ""}
            >
              Reject
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  const ShowCommentModal = () => {
    const [comment, setComment] = React.useState("");
    return (
      <Modal
        open={modalOpen2}
        onClose={(e, r) => {
          setComment("");
          handleClose2();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Comment"
            value={comment}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setComment(e.target.value)}
          />
          <Tooltip title="Send Comment" placement="top-start">
            <Button
              variant="contained"
              color="info"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                const notification = {
                  _from: user[0],
                  _to: calibratedby[0],
                  body: `${instrumentName}(${DUCID})(${
                    showButton(3)
                      ? "Review By"
                      : showButton(4)
                      ? "Sign By"
                      : showButton(5)
                      ? "Witnessed By"
                      : ""
                  }):\n${comment}`,
                  type: 1,
                };
                setComment("");
                notifiy(notification, () => {
                  toast.success(`Comment notified to ${notification._to}`);
                  setTimeout(refresh, 500);
                });
              }}
              disabled={comment === ""}
            >
              Send Comment
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  return (
    <>
    {!props.isPrint && (
    <div style={{display:"flex",justifyContent:"flex-end"}}>            
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
         viewCertificateGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>    
    )}
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {((userType == 1 || userType == 4) && approvedby &&  !props.certificateIds) ||
        (!props.isPrint && approvedby && !props.certificateIds) ? (
          <Grid container spacing={2} align="left" style={{ display: "flex" }}>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="certificate-certificateviewletterhead"
                options={[
                  { id: 1, label: "With Letter Head" },
                  { id: 2, label: "Without Letter Head" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="letterHead" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Letter Head" ||
                    newInputValue === ""
                  ) {
                    setLetterHead(true);
                  } else if (newInputValue === "Without Letter Head") {
                    setLetterHead(false);
                  }
                  // newInputValue == "Approve" ? setEnableDisable() : setEnableDisable(4+':'+result.toString());
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Autocomplete
                size="small"
                id="certificate-certificateviewelectronicsign"
                options={[
                  { id: 1, label: "With Electronically Signed" },
                  { id: 2, label: "Without  Electronically Signed" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Electronically Signed" />
                )}
                onInputChange={(event, newInputValue) => {
                  if (
                    newInputValue === "With Electronically Signed" ||
                    newInputValue === ""
                  ) {
                    setElectronicSign(true);
                  } else if (
                    newInputValue === "Without  Electronically Signed"
                  ) {
                    setElectronicSign(false);
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                id="certificate-certificateviewDownalod"
                variant="contained"
                size="small"
                sx={{ ml: 3 }}
                onClick={() => {
                  generatePDF();
                  // certificateCount()
                }}
              >
                Download PDF
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                id="certificate-certificateviewDownalod"
                variant="contained"
                size="small"
                onClick={copyToClipboard} 
                startIcon={<ContentCopyIcon />}               
              >
               Copy URL
              </Button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Toolbar>
      {printView()}
      <Toolbar style={{ padding: "0px", width: "100%" }}>
        {showButton(3) && (
          <Button
            id="certificate-certificateview_reviewbtn"
            variant="contained"
            color="success"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              changeCertificateStatus(5);
            }}
          >
            Review
          </Button>
        )}
        {showButton(4) && (
          <Button
            id="certificate-certificateview_signbtn"
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              changeCertificateStatus(6);
            }}
          >
            Sign
          </Button>
        )}
        {showButton(5) && (
          <Button
            id="certificate-certificateview_Witnessedbtn"
            variant="contained"
            color="info"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              changeCertificateStatus(7);
            }}
          >
            Witnessed
          </Button>
        )}
        {showButton(1) && (
          <Button
            id="certificate-certificateview_Rejectbtn"
            variant="contained"
            color="error"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Reject
          </Button>
        )}
        {showButton(2) && (
          <Button
            id="certificate-certificateview_Approvebtn"
            variant="contained"
            color="success"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => {
              changeCertificateStatus(3);
            }}
          >
            Approve
          </Button>
        )}
        {userType == 3 && (showButton(3) || showButton(4) || showButton(5)) && (
          <Button
            variant="contained"
            color="info"
            size="small"
            sx={{ ml: 3 }}
            onClick={() => setModalOpen2(true)}
          >
            Send Comment
          </Button>
        )}
      </Toolbar>
      {ShowRejectModal()}
      {ShowCommentModal()}
    </>
  );
}
