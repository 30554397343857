import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Toolbar,
  Button,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { createProductGuide } from "./guide";
const axios = require("axios");

export default function CreateProduct(props) {
  const navigate = useNavigate();
  const createproductGuides = createProductGuide();
  const [instrumentArray, setInstrumentArray] = React.useState([]);
  const [prRate, setPrRate] = React.useState("");
  const [instrumentId, setInstrumentId] = React.useState(null);
  const [clientList, setClientList] = React.useState([]);
  const [clientId, setClientId] = React.useState(0);

  const getInstrumentsList = () => {
    let url = BASE_URL;
    let data = {
      query: `SELECT instruments.id, instruments.instrumentName FROM instruments LEFT JOIN customProducts ON instruments.id = customProducts.instrumentId AND customProducts.clientId = ${clientId} WHERE instruments.status = 1 AND customProducts.instrumentId IS NULL`,
      //AND customProducts.instrumentId IS NULL
    };
    axios
      .post(url + "dynamic", data)
      .then((res) => {
        setInstrumentArray(res.data);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getInstrumentsList();
  }, [clientId]);

  var refresh = () => {
    window.location.reload(false);
    navigate("/master/product");
  };

  const saveProduct = (event) => {
    let row = {
      clientId: clientId,
      rate: prRate,
      instrumentId: instrumentId,
    };

    let url = BASE_URL;

    axios
      .post(url + "customProducts", row)
      .then((res) => {
        props.setLoader(false);
        toast("custom product created successfully !");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };

  var pushIArray = [];
  for (let i = 0; i < instrumentArray.length; i++) {
    if (instrumentArray[i].instrumentName) {
      pushIArray.push({
        label: instrumentArray[i].id + ", " + instrumentArray[i].instrumentName,
      });
    }
  }

  const getCustomer = (event) => {
    let url = BASE_URL;
    axios
      .get(url + "clients?_where=(status,eq,1)")
      .then((res) => {
        let pushArray = [];
        res.data.map((client) => {
          pushArray.push({
            id: client.id,
            label: client.companyName,
            // contact: client.contact,
            address: client.address,
          });
        });
        setClientList(pushArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create Product
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          createproductGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div> 

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="product_create_company"
            options={clientList}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Choose Company Name *" />
            )}
            onChange={(event, value) => {
              if (value === null) {
                setClientId(0);
              } else {
                setClientId(value.id);
              }
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <Autocomplete
            size="small"
            id="product_create_instruname"
            options={pushIArray}
            getOptionLabel={(option) => option.label}
            // value={invoiceObject.companyName}
            renderInput={(params) => (
              <TextField {...params} label="Choose an Instrument *" />
            )}
            onChange={(event, value) => {
              let id = value?.label?.split(",")[0];
              let name = value?.label?.split(",")[1];
              //  setPrName(name)
              setInstrumentId(id);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="product_create_rate"
            label="Rate *"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              setPrRate(e.target.value);
            }}
          />
        </Grid>
      </Grid>

      <br />
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        <Button
        id="product_create_save"
          variant="contained"
          size="small"
          onClick={() => {
            props.setLoader(true);
            saveProduct();
          }}
        >
          Save
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
