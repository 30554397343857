import React, { useState, useEffect } from "react";
import { BASE_URL, SRF_ID_BASE } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { generateNumber } from "../../../utils/utils";
import "./../srf.css";
import "driver.js/dist/driver.css";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { showComponent } from "../../helper/helpers";
import { useParams } from "react-router-dom";

import { fetchClientIdList, useSrfDetails, useSettings} from "./fetchHandlers";
import { DUCInwardDetails } from "./DUCInwardDetails";
import { handleUpdateSubmit, handleCreateSubmit } from "./submitHandlers";
import { editSRFGuide } from "../guide";
import CustomerAnnexure from "./customerAnnexure";
import { fetchAddresses } from "../../invoice/productDetails";

const axios = require("axios");

export default function CreateSRF(props) {
  const params = useParams();
  const { setLoader } = props;
  const editSRFGuides = editSRFGuide();
  
  const [readingRows, setReadingRows] = useState([]);
  const [srfInstrumentsToDelete, setSrfInstrumentsToDelete] = useState([]);

  const [clientArray, setClientArray] = useState([]);
  const [latestId, setLatestId] = useState(null);
  const [latestSRFId, setLatestSRFId] = useState(null);
  const [allAddress, setAllAddress] = React.useState([]);
  const [isCustomerAnnexure, setIsCustomerAnnexure]=React.useState(false)
  // constants
  const userType = localStorage.getItem("type");

  // data hooks
  const [srfDetails, updateSrfDetails, fetchSrfDetails] = useSrfDetails({
    id: params?.id,
    customerAnnextureFilePaths:[],
  });
  const [settings, fetchSettings] = useSettings({});


  // helper functions
  var pushCArray = [];
  var clientsArrays = [];
  for (let i = 0; i < clientArray.length; i++) {
    if (clientArray[i].companyName) {
      pushCArray.push({
        label: clientArray[i].id + ", " + clientArray[i].companyName,
      });
    }
    clientsArrays.push({
      id: clientArray[i].id,
      address: clientArray[i].address,
      branch: clientArray[i].branchName,
      shippingAddress:clientArray[i].shippingAddress,
    });
  }

  const getNewCertId = () => {
    return axios
      .get(BASE_URL + `certificates?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestId(0);
        else setLatestId(res.data[0].id);
      });
  };

  const getNewSRFId = () => {
    return axios
      .get(BASE_URL + `srfs?_sort=-id&_fields=id&_size=1`)
      .then((res) => {
        if (res.data.length < 1) setLatestSRFId(0);
        else setLatestSRFId(res.data[0].id + 1);
      });
  };

  const validateData = () => {
    let isValid = true;

    if (!srfDetails?.clientId) {
      toast.error("Please select a client");
      isValid = false;
    }
    if (!srfDetails?.entryDate) {
      toast.error("Please select an entry date");
      isValid = false;
    }

    if (!readingRows?.length) {
      toast.error("Please select at least one SRF Instrument!");
      isValid = false;
    }

    readingRows.some((row, index) => {
      if (row.instrumentId == null || row.instrumentId == "") {
        toast.error(`Row ${index + 1}: Please select an Instrument`);
        isValid = false;
      }
      if (row.DUCID == null || row.DUCID == "") {
        toast.error(`Row ${index + 1}: Please add DUCID`);
        isValid = false;
      }
      if (row.locationOfInstrument == null || row.locationOfInstrument == "") {
        toast.error(`Please add location of Instrument for row ${index + 1}`);
        isValid = false;
        return true;
      }
      if (row.ranges == null || row.ranges == "") {
        toast.error(`Row ${index + 1}: Please add DUC Range(s)`);
        isValid = false;
      }
      if (row.lc == null || row.lc == "") {
        toast.error(`Row ${index + 1}: Please add Least Count(s)`);
        isValid = false;
      }
      if (row.make == null || row.make == "") {
        toast.error(`Row ${index + 1}: Please add DUC Make`);
        isValid = false;
      }
      if (row.calFrequency == null || row.calFrequency == "") {
        toast.error(
          `Row ${index + 1}: Please select the Calibration frequency`
        );
        isValid = false;
      }
      if (row.location == null || row.location == "") {
        toast.error(`Row ${index + 1}: Please select the Location`);
        isValid = false;
      }
    });

    return isValid;
  };



  useEffect(() => {
    params?.id > 0 && fetchSrfDetails();

    fetchClientIdList(setClientArray);
    fetchSettings([
      "srfInstrumentsAdditionalColumns",
      "DUCMasterStatus",
      "Certificate Number",
      "serviceReqNumber",
      "srfInstrumentsAdditionalColumns",
      "ULR number",
      "ULR ID",
      "Customer Annexure",
    ]);
    getNewCertId();
    getNewSRFId();
  }, []);

  useEffect(() => {
    let serviceReqNumber = settings?.serviceReqNumber?.value;
    if (serviceReqNumber && latestSRFId != null) {
      updateSrfDetails({
        serviceReqNumber: generateNumber(
          serviceReqNumber,
          `${SRF_ID_BASE + latestSRFId}`,
          {
            branch: srfDetails?.branch,
          }
        ),
      });
    }
  }, [srfDetails?.clientId, srfDetails?.branch]);

  useEffect(async()=>{

    let Client = clientArray?.filter((e) => e.id == srfDetails?.clientId)[0];

    const addressIds = Client?.address
    const shippingAddressIds = Client?.shippingAddress

   let newAddressArray = await fetchAddresses(addressIds,shippingAddressIds)
    setAllAddress(newAddressArray);
  },[srfDetails?.clientId,clientArray])

  useEffect(() => {
    updateSrfDetails({
      serviceReqNumber: generateNumber(settings.serviceReqNumber?.value),
    });
    updateSrfDetails({
      "Certificate Number": settings["Certificate Number"]?.value,
      "ULR number": settings["ULR number"]?.value,
      "ULR ID": settings["ULR ID"]?.value,
    });

    if(settings["Customer Annexure"]?.value === "true"){
      setIsCustomerAnnexure(true)
    }
   
  }, [settings]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Create SRF
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
      <Button
        style={{ float: "right" }}
        onClick={() => {
          editSRFGuides.drive();
        }}
      >
        <HelpIcon />
      </Button>
      </Tooltip>
      <br />
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}  id="editsrf-companyName">
          <Autocomplete
            size="small"           
            options={pushCArray}
            value={
              (srfDetails?.clientId ? srfDetails?.clientId + ", " : "") +
              (srfDetails?.companyName || "")
            }
            renderInput={(params) => (
              <TextField {...params} label="Company Name *" />
            )}
            onChange={async(event, newInputValue) => {
              newInputValue = newInputValue?.label;
              let clientDetails = {};

              clientsArrays.map((client) => {
                if (client.id === parseInt(newInputValue?.split(",")[0])) {
                  if (client.address) {
                    clientDetails["address"] = client.address;
                    clientDetails["branch"] = client.branch;
                    clientDetails["shippingAddress"] = client.shippingAddress;
                  } else {
                    clientDetails["address"] = "";
                  }
                }
              });
              const addressIds = clientDetails?.address
              const shippingAddressIds = clientDetails?.shippingAddress
             let newAddressArray = await fetchAddresses(addressIds,shippingAddressIds)
              setAllAddress(newAddressArray);
              if (newAddressArray.length == 1)
                updateSrfDetails({ address: newAddressArray[0]?.address });
              updateSrfDetails({
                clientId: parseInt(newInputValue?.split(",")[0]),
                companyName: newInputValue?.split(",")[1],
                ...clientDetails,
              });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}  id="editsrf-address">
          {allAddress?.length === 1 || params?.id != 0 ? (
            <TextField
              // value={allAddress?.[0]?.address || srfDetails?.address || ""}
              value={
                allAddress?.length === 1
                  ? allAddress[0]?.address // If allAddress has only one element, use its address
                  : allAddress.find((e) => e.id == (srfDetails?.address).split(",")?.[0])?.address || ""
              }
              label="Address *"
              fullWidth
              variant="outlined"
              size="small"
              disabled
            />
          ) : (
            <Autocomplete
            options={allAddress}
            size="small"
            getOptionLabel={(option) => option?.address}
            onChange={(event, value) => {
              updateSrfDetails({ address: value?.id });
            }}
              renderInput={(params) => (
                <TextField {...params} label="Address *" variant="outlined" />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-serno">
          <TextField
            id="outlined-basic"
            label="Service request number *"
            size="small"
            fullWidth
            variant="outlined"
            defaultValue={srfDetails?.serviceReqNumber || ""}
            value={srfDetails?.serviceReqNumber || ""}
            onChange={(e) => {
              updateSrfDetails({ serviceReqNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-dcno">
          <TextField
            id="outlined-basic"
            label="Dc No./RGP No."
            efaultValue={srfDetails?.dcNumber || ""}
            value={srfDetails?.dcNumber || ""}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ dcNumber: e.target.value });
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-pono">
          <TextField
            id="outlined-basic"
            label="PO Number"
            value={srfDetails.poNumber || ""}
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            onChange={(e) => {
              updateSrfDetails({ poNumber: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-billingname">
          <Autocomplete
            size="small"
            id="combo-box-demo"
            options={pushCArray}
            value={pushCArray.find((ele) => ele.label?.split(",")?.[0] == srfDetails.billingId)?.label || null}
            renderInput={(params) => (
              <TextField {...params} label="Billing Name *" />
            )}
            onInputChange={(event, newInputValue) => {
              updateSrfDetails({
                billingId: parseInt(newInputValue.split(",")[0]),
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-entrydate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
            label="Entry Date *"
              value={srfDetails?.entryDate}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ entryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-dcdate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="DC Dt./RGP Dt."
              value={srfDetails.dcDate ? srfDetails.dcDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ dcDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>     
      <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-podate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
            label="PO Date"
              value={srfDetails.poDate ? srfDetails.poDate : new Date()}
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ poDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-receiptdate">
          <LocalizationProvider  dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
              label="Receipt date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                srfDetails.receiptDate ? srfDetails.receiptDate : new Date()
              }
              onChange={(newValue) => {
                updateSrfDetails({ receiptDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>   
        <Grid item xs={12} sm={6} md={4} lg={3} id="editsrf-comtdelvdate">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            slotProps={{ textField: { size: "small" ,fullWidth: true } }}
            label="Committed delivery date"
              value={
                srfDetails.committedDeliveryDate
                  ? srfDetails.committedDeliveryDate
                  : new Date()
              }
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              onChange={(newValue) => {
                updateSrfDetails({ committedDeliveryDate: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <br />
      <hr />
      <DUCInwardDetails
        id={params?.id}
        readingRows={readingRows}
        setReadingRows={setReadingRows}
        srfInstrumentsToDelete={srfInstrumentsToDelete}
        setSrfInstrumentsToDelete={setSrfInstrumentsToDelete}
        srfDetails={srfDetails}
        updateSrfDetails={updateSrfDetails}
      />
      <hr />
      <br />
      {isCustomerAnnexure && (
    <CustomerAnnexure
      customerAnnextureFilePaths={srfDetails?.customerAnnextureFilePaths}
      updateSrfDetails={updateSrfDetails}
    />
  )}
      <br/>
      <Toolbar style={{ padding: "0px", overflow: "auto" }}>
        {showComponent("save") && (
          <Button
          id="editsrf-saveupdate"
            variant="contained"
            size="small"
            sx={{ m: 0 }}
            onClick={() => {
              if (!validateData()) return;
              if (srfDetails?.id > 0) {
                handleUpdateSubmit(
                  srfDetails,
                  readingRows,
                  srfInstrumentsToDelete,
                  settings,
                  setLoader,
                );
              } else {
                handleCreateSubmit(
                  srfDetails,
                  readingRows,
                  settings,
                  setLoader,
                );
              }
            }}
          >
            {params.id != 0 ? "Update" : "Save"}
          </Button>
        )}
        {false && (
          <Button
            variant="contained"
            size="small"
            sx={{ m: 0, ml: 2 }}
            onClick={() => {
              console.log("incomplete functionality :(");
            }}
          >
            Print
          </Button>
        )}
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
