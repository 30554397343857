import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import HelpIcon from "@mui/icons-material/Help";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  createFilterOptions,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { editUncertinityGuide } from "./guide";
const axios = require("axios");
const filter = createFilterOptions();

export default function UpdateUncertinity(props) {
  const editUncertinityGuides = editUncertinityGuide();
  const [name, setName] = useState("");
  const [distribution, setDistribution] = useState("");
  const params = useParams();
  const [sourceConfig, setSourceConfig] = useState({ default: "" });
  const [selected, setSelected] = useState("default");
  const [showCondition, setShowCondition] = useState({ default: "" });
  const [selectedCondition, setSelectedCondition] = useState("default");
  const [sourceConfigSensitivity, setSourceConfigSensitivity] = useState({
    default: "",
  });
  const [selectedSensitivity, setSelectedSensitivity] = useState("default");
  const [units, setUnits] = React.useState([]);
  const [sensitiveCoefficientUnit, setSensitiveCoefficientUnit] = useState("");
  const [sourceValueUnit, setSourceValueUnit] = useState("");

  const getUncertinityList = () => {
    let url = BASE_URL;
    axios
      .get(url + `uncertainty/${params.id}`)
      .then((res) => {
        let resData=res.data[0]
        setName(resData.name);
        setDistribution(resData.distribution);
        setSourceValueUnit(resData?.sourceValueUnit ? resData?.sourceValueUnit : "");
        setSensitiveCoefficientUnit(resData?.sensitiveCoefficientUnit ? resData?.sensitiveCoefficientUnit : "");
        setSourceConfigSensitivity(
          resData.sensitives ? JSON.parse(resData.sensitives) : {}
        );
        setSourceConfig(
          resData.sourceconfig ? JSON.parse(resData.sourceconfig) : {}
        );
        setShowCondition(
          resData.showcondition ? JSON.parse(resData.showcondition) : {}
        );
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  useEffect(() => {
    getUncertinityList();
  }, []);

  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = () => {
    var jsonData = {
      name: name,
      distribution: distribution,
      sensitives: JSON.stringify(sourceConfigSensitivity),
      sourceconfig: JSON.stringify(sourceConfig),
      showcondition: JSON.stringify(showCondition),
      sourceValueUnit:sourceValueUnit,
      sensitiveCoefficientUnit:sensitiveCoefficientUnit,
    };

    let url = BASE_URL;

    axios
      .patch(url + `uncertainty/${params.id}`, jsonData)
      .then((res) => {
        props.setLoader(false);
        toast("Updated Uncertainty!");
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        props.setLoader(false);
        toast.error("Something Went Wrong!");
      });
  };


  const fetchUnitMasterArray = async () => {
    try {
      const response = await axios.get(`${BASE_URL}unit?_where=(status,eq,1)`);
      const data = response.data;
      const filteredUnits = data.filter(unit => unit.symbol !== undefined && unit.symbol !== null);
      const formattedUnits = filteredUnits.map(unit => ({ label: unit.symbol }));
      return formattedUnits;
    } catch (error) {
      toast.error("Something Went Wrong!");
      return [];
    }
  };
  
  useEffect(() => {
    fetchUnitMasterArray()
      .then(formattedUnits => {
        setUnits(["", ...formattedUnits]);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{display:"flex",justifyContent:"space-between"}}>            
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        Edit Uncertainty
      </Typography>
      <Tooltip title="User Guide" placement="top-start">
        <Button
         onClick={() => {
          editUncertinityGuides.drive();
         }}
        >
          <HelpIcon />
        </Button>
      </Tooltip>
    </div>  

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="uncertinity_edit_name"
            label="Name *"
            size="small"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="uncertinity_edit_distrubution"
            label="Distribution *"
            size="small"
            fullWidth
            variant="outlined"
            value={distribution}
            onChange={(e) => setDistribution(e.target.value)}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography
            variant="h6"
            component="h6"
            style={{ float: "left", marginBottom: "15px" }}
          >
            Sensitivity Coefficient
          </Typography>
          <Autocomplete
            value={selectedSensitivity}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setSelectedSensitivity(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedSensitivity(newValue.inputValue);
              } else {
                setSelectedSensitivity(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="uncertinity_edit_condcoeff"
            options={Object.keys(
              sourceConfigSensitivity || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set condition " />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={4} marginTop="48px">
          <TextField
            id="uncertinity_edit_formulacoeff"
            label="Formula for *"
            size="small"
            value={sourceConfigSensitivity[selectedSensitivity] || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let tmp = { ...sourceConfigSensitivity };
              tmp[selectedSensitivity] = e.target.value;
              setSourceConfigSensitivity(tmp);
            }}
          />
        </Grid>
        <Grid item xs={2.1} marginTop="48px" marginLeft="-18px">
          <Autocomplete
            size="small"
            id="uncertinity_edit_unitcoeff"
            options={units}
            getOptionLabel={(option) => option.label}
            value={
              units.find(
                (lab) => lab.label === sensitiveCoefficientUnit
              ) || null
            }
            sx={{ minWidth: "100px" }}
            renderInput={(params) => <TextField {...params} label="Unit*" />}
            onChange={(event, value) => {
              let newValue=value ? value.label : ""
              setSensitiveCoefficientUnit(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="h6"
            component="h6"
            style={{ float: "left", marginBottom: "15px" }}
          >
            Uncertainty Factors
          </Typography>
          <Autocomplete
            value={selected}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setSelected(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelected(newValue.inputValue);
              } else {
                setSelected(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="uncertinity_edit_condFact"
            options={Object.keys(
              sourceConfig || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set condition " />
            )}
            size="small"
          />
        </Grid>
        <Grid item xs={4} marginTop="48px">
          <TextField
            id="uncertinity_edit_formulaFact"
            label="Formula for*"
            size="small"
            value={sourceConfig[selected] || ""}
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let tmp = { ...sourceConfig };
              tmp[selected] = e.target.value;
              setSourceConfig(tmp);
            }}
          />
        </Grid>
        <Grid item xs={2.1} marginTop="48px" marginLeft="-18px">
          <Autocomplete
            size="small"
            id="uncertinity_edit_unitFact"
            options={units}
            getOptionLabel={(option) => option.label}
            value={
              units.find(
                (lab) => lab.label === sourceValueUnit
              ) || null
            }
            sx={{ minWidth: "100px" }}
            renderInput={(params) => <TextField {...params} label="Unit*" />}
            onChange={(event, value) => {
              let newValue=value ? value.label : ""
              setSourceValueUnit(newValue);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={selectedCondition}
            onChange={(event, newValue) => {
              if (newValue === null || newValue.inputValue === null) return;
              if (typeof newValue === "string") {
                setSelectedCondition(newValue);
              } else if (newValue && newValue.inputValue) {
                setSelectedCondition(newValue.inputValue);
              } else {
                setSelectedCondition(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={Object.keys(
              showCondition || {
                default: "",
              }
            )}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
            }}
            renderOption={(props, option) => (
              <li {...props} style={{ display: "flex" }}>
                <div style={{ flex: "1" }}>{option}</div>
                <button
                  style={{ color: "#888" }}
                  onClick={(e) => {
                    let tmp = { ...showCondition };
                    delete tmp[props["key"]];
                    setShowCondition({ ...tmp });
                    setSelectedCondition("");
                  }}
                >
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall css-ptiqhd-MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="CloseIcon"
                  >
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                  </svg>
                </button>
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Set show condition" />
            )}
            size="small"
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            id="outlined-basic"
            label="Default show Value *"
            size="small"
            value={showCondition[selectedCondition] || ""}
            fullWidth
            variant="outlined"
            onInputChange={(event, newInputValue) => {
              let tmp = { ...showCondition }
              tmp[selected] = newInputValue
              setShowCondition(tmp)
            }}
            onChange={e => {
              let tmp = { ...showCondition }
              tmp[selectedCondition] = e.target.value
              setShowCondition(tmp)
            }}
          />
        </Grid> */}
        <Grid item xs={3} style={{ alignItems: "start" }}>
          <FormControlLabel
            classes={{
              alignItems: "left",
            }}
            value={showCondition[selectedCondition] || ""}
            style={{ display: "flex" }}
            control={
              <Checkbox
                value={showCondition[selectedCondition] || ""}
                checked={showCondition[selectedCondition] ? true : false}
                onChange={(e) => {
                  let tmp = { ...showCondition };
                  tmp[selectedCondition] = e.target.checked;
                  setShowCondition(tmp);
                }}
              />
            }
            label="Show Factor?"
          />
        </Grid>
      </Grid>
      <br />
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
        id="uncertinity_edit_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            props.setLoader(true);
            onSubmit();
          }}
        >
          Update
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
